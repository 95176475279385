import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Icon } from "@iconify/react";
import Modal from "react-bootstrap/Modal";
import { BGHOLDER as bg, checkStatusCodes } from "../../constants";
import { editDepense } from "./services/depense.service";
import { formatDateForInput } from "../../helpers/dates.helper";

function DepenseEditModal({
  selectedDepense,
  handleClose,
  showEdit,
  handleToast,
  showLoader,
  updateDepenseInList,
}) {
  const [code, setCode] = useState("");
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState("");
  const [title, setTitle] = useState("");

  //   const { register, handleSubmit } = useForm();
  const depenseEdit = async (e) => {
    e.preventDefault();
    showLoader(true);
    const data = {
      title: title,
      amount: parseInt(amount),
      date: formatDateForInput(date),
    };

    const editUrl = `/spents/${code}/update`;
    const response = await editDepense(editUrl, data);
    let state = false;
    let message = "";
    let type = "";
    if (checkStatusCodes(response.data.statusCode)) {
      showLoader(false);
      state = true;
      message = response.data.message;
      type = "success";
      handleToast(state, type, message);
      updateDepenseInList(response.data.data);
    } else {
      showLoader(false);
      state = true;
      message = response.data.message;
      type = "error";
      handleToast(state, type, message);
    }
  };

  useEffect(() => {
    if (selectedDepense) {
      setCode(selectedDepense.code);
      setAmount(selectedDepense.amount);
      setDate(selectedDepense.date);
      setTitle(selectedDepense.title);
      console.log(selectedDepense);
    }
  }, [selectedDepense]);

  return (
    <Modal
      show={showEdit}
      onHide={handleClose}
      animation={true}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
      <Modal.Header>
        <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
          <button
            class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
            onClick={handleClose}
          ></button>
        </div>
        <div className="bg-light rounded-top-lg py-3 ps-4 pe-6">
          <h4 className="mb-1" id="staticBackdropLabel">
            Modifier le dépense :{" "}
            <span className="text-primary"> {selectedDepense.code} </span>
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="p-4">
          <div className="row">
            <form onSubmit={(e) => depenseEdit(e)}>
              <div className="col-lg-12">
                <div className="d-flex">
                  <div className="flex-1">
                    <div className="row">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="date">
                          Date:
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="clarity:date-solid"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="date"
                            className="form-control sb-input"
                            type="datetime-local"
                            aria-label="date"
                            placeholder="date"
                            aria-describedby="basic-addon1"
                            defaultValue={formatDateForInput(
                              selectedDepense.date
                            )}
                            onChange={(e) => setDate(e.target.value)}
                            // {...register("date", { required: true })}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="libellé">
                          Libelle de la dépense:
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="fluent-emoji-high-contrast:label"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="libellé"
                            className="form-control sb-input"
                            type="text"
                            aria-label="libellé"
                            placeholder="Libelle de la dépense"
                            aria-describedby="basic-addon1"
                            defaultValue={selectedDepense.title}
                            onChange={(e) => setTitle(e.target.value)}
                            // {...register("title", { required: true })}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="amount">
                          Somme de la dépense:
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="tdesign:undertake-transaction"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="amount"
                            className="form-control sb-input"
                            type="number"
                            aria-label="amount"
                            placeholder="Somme de la dépense"
                            aria-describedby="basic-addon1"
                            defaultValue={selectedDepense.amount}
                            onChange={(e) => setAmount(e.target.value)}
                            // {...register("amount", { required: true })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="flex-1">
                    <button
                      className="btn btn-outline-danger rounded-pill me-1 mb-1"
                      type="button"
                      onClick={handleClose}
                    >
                      Annuler
                    </button>
                    <button
                      className="btn btn-outline-primary rounded-pill me-1 mb-1 btn-edit"
                      type="submit"
                      id="edit_charge_send"
                      onClick={handleClose}
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DepenseEditModal;
