/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

import ToastMessage from "../../components/messages/ToastMessage";
import Loader from "../../components/partials/Loader";
import Pagination from "../../components/partials/Pagination";

import HeaderCommande from "./headerCommande";
import CommandeAddModal from "./components/CommandeAddModal";
import CommandeDetailModal from "./components/CommandeDetailModal";
import CommandeStatusModal from "./components/CommandeStatusModal";

import { BGHOLDER as bg } from "../../constants";
import { commandeList } from "./services/commande.service";
import { checkStatusCodes } from "../../constants";
import { entrepriseList } from "../../components/customer/services/entreprise.service";
import { commercialList } from "../../components/customer/services/commercial.service";
import { produitList } from "../../components/stock/produit/services/produit.service";
import { renderCommandeRow } from "./components/CommandeRow";

function Commande(props) {
  const [commandes, setCommandes] = useState([]);

  const [produits, setProduits] = useState([]);
  const [commercials, setCommercials] = useState(null);
  const [selectedCommande, setSelectedCommande] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [paginator, setPaginator] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [entreprises, setEntrprises] = useState(null);

  const [loaderState, setLoaderState] = useState(false);
  const [toastState, setToastState] = useState({
    show: false,
    type: "",
    message: "",
  });

  const handleToast = (show, type, message) => {
    const updatedToast = { ...toastState };
    updatedToast.show = show;
    updatedToast.type = type;
    updatedToast.message = message;
    setToastState(updatedToast);
  };
  const handleClose = () => {
    setShowAdd(false);
    setShowEdit(false);
    setShowDetail(false);
    setShowStatus(false);
  };

  const handleShowDetail = () => setShowDetail(true);
  const handleShowAdd = () => setShowAdd(true);
  const handleShowEdit = () => setShowEdit(true);
  const handleShowStatus = () => setShowStatus(true);

  const fetchCommandeList = async () => {
    const response = await commandeList();
    if (checkStatusCodes(response.data.statusCode)) {
      setCommandes(response.data.data.list);
      setPaginator(response.data.data.pagination);
      setLoaderState(false);
    } else {
      let state = true;
      let message = "Vous n'avez pas les authorisations réquises";
      let type = "error";
      handleToast(state, type, message);
    }
  };

  const fetchProduitList = async () => {
    try {
      const response = await produitList("/products/all?page=1&limit=100");
      setProduits(response.data.data.list);
      setLoaderState(false);
    } catch (error) {}
  };
  const fetchEntrepriseList = async () => {
    const response = await entrepriseList(
      `/customers/companies/all?page=1&limit=100`
    );
    if (checkStatusCodes(response.data.statusCode)) {
      setEntrprises(response.data.data.list);
      setLoaderState(false);
    } else {
      let state = true;
      let message = "Vous n'avez pas les authorisations réquises";
      let type = "error";
      handleToast(state, type, message);
    }
  };

  const fetchCommercialList = async () => {
    const response = await commercialList(
      `/customers/commercials/all?page=1&limit=100`
    );
    if (checkStatusCodes(response.data.statusCode)) {
      setCommercials(response.data.data.list);
      setLoaderState(false);
    } else {
      let state = true;
      let message = "Vous n'avez pas les authorisations réquises";
      let type = "error";
      handleToast(state, type, message);
    }
  };

  const handleEditClick = (commande) => {
    setSelectedCommande(commande);
    handleShowEdit();
  };

  const handleStatusClick = (commande) => {
    setSelectedCommande(commande);
    handleShowStatus();
  };

  const handleDetailClick = (commande) => {
    setSelectedCommande(commande);
    handleShowDetail();
  };
  const PanaginationList = async (page) => {
    setLoaderState(true);
    setCurrentPage(page);
    try {
      const response = await commandeList(
        `/invoices/orders/all?page=${page}&limit=10`
      );
      setCommandes(response.data.data.list);
      setPaginator(response.data.data.pagination);
      setLoaderState(false);
    } catch (error) {}
  };

  const renderTableData = () => {
    const sortedCommandes =
      commandes && commandes.length > 0
        ? commandes.slice().sort((a, b) => (a.number < b.number ? 1 : -1))
        : null;
    return (
      <tbody className="list_admin">
        {sortedCommandes && sortedCommandes.length > 0 ? (
          sortedCommandes.map((commande) =>
            renderCommandeRow({
              commande: commande,
              handleStatusClick: handleStatusClick,
              handleDetailClick: handleDetailClick,
              handleEditClick: handleEditClick,
            })
          )
        ) : (
          <tr>
            <td colSpan="5" className="text-center">
              Aucune Commande disponible.
            </td>
          </tr>
        )}
      </tbody>
    );
  };

  useEffect(() => {
    setLoaderState(true);
    fetchCommandeList();
    fetchProduitList();
    fetchEntrepriseList();
    fetchCommercialList();
  }, []);

  return (
    <div>
      {loaderState ? <Loader loaderState={loaderState} /> : null}

      {toastState.show ? <ToastMessage toastState={toastState} /> : null}
      <div className="row g-3 mb-3 p-0">
        <HeaderCommande handleShowAdd={handleShowAdd} />
        <div className="row mb-3 p-0 ps-3">
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={bg}
            ></div>
            <div className="card-body position-relative">
              <div className="row p-0">
                <div className="col-md-6">
                  <div className="col-12 input-group" id="search_box">
                    <input
                      type="text"
                      className="form-control input-sm m-0"
                      placeholder="Rechercher"
                      id="searchInput"
                    />
                    <span className="input-group-text" id="basic-addon3">
                      <a href="" className="search">
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </a>
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="col-12 input-group" id="search_box">
                    <input
                      type="date"
                      className="form-control input-sm m-0"
                      placeholder="Rechercher"
                      id="searchInput"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 p-0 mb-3">
          <div className="col-md-12 p-0">
            <div className="card mb-3">
              <div className="card-header bg-light">
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="mb-0" id="followers">
                      Les Commandes
                    </h5>
                  </div>
                </div>
              </div>
              <div className="card-body bg-light px-1 py-0">
                <div className="row g-0 fs--1">
                  <div
                    id="tableExample2"
                    data-list='{"valueNames":["Nom","email","telephone","role"],"page":5,"pagination":true}'
                  >
                    <div className="table-responsive scrollbar">
                      <table
                        className="table table-bordered table-striped fs--1 mb-0"
                        id="table"
                      >
                        <thead className="bg-200 text-900">
                          <tr>
                            <th className="sort">N° Commande</th>
                            <th className="sort">Montant HT</th>
                            <th className="sort">Montant TVA</th>
                            <th className="sort">Montant TTC</th>
                            <th className="sort">commanditaire</th>
                            <th className="sort">Statut</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        {renderTableData()}
                      </table>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <Pagination
                        paginator={paginator}
                        currentPage={currentPage}
                        limit={10}
                        pagination={PanaginationList}
                      />
                    </div>
                  </div>
                  <CommandeAddModal
                    produits={produits}
                    commercials={commercials}
                    entreprises={entreprises}
                    showAdd={showAdd}
                    handleClose={handleClose}
                    handleToast={handleToast}
                  />
                  <CommandeDetailModal
                    selectedCommande={selectedCommande}
                    showDetail={showDetail}
                    handleClose={handleClose}
                  />
                  <CommandeStatusModal
                    selectedCommande={selectedCommande}
                    showStatus={showStatus}
                    handleClose={handleClose}
                    handleToast={handleToast}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Commande;
