import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { Icon } from "@iconify/react";

function SideBar(props) {
  const location = useLocation(); // once ready it returns the 'window.location' object
  const [url, setUrl] = useState(null);
  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);
  return (
    <nav className="navbar navbar-light navbar-vertical navbar-expand-xl">
      <script src="../assets/js/inline_2.js"></script>
      <div className="d-flex align-items-center">
        <div className="toggle-icon-wrapper">
          <button
            className="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
            data-bs-toggle="tooltip"
            data-bs-placement="left"
            title="Toggle"
          >
            <span className="navbar-toggle-icon">
              <span className="toggle-line"></span>
            </span>
          </button>
        </div>
        <Link className="navbar-brand" to={"#"}>
          <div className="d-flex align-items-center py-3">
            <img
              className="me-2"
              src="../../../assets/logo_anita_2.png"
              alt=""
              width="80"
              height="50"
            />
          </div>
        </Link>
      </div>
      <div class="collapse navbar-collapse" id="navbarVerticalCollapse">
        <div className="navbar-vertical-content scrollbar">
          <ul className="navbar-nav flex-column mb-3" id="navbarVerticalNav">
            <li className="nav-item">
              <NavLink
                className={
                  "nav-link" + (url === "/me/dashboard" ? " active" : "")
                }
                to={"/me/dashboard"}
                data-bs-toggle=""
                aria-expanded="false"
                aria-controls="dashboard"
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon">
                    <span className="fas fa-chart-pie"></span>
                  </span>
                  <span className="nav-link-text text-to-cut ps-1">
                    tableau de bord
                  </span>
                </div>
              </NavLink>
            </li>

            <li className="nav-item">
              <div className="row navbar-vertical-label-wrapper mt-3 mb-2">
                <div className="col-auto navbar-vertical-label">Acteurs</div>
                <div className="col ps-0">
                  <hr className="mb-0 navbar-vertical-divider" />
                </div>
              </div>
              <NavLink
                className={"nav-link" + (url === "/me/users" ? "active" : "")}
                to={"/me/users"}
                role="button"
                data-bs-toggle=""
                aria-expanded="false"
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon">
                    <Icon icon="mdi:users" width="20" />
                  </span>
                  <span className="nav-link-text ps-1">Utilisateurs</span>
                </div>
              </NavLink>

              <a
                className={
                  "nav-link dropdown-indicator" +
                  (url === "/me/company" || url === "/me/commercial"
                    ? " active"
                    : "")
                }
                href="#client"
                role="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls="client"
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon">
                    <Icon icon="raphael:customer" width="20" />
                  </span>
                  <span class="nav-link-text ps-1">Client</span>
                </div>
              </a>
              <ul class="nav collapse" id="client">
                <NavLink
                  className={
                    "nav-link" + (url === "/me/company" ? "active" : "")
                  }
                  to={"/me/company"}
                  role="button"
                  data-bs-toggle=""
                  aria-expanded="false"
                >
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon"></span>
                    <span className="nav-link-text ps-1">Entreprises</span>
                  </div>
                </NavLink>
                <NavLink
                  className={
                    "nav-link" + (url === "/me/commercial" ? "active" : "")
                  }
                  to={"/me/commercial"}
                  role="button"
                  data-bs-toggle=""
                  aria-expanded="false"
                >
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon"></span>
                    <span className="nav-link-text ps-1">Commerciaux</span>
                  </div>
                </NavLink>
              </ul>
            </li>

            <li className="nav-item">
              <div className="row navbar-vertical-label-wrapper mt-3 mb-2">
                <div className="col-auto navbar-vertical-label">
                  Gestion Personnel
                </div>
                <div className="col ps-0">
                  <hr className="mb-0 navbar-vertical-divider" />
                </div>
              </div>
              <NavLink
                className={
                  "nav-link" + (url === "/me/personnels" ? "active" : "")
                }
                to={"/me/personnels"}
                role="button"
                data-bs-toggle=""
                aria-expanded="false"
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon">
                    <Icon icon="clarity:employee-group-solid" width="20" />
                  </span>
                  <span className="nav-link-text ps-1">Personnels</span>
                </div>
              </NavLink>
              <NavLink
                className={
                  "nav-link" + (url === "/me/pointage" ? "active" : "")
                }
                to={"/me/pointage"}
                role="button"
                data-bs-toggle=""
                aria-expanded="false"
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon">
                    <Icon icon="ion:finger-print" width="20" height="20" />
                  </span>
                  <span className="nav-link-text ps-1">Pointage</span>
                </div>
              </NavLink>
              <NavLink
                className={"nav-link" + (url === "/me/conge" ? "active" : "")}
                to={"/me/conge"}
                role="button"
                data-bs-toggle=""
                aria-expanded="false"
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon">
                    <Icon
                      icon="icon-park-twotone:resting"
                      width="20"
                      height="20"
                    />
                  </span>
                  <span className="nav-link-text ps-1">Congés</span>
                </div>
              </NavLink>
              <NavLink
                className={"nav-link" + (url === "/me/salaire" ? "active" : "")}
                to={"/me/salaire"}
                role="button"
                data-bs-toggle=""
                aria-expanded="false"
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon">
                    <Icon icon="grommet-icons:money" width="20" height="20" />
                  </span>
                  <span className="nav-link-text ps-1">Salaire</span>
                </div>
              </NavLink>
            </li>

            <li className="nav-item">
              <div className="row navbar-vertical-label-wrapper mt-3 mb-2">
                <div className="col-auto navbar-vertical-label">
                  approvisionnement
                </div>
                <div className="col ps-0">
                  <hr className="mb-0 navbar-vertical-divider" />
                </div>
              </div>

              <a
                class="nav-link dropdown-indicator"
                href="#stock"
                role="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls="stock"
              >
                <div class="d-flex align-items-center">
                  <span className="nav-link-icon">
                    <Icon icon="carbon:heat-map-stocks" width="20" />
                  </span>
                  <span class="nav-link-text ps-1">Gestion Stock</span>
                </div>
              </a>
              <ul class="nav collapse" id="stock">
                <NavLink
                  className={
                    "nav-link" + (url === "/me/produit" ? "active" : "")
                  }
                  to={"/me/produit"}
                  role="button"
                  data-bs-toggle=""
                  aria-expanded="false"
                >
                  <div className="d-flex align-items-center">
                    <span className="nav-link-text ps-1">Produit</span>
                  </div>
                </NavLink>
                <NavLink
                  className={"nav-link" + (url === "/me/stock" ? "active" : "")}
                  to={"/me/stock"}
                  role="button"
                  data-bs-toggle=""
                  aria-expanded="false"
                >
                  <div className="d-flex align-items-center">
                    <span className="nav-link-text ps-1">Stock</span>
                  </div>
                </NavLink>
                <NavLink
                  className={
                    "nav-link" + (url === "/me/historiqueStock" ? "active" : "")
                  }
                  to={"/me/historiqueStock"}
                  role="button"
                  data-bs-toggle=""
                  aria-expanded="false"
                >
                  <div className="d-flex align-items-center">
                    <span className="nav-link-text ps-1">Historique</span>
                  </div>
                </NavLink>
                <NavLink
                  className={
                    "nav-link" + (url === "/me/fournisseur" ? "active" : "")
                  }
                  to={"/me/fournisseur"}
                  role="button"
                  data-bs-toggle=""
                  aria-expanded="false"
                >
                  <div className="d-flex align-items-center">
                    <span className="nav-link-text ps-1">Fournisseur</span>
                  </div>
                </NavLink>
              </ul>
              <NavLink
                className={
                  "nav-link" + (url === "/me/production" ? "active" : "")
                }
                to={"/me/production"}
                role="button"
                data-bs-toggle=""
                aria-expanded="false"
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon">
                    <Icon
                      icon="fluent:production-checkmark-20-filled"
                      width="20"
                    />
                  </span>
                  <span className="nav-link-text ps-1">Production</span>
                </div>
              </NavLink>
            </li>
            <li className="nav-item">
              <div className="row navbar-vertical-label-wrapper mt-3 mb-2">
                <div className="col-auto navbar-vertical-label">finances</div>
                <div className="col ps-0">
                  <hr className="mb-0 navbar-vertical-divider" />
                </div>
              </div>
              <NavLink
                className={
                  "nav-link" + (url === "/me/depenses" ? "active" : "")
                }
                to={"/me/depenses"}
                role="button"
                data-bs-toggle=""
                aria-expanded="false"
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon">
                    <Icon icon="game-icons:expense" width="20" />
                  </span>
                  <span className="nav-link-text ps-1">Dépenses</span>
                </div>
              </NavLink>
              <NavLink
                className={"nav-link" + (url === "/me/caisse" ? "active" : "")}
                to={"/me/caisse"}
                role="button"
                data-bs-toggle=""
                aria-expanded="false"
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon">
                    <Icon icon="fa6-solid:cash-register" width="20" />
                  </span>
                  <span className="nav-link-text ps-1">Caisse</span>
                </div>
              </NavLink>
              <NavLink
                className={
                  "nav-link" + (url === "/me/commande" ? "active" : "")
                }
                to={"/me/commande"}
                role="button"
                data-bs-toggle=""
                aria-expanded="false"
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon">
                    <Icon icon="icon-park-solid:transaction-order" width="20" />
                  </span>
                  <span className="nav-link-text ps-1">Commande</span>
                </div>
              </NavLink>
              <NavLink
                className={"nav-link" + (url === "/me/facture" ? "active" : "")}
                to={"/me/facture"}
                role="button"
                data-bs-toggle=""
                aria-expanded="false"
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon">
                    <Icon icon="basil:invoice-solid" width="20" />
                  </span>
                  <span className="nav-link-text ps-1">Factures</span>
                </div>
              </NavLink>
            </li>

            <li className="nav-item">
              <div className="row navbar-vertical-label-wrapper mt-3 mb-2">
                <div className="col-auto navbar-vertical-label">Parking</div>
                <div className="col ps-0">
                  <hr className="mb-0 navbar-vertical-divider" />
                </div>
              </div>
              <NavLink
                className={
                  "nav-link" + (url === "/me/vehicule" ? "active" : "")
                }
                to={"/me/vehicule"}
                role="button"
                data-bs-toggle=""
                aria-expanded="false"
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon">
                    <Icon icon="ant-design:car-filled" width="20" height="20" />
                  </span>
                  <span className="nav-link-text ps-1">Véhicules</span>
                </div>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default SideBar;
