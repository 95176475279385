import React from 'react';
import { BGHOLDER as bg, checkStatusCodes } from '../../constants';
import { useForm } from 'react-hook-form';
import { Icon } from '@iconify/react';
import Modal from 'react-bootstrap/Modal';
import { createVehicule } from './services/vehicule.service';
import { dateFormatForSubmit } from '../../helpers/dates.helper';

function VehiculeAddModal({ showAdd, handleClose, handleToast}) {
    const {
        register,
        handleSubmit,
    } = useForm()

    const vehiculeCreation = async (data) => {
        handleClose()
        const newdata = {
            name_mark: data.name_mark,
            name_model: data.name_model,
            transmission: data.transmission,
            registration: data.registration,
            bail: {
                price: parseInt(data.price),
                date_start:dateFormatForSubmit(data.date_start),
                date_end: dateFormatForSubmit(data.date_end)
            },
            transfer_fees: parseInt(data.transfer_fees),
            supplier: data.supplier
        }
        handleToast()
        const response = await createVehicule(newdata)
        let state = false
        let message = ""
        let type = ""
        if(checkStatusCodes(response.data.statusCode)){
            state = true
            message = response.data.message
            type = "success"
            handleToast(state, type, message)
            setTimeout(() => {
                window.location.reload(); // Recharge la page
            }, 3000);
        }else{
            state = true
            message = response.data.message
            type = "error"
            handleToast(state, type, message)
        }        
    };

    return (
        <Modal show={showAdd} onHide={handleClose} animation={true} size="lg" aria-labelledby="example-modal-sizes-title-lg">
            <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
            <Modal.Header>
                <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
                    <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                        onClick={handleClose}></button>
                </div>
                <div className="bg-light rounded-top-lg py-3 ps-4 pe-6">
                        <h4 className="mb-1" id="staticBackdropLabel">
                            Enrégistrer un véhicule
                        </h4>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="p-4">
                    <div className="row">
                        <form onSubmit={handleSubmit((data) => vehiculeCreation(data))}>
                            <div className="col-lg-12">
                                <div className="d-flex">
                                    <div className="flex-1">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="form-label" htmlFor="marque">
                                                    La marque:
                                                </label>
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text bg-primary" id="basic-addon1">
                                                        <Icon icon="material-symbols:car-crash" width="25" height="25" className='text-white'/>
                                                    </span>
                                                    <input
                                                    id="marque"
                                                    className="form-control sb-input"
                                                    type="text"
                                                    aria-label="marque"
                                                    placeholder="la marque"
                                                    aria-describedby="basic-addon1"
                                                    {...register("name_mark", { required: true })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" htmlFor="model">
                                                    Le model:
                                                </label>
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text bg-primary" id="basic-addon1">
                                                        <Icon icon="material-symbols:car-crash" width="25" height="25" className='text-white'/>
                                                    </span>
                                                    <input
                                                    id="model"
                                                    className="form-control sb-input"
                                                    type="text"
                                                    aria-label="model"
                                                    placeholder="Le model"
                                                    aria-describedby="basic-addon1"
                                                    {...register("name_model", { required: true })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <label className="form-label" htmlFor="registration">
                                                    Incription:
                                                </label>
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text bg-primary" id="basic-addon1">
                                                        <Icon icon="solar:plate-bold" width="25" height="25" className='text-white'/>
                                                    </span>
                                                    <input
                                                    id="registration"
                                                    className="form-control sb-input"
                                                    type="text"
                                                    aria-label="registration"
                                                    placeholder="Le numéro de la plaque"
                                                    aria-describedby="basic-addon1"
                                                    {...register("registration", { required: false })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" htmlFor="transmission">
                                                    Tranmission:
                                                </label>
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text bg-primary" id="basic-addon1">
                                                        <Icon icon="solar:transmission-broken"  width="25" height="25" className='text-white'/>
                                                    </span>
                                                    <input
                                                    id="transmission"
                                                    className="form-control sb-input"
                                                    type="text"
                                                    aria-label="transmission"
                                                    placeholder="Ex: auto ou manuelle"
                                                    aria-describedby="basic-addon1"
                                                    {...register("transmission", { required: false })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <fieldset class="border p-2 mb-3">
                                            <legend class="float-none w-auto p-2">Bail</legend>
                                            <div className="row mb-3">
                                                <div className="col-md-6">
                                                    <label className="form-label" htmlFor="price">
                                                        Prix:
                                                    </label>
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text bg-primary" id="basic-addon1">
                                                            <Icon icon="tdesign:money" width="25" height="25" className='text-white'/>
                                                        </span>
                                                        <input
                                                        id="price"
                                                        className="form-control sb-input"
                                                        type="number"
                                                        aria-label="price"
                                                        placeholder="Le prix du bail"
                                                        aria-describedby="basic-addon1"
                                                        {...register("price", { required: false })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" htmlFor="date_start">
                                                        Date de debut:
                                                    </label>
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text bg-primary" id="basic-addon1">
                                                            <Icon icon="bi:calendar-fill" width="25" height="25" className='text-white'/>
                                                        </span>
                                                        <input
                                                        id="date_start"
                                                        className="form-control sb-input"
                                                        type="datetime-local"
                                                        aria-label="date_start"
                                                        aria-describedby="basic-addon1"
                                                        {...register("date_start", { required: false })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" htmlFor="date_end">
                                                        Date de fin:
                                                    </label>
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text bg-primary" id="basic-addon1">
                                                            <Icon icon="bi:calendar-x-fill" width="25" height="25" className='text-white'/>
                                                        </span>
                                                        <input
                                                        id="date_end"
                                                        className="form-control sb-input"
                                                        type="datetime-local"
                                                        aria-label="date_end"
                                                        aria-describedby="basic-addon1"
                                                        {...register("date_end", { required: false })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                        <div className="row mt-2 mb-2">
                                            <div className="col-md-6">
                                                <label className="form-label" htmlFor="transfer_fees">
                                                    Frais de Tranfert:
                                                </label>
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text bg-primary" id="basic-addon1">
                                                        <Icon icon="tdesign:money" width="25" height="25" className='text-white'/>
                                                    </span>
                                                    <input
                                                    id="transfer_fees"
                                                    className="form-control sb-input"
                                                    type="number"
                                                    aria-label="transfer_fees"
                                                    placeholder="Frais de Tranfert"
                                                    aria-describedby="basic-addon1"
                                                    {...register("transfer_fees", { required: false })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" htmlFor="supplier">
                                                    Le Fournisseur:
                                                </label>
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text bg-primary" id="basic-addon1">
                                                        <Icon icon="mdi:company" width="25" height="25" className='text-white'/>
                                                    </span>
                                                    <input
                                                    id="supplier"
                                                    className="form-control sb-input"
                                                    type="text"
                                                    aria-label="supplier"
                                                    placeholder="Le fournisseur"
                                                    aria-describedby="basic-addon1"
                                                    {...register("supplier", { required: false })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="flex-1">
                                        <button className="btn btn-outline-danger rounded-pill me-1 mb-1" type="button"
                                            onClick={handleClose}>Annuler</button>
                                        <button className="btn btn-outline-primary rounded-pill me-1 mb-1 btn-edit"
                                            type="submit" id="edit_charge_send">Valider</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default VehiculeAddModal;