import ApiHelper from "../../../libs/Api";

const loginUrl = "/auth/signin/identifier";

export const login = async (body) => {
  try {
    const result = await ApiHelper.request(loginUrl, "post", body);
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
