export const dateFormatToFr = (str) => {
    if (str) {
        const date = new Date(str);
        let formattedDate = null;
        if (str.includes("T")) {
            formattedDate = date.toLocaleString("fr-FR", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: false,
            });
            return formattedDate;
        } else {
            formattedDate = date.toLocaleString("fr-FR", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                // hour12: false
            });
            return formattedDate;
        }
    } else {
        return;
    }
};

export const dateFormatForSubmit = (str) => {
    if (str) {
        let date = new Date(str);
        let day = `${date.getDate()}`.padStart(2, "0");
        let month = `${date.getMonth() + 1}`.padStart(2, "0");
        let year = date.getFullYear();
        let hour = `${date.getHours()}`.padStart(2, "0");
        let min = `${date.getMinutes()}`.padStart(2, "0");
        let format = str.includes("T") ?
            `${year}-${month}-${day} ${hour}:${min}:00` :
            `${year}-${month}-${day}`;

        return format;
    } else {
        return "";
    }
};

export const formatDateForInput = (date) => {
    if (typeof date === "string") {
        date = new Date(date);
    }

    var year = date.getFullYear();
    var month = (date.getMonth() + 1).toString().padStart(2, "0");
    var day = date.getDate().toString().padStart(2, "0");
    var hours = date.getHours().toString().padStart(2, "0");
    var minutes = date.getMinutes().toString().padStart(2, "0");

    // Check if the date object has a valid time component
    const hasTime = date.getHours() !== 0 || date.getMinutes() !== 0;

    if (hasTime) {
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    } else {
        return `${year}-${month}-${day}`;
    }
};