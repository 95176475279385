import ApiHelper from "../../../libs/Api";

export const invoiceList = async (invoiceListUrl = "/invoices/all") => {
  try {
    return await ApiHelper.request(invoiceListUrl);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const invoiceCreationUrl = "/invoices/create";

export const createInvoice = async (body) => {
  try {
    return await ApiHelper.request(invoiceCreationUrl, "post", body);
  } catch (error) {
    return error.response;
  }
};

export const editInvoice = async (invoiceEditUrl, body) => {
  try {
    return await ApiHelper.request(invoiceEditUrl, "put", body);
  } catch (error) {
    return error.response;
  }
};

export const editInvoicePayment = async (invoiceEditUrl, body) => {
  try {
    return await ApiHelper.request(invoiceEditUrl, "post", body);
  } catch (error) {
    return error.response;
  }
};
