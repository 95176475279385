import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { BGHOLDER as bg } from '../../../constants';

// import { Icon } from '@iconify/react';
import { formaterNombreAvecEspace } from '../../../helpers/numbers.helper';

function CommandeDetailModal({ selectedCommande, showDetail, handleClose }) {
    useEffect(() => {
        if (!selectedCommande) {
            return () => {
                // Logique de nettoyage ou de destruction si nécessaire
                // Cette fonction sera appelée lors du démontage du composant
            };
        }
    }, [selectedCommande])

    return (
        <Modal show={showDetail} onHide={handleClose} animation={true} size="lg" aria-labelledby="example-modal-sizes-title-lg">
            <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
            <Modal.Header>
                <div className="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
                    <button className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                        onClick={handleClose}></button>
                </div>
                <div className="bg-light rounded-top-lg py-3 ps-4 pe-6">
                    <h4 className="mb-1" id="staticBackdropLabel">
                        {selectedCommande ? (
                            <span>
                                <span>Commande N°</span>: <span className="text-success">{selectedCommande?.number} </span>
                            </span>
                        ) : null}
                    </h4>
                    <p className="fs--4 mb-0">
                        créer par <a className="link-600 fw-semi-bold" href="/">{selectedCommande?.created_by.name}</a>
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="p-4">
                    <div className="row">

                        <div className="d-flex mb-3">
                            <span className="fa-stack ms-n1 me-3">
                                <i className="fas fa-circle fa-stack-2x text-200"></i>
                                <i className="fa-inverse fa-stack-1x text-primary fas fa-file"
                                    data-fa-transform="shrink-2"></i>
                            </span>
                            {selectedCommande ? (
                                <div className="flex-1">
                                    <h5 className="mb-2 fs-0">Infos sur la commande</h5>
                                    <div className="d-flex">
                                        <ul className="list-group fs--4 w-100">
                                            <li className="list-group-item me-2 me-lg-0 fs-1">
                                                Montant HT: <span className="text-success">{formaterNombreAvecEspace(selectedCommande?.amount_ht)} XOF</span>
                                            </li>
                                            <li className="list-group-item me-2 me-lg-0 fs-1">
                                                Montant TVA: <span className="text-success">{formaterNombreAvecEspace(selectedCommande?.amount_tva)} XOF</span>
                                            </li>
                                            <li className="list-group-item me-2 me-lg-0 fs-1">
                                                Montant TTC: <span className="text-success">{formaterNombreAvecEspace(selectedCommande?.amount_ttc)} XOF</span>
                                            </li>
                                            <li className="list-group-item me-2 me-lg-0 fs-1">
                                                commanditaire: <span className="text-success">{selectedCommande?.owner?.name}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        {selectedCommande && selectedCommande.products && selectedCommande.products.length > 0 ? (
                            <div className="d-flex">
                                <span className="fa-stack ms-n1 me-3">
                                    <i className="fas fa-circle fa-stack-2x text-200"></i>
                                    <i className="fa-inverse fa-stack-1x text-primary fas fa-file"
                                        data-fa-transform="shrink-2"></i>
                                </span>

                                <div className="flex-1">
                                    <h5 className="mb-2 fs-0">Les produits commandés</h5>
                                    <div className="d-flex">
                                        {/* <div className="table-responsive scrollbar"> */}
                                        <table className="table table-bordered fs--4 mb-0" id="table">
                                            <thead className="bg-200 text-900">
                                                <tr>
                                                    <th className="sort" data-sort="email">Nom</th>
                                                    <th className="sort" data-sort="email">Quantité</th>
                                                    <th className="sort" data-sort="email">Prix unitaire</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectedCommande.products && selectedCommande.products.length > 0 ? (
                                                    selectedCommande.products.map(product => (
                                                        <tr key={product?.code} className='text-success'>
                                                            <td>{product?.product?.name}</td>
                                                            <td>{formaterNombreAvecEspace(product?.quantity)} {product?.product?.unit_of_measure}</td>
                                                            <td>{formaterNombreAvecEspace(product?.price)} XOF</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="3" className='text-center'>Aucune produit disponible.</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <hr className="my-4" />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default CommandeDetailModal;
