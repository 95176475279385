import React, { useEffect, useState } from "react";
import { BGHOLDER as bg, checkStatusCodes } from "../../../constants";
import { Icon } from "@iconify/react";
import Modal from "react-bootstrap/Modal";
import { editProduit } from "./services/produit.service";

function ProduitEditModal({
  produitTypesValues,
  selectedProduit,
  showEdit,
  handleClose,
  handleToast,
  showLoader,
  updateProduitInList,
}) {
  const [codeProduit, setCodeProduit] = useState("");
  const [nameProduit, setNameProduit] = useState("");
  const [prix, setPrix] = useState("");
  const [uniteMesure, setUniteMesure] = useState("");
  const [selectedProduitType, setSelectedProduitType] = useState(null);

  const changeInputState = (selectedProduit) => {
    setCodeProduit(() => selectedProduit.code);
    setNameProduit(() => selectedProduit.name);
    setPrix(() => selectedProduit.unit_price);
    setUniteMesure(() => selectedProduit.unit_of_measure);
  };

  const clearInput = () => {
    setCodeProduit("");
    setNameProduit("");
    setPrix("");
    setUniteMesure("");
  };

  const ProduitlEdit = async (e) => {
    showLoader(true);
    e.preventDefault();
    const editData = {
      name: nameProduit,
      unit_price: parseInt(prix),
      unit_of_measure: uniteMesure,
      selectedProduitType: selectedProduitType.code,
    };
    const ProduitEditUrl = `/products/${codeProduit ? codeProduit : ""}/update`;
    const response = await editProduit(ProduitEditUrl, editData);
    let state = false;
    let message = "";
    let type = "";
    if (checkStatusCodes(response.data.statusCode)) {
      showLoader(false);
      const data = response.data.data;
      state = true;
      message = response.data.message;
      type = "success";
      handleToast(state, type, message);
      updateProduitInList({
        ...data,
        product_type: selectedProduitType,
      });
    } else {
      showLoader(false);
      state = true;
      message = response.data.message;
      type = "error";
      handleToast(state, type, message);
    }
  };
  useEffect(() => {
    // Mettez à jour le type d'utilisateur en fonction du rôle sélectionné
    if (selectedProduit && selectedProduit.product_type) {
      changeInputState(selectedProduit);
      const ProduitCode = selectedProduit.product_type.code;
      const correspondingProduit = produitTypesValues.find(
        (typeProduit) => typeProduit.code === ProduitCode
      );
      setSelectedProduitType(correspondingProduit);
    }
  }, [selectedProduit, produitTypesValues]);
  return (
    <Modal
      show={showEdit}
      onHide={handleClose}
      animation={true}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
      <Modal.Header>
        <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
          <button
            class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
            onClick={() => {
              handleClose();
              clearInput();
            }}
          ></button>
        </div>
        <div className="bg-light rounded-top-lg py-3 ps-4 pe-6">
          <h4 className="mb-1" id="staticBackdropLabel">
            Modifier un produit
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="p-4">
          <div className="row">
            <form onSubmit={(e) => ProduitlEdit(e)}>
              <div className="col-lg-12">
                <div className="d-flex">
                  <div className="flex-1">
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="name">Nom du produit:</label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="mdi:rename"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="name"
                            className="form-control sb-input"
                            type="text"
                            aria-label="Nom du produit"
                            placeholder="Nom du produit"
                            aria-describedby="basic-addon1"
                            defaultValue={nameProduit}
                            onChange={(e) => setNameProduit(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="prix">Prix unitaire:</label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <i className="fas fa-coins text-white"></i>
                          </span>
                          <input
                            id="prix"
                            className="form-control sb-input"
                            type="number"
                            aria-label="prix"
                            placeholder="prix"
                            aria-describedby="basic-addon1"
                            defaultValue={prix}
                            onChange={(e) => setPrix(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="unite">Unité de mesure:</label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="game-icons:weight-scale"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="unite"
                            className="form-control sb-input"
                            type="text"
                            aria-label="unite"
                            placeholder="unite"
                            aria-describedby="basic-addon1"
                            defaultValue={uniteMesure}
                            onChange={(e) => setPrix(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="produitType">
                          Type de produit
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="ic:baseline-category"
                              className="text-white"
                              width="25"
                              height="25"
                            />
                          </span>
                          <select
                            className="form-select js-choice"
                            id="produitType"
                            size="1"
                            name="produitType"
                            data-options='{"removeItemButton":true}'
                            value={
                              selectedProduitType
                                ? selectedProduitType.code
                                : ""
                            }
                            onChange={(e) => {
                              const selectedProduitCode = e.target.value;
                              const ProduitSelected = produitTypesValues.find(
                                (typeProduit) =>
                                  typeProduit.code === selectedProduitCode
                              );
                              setSelectedProduitType(ProduitSelected);
                            }}
                          >
                            <option value="">Sélectionner un employé...</option>
                            {produitTypesValues && produitTypesValues.length > 0
                              ? produitTypesValues.map((typeProduit) => (
                                  <option
                                    key={typeProduit.code}
                                    value={typeProduit.code}
                                  >
                                    {typeProduit?.name}
                                  </option>
                                ))
                              : null}
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div className="flex-1">
                  <button
                    className="btn btn-outline-danger rounded-pill me-1 mb-1"
                    type="button"
                    onClick={() => {
                      handleClose();
                      clearInput();
                    }}
                  >
                    Annuler
                  </button>
                  <button
                    className="btn btn-outline-primary rounded-pill me-1 mb-1 btn-edit"
                    type="submit"
                    id="edit_charge_send"
                    onClick={handleClose}
                  >
                    Valider
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ProduitEditModal;
