import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

import { BGHOLDER as bg } from '../../constants';
import { dateFormatToFr } from '../../helpers/dates.helper';
function PersonnelDetailModal({selectedPersonnel, showDetail, handleClose}) {

    useEffect(() => {
        console.log(selectedPersonnel)
        if (!selectedPersonnel) {
            return () => {
              // Logique de nettoyage ou de destruction si nécessaire
              // Cette fonction sera appelée lors du démontage du composant
            };
          }
    }, [selectedPersonnel])
    return (
        <Modal show={showDetail} onHide={handleClose} animation={true} size="lg" aria-labelledby="example-modal-sizes-title-lg">
            <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
            <Modal.Header>
                <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
                    <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                        onClick={handleClose}></button>
                </div>
                <div className="bg-light rounded-top-lg py-3 ps-4 pe-6">
                    <h4 className="mb-1" id="staticBackdropLabel">
                    {selectedPersonnel ? (
                        <span>
                            <span>{`${selectedPersonnel?.first_name} ${selectedPersonnel?.last_name}`}</span>: <span class="text-success">{selectedPersonnel.code} </span> 
                        </span>    
                    ):null}
                    </h4>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="p-4">
                    <div className="row">
                        <div class="d-flex">
                            <span class="fa-stack ms-n1 me-3"><i
                                    class="fas fa-circle fa-stack-2x text-200"></i><i
                                    class="fa-inverse fa-stack-1x text-primary fas fa-user"
                                    data-fa-transform="shrink-2"></i></span>
                            {selectedPersonnel ? (
                                <div class="flex-1">
                                    <h5 class="mb-2 fs-0">Infos Personnel</h5>
                                    <div class="d-flex">
                                        <ul class="list-group fs--4 w-100">
                                            <li class="list-group-item me-2 me-lg-0 fs-1">
                                                Prenom et Nom: <span class="text-success">{selectedPersonnel.first_name} {selectedPersonnel.last_name} </span>
                                            </li>
                                            <li class="list-group-item me-2 me-lg-0 fs-1">
                                                Email: <span class="text-success">{selectedPersonnel.email}</span>
                                            </li>
                                            <li class="list-group-item me-2 me-lg-0 fs-1">
                                                Telephone: <span class="text-success">{selectedPersonnel.phone}</span>
                                            </li>
                                            <li class="list-group-item me-2 me-lg-0 fs-1">
                                                Salaire: <span class="text-success">{selectedPersonnel.salary}</span>
                                            </li>
                                            <li class="list-group-item me-2 me-lg-0 fs-1">
                                                Date de naissance: <span class="text-success">{dateFormatToFr(selectedPersonnel.date_of_birth)}</span>
                                            </li>
                                            <li class="list-group-item me-2 me-lg-0 fs-1">
                                                Lieu de naissance: <span class="text-success">{selectedPersonnel.place_of_birth}</span>
                                            </li>
                                            <li class="list-group-item me-2 me-lg-0 fs-1">
                                                Situation Familliale: <span class="text-success">{selectedPersonnel.family_situation}</span>
                                            </li>
                                            <li class="list-group-item me-2 me-lg-0 fs-1">
                                                Nombre d'enfant: <span class="text-success">{selectedPersonnel.number_of_children}</span>
                                            </li>
                                            <li class="list-group-item me-2 me-lg-0 fs-1">
                                                Type de contrat: <span class="text-success">{selectedPersonnel.contract}</span>
                                            </li>
                                            <li class="list-group-item me-2 me-lg-0 fs-1">
                                                Diplome: <span class="text-success">{selectedPersonnel.diploma}</span>
                                            </li>
                                            <li class="list-group-item me-2 me-lg-0 fs-1">
                                                Role: <span class="text-success">{selectedPersonnel.category.name}</span>
                                            </li>
                                            <li class="list-group-item me-2 me-lg-0 fs-1">
                                                Date de recrutement: <span class="text-success">{dateFormatToFr(selectedPersonnel.recruitment_date)}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            ):null}
                            
                        </div>
                        <hr class="my-4" />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default PersonnelDetailModal;