import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import Loader from '../../components/partials/Loader';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import ToastMessage from '../../components/messages/ToastMessage';
import { BGHOLDER as bg, checkStatusCodes } from '../../constants';
import { produitList } from '../../components/stock/produit/services/produit.service';
import { createProductionStep } from '../../components/production/services/production.service';
import { personnelList } from '../../components/personnels/services/personnel.service';

const animatedComponents = makeAnimated();

function ProductionStep(props) {
    const { id } = useParams();
    
    const [production, setProduction] = useState(null);
    const navigate = useNavigate();
    const [loaderState, setLoaderState] = useState(false);
    const [options, setOptions] = useState([])
    const [productionCode, setProductionCode] = useState("")
    const [productionStepValue, setProductionStepValue] = useState("")

    const [selectedPersonnels, setSelectedPersonnels] = useState(null)
    const [personnels, setPersonnels] = useState([])
    const [toastState, setToastState] = useState({
        show: false,
        type: '',
        message: ''
    });

    const handleToast = (show, type, message) => {
        const updatedToast = { ...toastState }
        updatedToast.show = show
        updatedToast.type = type;
        updatedToast.message = message

        setToastState(updatedToast)
    }

    const fetchProduction = async () => {
        const response = await produitList(`/productions/histories/by_production/${id}`)
        if (checkStatusCodes(response.data.statusCode)) {
            const data = response.data.data[response.data.data.length-1]
            setProduction(data)
            setProductionCode(data?.code_production)
            setProductionStepValue(data?.production_step.code)
            setLoaderState(false)
        } else {
            let state = true
            let message = "Vous n'avez pas les authorisations réquises"
            let type = "error"
            handleToast(state, type, message)
        }
    }
    const fetchPersonnelList = async () =>{
        try {
            const response = await personnelList()
            setPersonnels(response.data.data.list)

            const personnelsData = response.data.data.list
            if(personnelsData.length > 0){
                const newOptions = personnelsData.map((item) => ({
                    value: item.code,
                    label: `${item.first_name} ${item.last_name}`
                }));
    
                setOptions(() => newOptions)
            }
            
            setLoaderState(false)
        } catch (error) {
            
        }
    }

    const productionStepHandler  = async (e) => {
        e.preventDefault();
        // console.log('
        const data = {
            code_production: productionCode,
            current_step: productionStepValue
        }
        if(data){
            if(!selectedPersonnels){
                let state = true;
                let message = 'Aucun responsable de production selectionné';
                let type = 'error';
                handleToast(state,type,message);
                return
            }
            let assignments = []
            selectedPersonnels.forEach(element => {
                assignments.push(element.value)
            });
            data.assignments = assignments
        }
        const response = await createProductionStep(data)
        let state = false
        let message = ""
        let type = ""
        if(checkStatusCodes(response.data.statusCode)){
            state = true
            message = response.data.message
            type = "success"
            handleToast(state, type, message)
            setTimeout(() => {
                // window.location.reload(); // Recharge la page
                navigate("/me/production");
            }, 2000);
        }else{
            state = true
            message = response.data.message
            type = "error"
            handleToast(state, type, message)
        }        
    }

    useEffect(() => {
        fetchProduction()
        fetchPersonnelList()
    }, [])

    // useEffect(() => {
    //     console.log(production);
    // }, [production]);
    return (
        <div>
            {loaderState ? (
                <Loader loaderState={loaderState} />
            ) : null}

            {toastState.show ? (
                <ToastMessage toastState={toastState} />
            ) : null}
            <div className="row g-3 mb-3 p-0">
                <div className="row mb-3 p-0 ps-3">
                    <div className="card mb-3">
                        <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
                        <div className="card-body position-relative">
                            <div className="row">
                                <div className="col-lg-8">
                                    <h3><Icon icon="basil:invoice-solid" width="50" height="50" /> Production: <span className='text-success'>{production?.code_production}</span></h3>
                                    <Link class="btn btn-link btn-sm ps-0 mt-2" to={"/me/production"}>
                                        <span class="fas fa-list" data-fa-transform="shrink-3 down-2">
                                        </span>
                                        La liste des productions<span class="fas fa-chevron-right ms-1 fs--2"></span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row m-0 p-0 mb-3">
                    <div className='col-md-12 p-0'>
                        <div className="card mb-3">
                            <div className="card-header bg-light">
                                <div className="row align-items-center">
                                    <div className="col">
                                    <h5 className="mb-3" id="followers">Etate précedente: <span className='text-success'>{production?.production_step.value}</span></h5>
                                    <h6>Assigné à:
                                    { production && production?.assignments.length > 0 ? (
                                        production?.assignments.map(personnel => (
                                            <span class="badge rounded-pill badge-soft-success ms-2">{personnel?.first_name} {personnel?.last_name}</span>
                                        ))
                                    ):null}
                                    </h6>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="card-body bg-light px-1 py-0">
                                <div className="row g-0 fs--1">
                                <form onSubmit={(e)=> productionStepHandler(e)}>
                                    <div className="col-lg-12">
                                        <div className="d-flex">
                                            <div className="flex-1">
                                                <div className="row">
                                                    <h5 className="mb-3" id="followers">Etate suivante</h5>

                                                    {/* <input type="text" defaultValue={production?.code} />
                                                    <input type="hidden" defaultValue={production?.production_step.code} /> */}
                                                    <div className="col-md-6">
                                                        <label className="form-label" htmlFor="organizerMultiple">
                                                            Employé(s)
                                                        </label>
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text bg-primary" id="basic-addon1">
                                                                <Icon icon="clarity:employee-group-solid" className='text-white' width="25" height="25" />
                                                            </span>
                                                            <Select
                                                                closeMenuOnSelect={false}
                                                                components={animatedComponents}
                                                                isMulti
                                                                options={options ?? []}
                                                                className="form-control"
                                                                onChange={(choice)=>setSelectedPersonnels(choice)}
                                                            />
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                </div>
                                                {/* </div> */}
                                            </div>
                                        </div>

                                        <div className="d-flex">
                                            <div className="flex-1">
                                                <button className="btn btn-outline-danger rounded-pill me-1 mb-1 float-end" type="reset">Annuler</button>
                                                <button className="btn btn-outline-primary rounded-pill me-1 mb-1 btn-edit float-end"
                                                    type="submit" disabled={loaderState.show}>etape suivante</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductionStep;