import React, { useEffect, useState } from "react";
import { BGHOLDER as bg, checkStatusCodes } from "../../../constants";

import Modal from "react-bootstrap/Modal";

// import { editCommande } from './services/commande.service';
import { editCommande } from "../services/commande.service";

// import useConfirm from '../../helpers/confirm.message.helper';

function CommandeStatusModal({
  selectedCommande,
  showStatus,
  handleClose,
  handleToast,
}) {
  const [codeCommande, setCodeCommande] = useState("");
  // const confirm = useConfirm();

  const commandeValid = async () => {
    const commandeValidUrl = `/invoices/orders/${
      codeCommande ? codeCommande : ""
    }/valided`;
    const response = await editCommande(commandeValidUrl, {});
    let state = false;
    let message = "";
    let type = "";
    if (checkStatusCodes(response.data.statusCode)) {
      state = true;
      message = response.data.message;
      type = "success";
      handleToast(state, type, message);
      setTimeout(() => {
        window.location.reload(); // Recharge la page
      }, 2000);
    } else {
      state = true;
      message = response.data.message;
      type = "error";
      handleToast(state, type, message);
    }
  };

  const commandeCancel = async () => {
    // const choice = await confirm({
    //     title: "Delete all",
    //     description: "Are you sure you want to delete everything?",
    //     confirmBtnLabel: "Yes",
    //   });
    if (window.confirm("Etes-vous sûr de refuser la commande")) {
      const commandeCancelUrl = `/invoices/orders/${
        codeCommande ? codeCommande : ""
      }/canceled`;
      const response = await editCommande(commandeCancelUrl, {});
      let state = false;
      let message = "";
      let type = "";
      if (checkStatusCodes(response.data.statusCode)) {
        state = true;
        message = response.data.message;
        type = "success";
        handleToast(state, type, message);
        setTimeout(() => {
          window.location.reload(); // Recharge la page
        }, 2000);
      } else {
        state = true;
        message = response.data.message;
        type = "error";
        handleToast(state, type, message);
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    // Mettez à jour le type d'utilisateur en fonction du rôle sélectionné
    if (selectedCommande) {
      setCodeCommande(selectedCommande.code);
    }
  }, [selectedCommande]);

  return (
    <Modal show={showStatus} onHide={handleClose} animation={true}>
      <div className="modal-content position-relative">
        <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
        <Modal.Body className="modal-body p-0">
          <div className="rounded-top-lg py-3 ps-4 pe-6 bg-light">
            <h5 className="mb-1" id="modalExampleDemoLabel">
              changer le statut de la commande:{" "}
              {selectedCommande ? (
                <span className="text-success">{selectedCommande.number}</span>
              ) : null}
            </h5>
          </div>
          <div className="action_status">
            <div className="d-flex justify-content-center m-2 ">
              <button
                className="btn btn-outline-success mx-2 btn-sm change-status-user"
                type="button"
                onClick={() => {
                  commandeValid();
                  handleClose();
                }}
              >
                Accepter
              </button>
              <button
                className="btn btn-outline-danger btn-sm"
                type="button"
                onClick={() => {
                  commandeCancel();
                  handleClose();
                }}
              >
                Refuser
              </button>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
}

export default CommandeStatusModal;
