import ApiHelper from "../../../libs/Api";

export const commercialList = async (
  commercialListUrl = "/customers/commercials/all?page=1&limit=100"
) => {
  try {
    return await ApiHelper.request(commercialListUrl);
  } catch (error) {
    return error.response;
  }
};

const commercialCreationUrl = "/customers/commercials/create";

export const createCommercial = async (body) => {
  try {
    return await ApiHelper.request(commercialCreationUrl, "post", body);
  } catch (error) {
    return error.response;
  }
};

export const editCommercial = async (commercialEditUrl, body) => {
  try {
    return await ApiHelper.request(commercialEditUrl, "put", body);
  } catch (error) {
    return error.response;
  }
};
