import ApiHelper from "../../../libs/Api";

export const personnelList = async (
  personnelUrl = "/personnals/all?page=1&limit=10"
) => {
  try {
    return await ApiHelper.request(personnelUrl);
  } catch (error) {
    return error.response;
  }
};

export const personnelTypeList = async (
  personnelTypeUrl = "/personnals/categories/all"
) => {
  try {
    return await ApiHelper.request(personnelTypeUrl);
  } catch (error) {
    return error.response;
  }
};

const personnelCreationUrl = "/personnals/create";

export const createPersonnel = async (body) => {
  try {
    return await ApiHelper.request(personnelCreationUrl, "post", body);
  } catch (error) {
    return error.response;
  }
};

export const editPersonnel = async (personnelEditUrl, body) => {
  try {
    return await ApiHelper.request(personnelEditUrl, "put", body);
  } catch (error) {
    return error.response;
  }
};
