import React, { useCallback, useEffect, useState } from "react";
import { useTrail, animated as a } from "react-spring";
import { Icon } from "@iconify/react";

import Loader from "../../components/partials/Loader";
import ToastMessage from "../../components/messages/ToastMessage";
import { BGHOLDER as bg, checkStatusCodes } from "../../constants";
import Pagination from "../../components/partials/Pagination";
import {
  caisseList,
  caisseReport,
} from "../../components/finance/services/caisse.service";
import CaisseAddModal from "../../components/finance/CaisseAddModal";
import { dateFormatToFr } from "../../helpers/dates.helper";
import { formaterNombreAvecEspace } from "../../helpers/numbers.helper";
import CaisseEditModal from "../../components/finance/CaisseEditModal";

function Caisse(props) {
  const [caisses, setCaisses] = useState([]);
  const [report, setReport] = useState(null);
  const [paginator, setPaginator] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loaderState, setLoaderState] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [toastState, setToastState] = useState({
    show: false,
    type: "",
    message: "",
  });

  const trail = useTrail(caisses?.length || 0, {
    from: { opacity: 0, transform: "translate3d(0,40px,0)" },
    to: { opacity: 1, transform: "translate3d(0,0px,0)" },
    config: { mass: 1, tension: 200, friction: 15 },
  });

  const handleToast = (show, type, message) => {
    const updatedToast = { ...toastState };
    updatedToast.show = show;
    updatedToast.type = type;
    updatedToast.message = message;

    setToastState(updatedToast);
  };
  const handleClose = () => {
    setShowAdd(false);
    setShowEdit(false);
  };
  const handleShowAdd = () => setShowAdd(true);
  const handleShowEdit = () => setShowEdit(true);
  const showLoader = useCallback((state) => setLoaderState(state), []);

  const fetchCaisseList = async () => {
    const response = await caisseList();
    if (checkStatusCodes(response.data.statusCode)) {
      setCaisses(response.data.data.list);
      setPaginator(response.data.data.pagination);
      setLoaderState(false);
    } else {
      let state = true;
      let message = "Vous n'avez pas les authorisations réquises";
      let type = "error";
      handleToast(state, type, message);
    }
  };

  const fetchCaisseReport = async () => {
    const response = await caisseReport();
    if (checkStatusCodes(response.data.statusCode)) {
      setReport(response.data.data);
    } else {
      let state = true;
      let message = "Vous n'avez pas les authorisations réquises";
      let type = "error";
      handleToast(state, type, message);
    }
  };

  // const type = usersType
  const handleEditClick = (transaction) => {
    setSelectedTransaction(transaction);
    handleShowEdit();
  };

  const PanaginationList = async (page) => {
    setLoaderState(true);
    setCurrentPage(page);
    try {
      const response = await caisseList(
        `/finances/transactions/all?page=${page}&limit=10`
      );
      setCaisses(response.data.data.list);
      setPaginator(response.data.data.pagination);
      setLoaderState(false);
    } catch (error) {}
  };

  const addCaisseToList = useCallback((newCaisse) => {
    setCaisses((prevCaisse) => [...prevCaisse, newCaisse]);
  }, []);

  const updateCaisseInList = useCallback((updatedCaisse) => {
    setCaisses((prevCaisse) =>
      prevCaisse.map((caisse) =>
        caisse.code === updatedCaisse.code ? updatedCaisse : caisse
      )
    );
  }, []);

  const renderTableData = () => {
    return (
      <tbody className="list_admin">
        {caisses && caisses.length > 0 ? (
          trail.map(({ opacity, transform }, index) => (
            <a.tr
              key={caisses[index]?.code}
              style={{
                opacity,
                transform,
              }}
            >
              <td>
                {formaterNombreAvecEspace(caisses[index]?.sum_transaction)} XOF
              </td>
              <td>{caisses[index]?.mode_transaction}</td>
              <td>{caisses[index]?.number_transaction}</td>
              <td>
                {caisses[index]?.type_transaction === "cash_in" ? (
                  <span class="badge rounded-pill badge-soft-primary">
                    Entrée
                  </span>
                ) : caisses[index]?.type_transaction === "cash_out" ? (
                  <span class="badge rounded-pill badge-soft-warning">
                    Sortie
                  </span>
                ) : null}
              </td>
              <td>{dateFormatToFr(caisses[index]?.date)}</td>
              <td>
                <div className="d-flex align-items-center justify-content-center">
                  <button
                    className="btn ms-2 btn-warning px-2 py-1"
                    type="button"
                    title="Modifier"
                    onClick={() => handleEditClick(caisses[index])}
                  >
                    <i className="text-500 text-white fas fa-edit"></i>
                  </button>
                </div>
              </td>
            </a.tr>
          ))
        ) : (
          <tr>
            <td colSpan="5" className="text-center">
              Aucun transaction disponible.
            </td>
          </tr>
        )}
      </tbody>
    );
  };

  useEffect(() => {
    setLoaderState(true);
    fetchCaisseList();
    fetchCaisseReport();
  }, []);
  return (
    <div>
      {loaderState ? <Loader loaderState={loaderState} /> : null}

      {toastState.show ? <ToastMessage toastState={toastState} /> : null}
      <div className="row g-3 mb-3 p-0">
        <div className="row mb-3 p-0 ps-3">
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={bg}
            ></div>
            <div className="card-body position-relative">
              <div className="row">
                <div className="col-lg-8">
                  <h3>
                    <Icon
                      icon="fa6-solid:cash-register"
                      width="50"
                      height="50"
                    />{" "}
                    Caisse
                  </h3>
                  <p className="mb-0">Gestion de la caisse</p>
                </div>
                <div className="col-lg-4">
                  <div
                    className="d-flex align-items-center justify-content-end my-3"
                    id="bulk-select-replace-element"
                  >
                    <button
                      className="btn btn-falcon-success btn-sm"
                      type="button"
                      onClick={handleShowAdd}
                    >
                      <span
                        className="fas fa-plus"
                        data-fa-transform="shrink-3 down-2"
                      ></span>
                      <span className="ms-1">Nouveau enrégistrement</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 p-0 ps-3">
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={bg}
            ></div>
            <div className="card-body position-relative py-0 my-0 justify-content-center">
              <div className="row mt-3">
                <div class="col-md-4 mb-2">
                  <h6>Montant actuel </h6>
                  <span className="text-success fs--4">
                    {formaterNombreAvecEspace(report?.sum_actual ?? 0)} XOF
                  </span>
                </div>
                <div class="col-md-4 mb-2">
                  <h6>Montant Entré </h6>
                  <span className="text-success fs--4">
                    {formaterNombreAvecEspace(report?.cash_in)} XOF
                  </span>
                </div>
                <div class="col-md-4 mb-2">
                  <h6>Montant Sortie </h6>
                  <span className="text-success fs--4">
                    {formaterNombreAvecEspace(report?.cash_out)} XOF
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 p-0 ps-3">
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={bg}
            ></div>
            <div className="card-body position-relative">
              <div className="row p-0">
                <div className="col-md-6">
                  <div className="col-12 input-group" id="search_box">
                    <input
                      type="text"
                      className="form-control input-sm m-0"
                      placeholder="Rechercher"
                      id="searchInput"
                    />
                    <span className="input-group-text" id="basic-addon3">
                      <a href="" className="search">
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </a>
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="col-12 input-group" id="search_box">
                    <input
                      type="date"
                      className="form-control input-sm m-0"
                      placeholder="Rechercher"
                      id="searchInput"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 p-0 mb-3">
          <div className="col-md-12 p-0">
            <div className="card mb-3">
              <div className="card-header bg-light">
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="mb-0" id="followers">
                      Les Transactions
                    </h5>
                  </div>
                </div>
              </div>
              <div className="card-body bg-light px-1 py-0">
                <div className="row g-0 fs--1">
                  <div
                    id="tableExample2"
                    data-list='{"valueNames":["Nom","email","telephone","role"],"page":5,"pagination":true}'
                  >
                    <div className="table-responsive scrollbar">
                      <table
                        className="table table-bordered table-striped fs--1 mb-0"
                        id="table"
                      >
                        <thead className="bg-200 text-900">
                          <tr>
                            <th className="sort" data-sort="email">
                              Somme de la transaction
                            </th>
                            <th className="sort" data-sort="mode">
                              Mode transaction
                            </th>
                            <th className="sort" data-sort="numero">
                              N° de la transaction
                            </th>
                            <th className="sort" data-sort="numero">
                              Type de transaction
                            </th>
                            <th className="sort" data-sort="date">
                              Date de la transaction
                            </th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        {renderTableData()}
                      </table>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <Pagination
                        paginator={paginator}
                        currentPage={currentPage}
                        limit={10}
                        pagination={PanaginationList}
                      />
                    </div>
                  </div>
                  <CaisseAddModal
                    showAdd={showAdd}
                    handleClose={handleClose}
                    handleToast={handleToast}
                    showLoader={showLoader}
                    addCaisseToList={addCaisseToList}
                    fetchCaisseReport={fetchCaisseReport}
                  />
                  {selectedTransaction && (
                    <CaisseEditModal
                      selectedTransaction={selectedTransaction}
                      showEdit={showEdit}
                      handleClose={handleClose}
                      handleToast={handleToast}
                      updateCaisseInList={updateCaisseInList}
                      showLoader={showLoader}
                      fetchCaisseReport={fetchCaisseReport}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Caisse;
