import React from "react";
import { Link } from "react-router-dom";

function Verify(props) {
  return (
    <main
      className="main"
      id="top"
      style={{
        backgroundImage: "url(../../../assets/publicbg.jpeg)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="container" data-layout="container">
        {/* <script>
                    var isFluid = JSON.parse(localStorage.getItem('isFluid'));
                    if (isFluid) {
                    var container = document.querySelector('[data-layout]');
                    container.classList.remove('container');
                    container.classList.add('container-fluid');
                    }
                </script> */}
        <div className="row flex-center min-vh-100 py-6 text-center">
          <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
            <a className="d-flex flex-center mb-4" to={"/"}>
              <img
                className="me-2 bg-dark rounded-circle"
                src="../../../assets/logo8.png"
                alt=""
                width="58"
              />
              <span className="font-sans-serif fw-bolder fs-5 d-inline-block">
                E-Stock
              </span>
            </a>
            <div className="card">
              <div className="card-body p-4 p-sm-5">
                <h5 className="mb-0">Vérification de l'email</h5>
                <small>Entrer le code envoyé dans ton mail.</small>
                <form className="mt-4">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Code"
                  />
                  <div className="mb-3"></div>
                  <Link
                    className="btn btn-primary d-block w-100 mt-3"
                    to={"/changepassword"}
                  >
                    Valider
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Verify;
