import React from 'react';
import LineChartComponent from '../../components/MyCharts/ProductionChart';
import LineChartComponentForCaisse from '../../components/MyCharts/CaisseChart';
import { BGHOLDER as bg } from '../../constants';

function Dashboard(props) {
    const styleInline = {
        backgroundImage: 'url(../assets/img/illustrations/ecommerce-bg.png)',
        backgroundSize: '230px',
        backgroundPosition: 'right bottom',
        zIndex: -1
      };
    return (
            <div className="row g-3">
                {/* <div className="col-12">
                    <div className="card bg-transparent-50 overflow-hidden">
                        <div className="card-header position-relative">
                            <div className="bg-holder d-none d-md-block bg-card z-index-1"
                                style={styleInline}>
                            </div>
                            <div class="position-relative z-index-2">
                                <div>
                                    <h3 class="text-primary mb-1 welcom">Good Afternoon, Jonathan!</h3>
                                    <p>voici ce qui se passe à <span class="sb-text">Subito</span> </p>
                                </div>
                                <div class="d-flex py-3">
                                    <div class="pe-3">
                                        <p class="text-600 fs--1 fw-medium">Réservations totales </p>
                                        <h4 class="text-800 mb-0" id="reservation_total"></h4>
                                    </div>
                                    <div class="ps-3">
                                        <p class="text-600 fs--1">Réservations bouclées </p>
                                        <h4 class="text-800 mb-0" id="reservation_bouclee"></h4>
                                    </div>
                                    <div class="ps-3">
                                        <p class="text-600 fs--1">Réservations en attente </p>
                                        <h4 class="text-800 mb-0" id="reservation_waiting"></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div> */}
                <div className="col-12">
                    <div class="row g-3 mb-3">
                        <div class="col-md-3">
                            <div class="card h-100">
                                <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
                                <div class="card-body">
                                    <div class="row flex-between-center">
                                        <div class="col d-md-flex d-lg-block flex-between-center">
                                            <h6 class="mb-md-0 mb-lg-2">Employé(s)</h6>
                                        </div>
                                        <div class="col-auto">
                                            <h4 class="fs-3 fw-normal text-700 text-primary client"
                                                data-countup='{"endValue":82.18,"decimalPlaces":2,"suffix":"M","prefix":"$"}'><span className='text-success'>10</span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="card h-100">
                                <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
                                <div class="card-body">
                                    <div class="row flex-between-center">
                                        <div class="col d-md-flex d-lg-block flex-between-center">
                                            <h6 class="mb-md-0 mb-lg-2">Entreprise(s)</h6>
                                        </div>
                                        <div class="col-auto">
                                            <h4 class="fs-3 fw-normal text-700 text-primary company"
                                                data-countup='{"endValue":82.18,"decimalPlaces":2,"suffix":"M","prefix":"$"}'><span className='text-success'>11</span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="card h-100">
                                <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
                                <div class="card-body">
                                    <div class="row flex-between-center">
                                        <div class="col d-md-flex d-lg-block flex-between-center">
                                            <h6 class="mb-md-0 mb-lg-2">Commerciaux</h6>
                                        </div>
                                        <div class="col-auto">
                                            <h4 class="fs-3 fw-normal driver"
                                                data-countup='{"endValue":28.5,"decimalPlaces":2,"suffix":"%"}'><span className='text-success'>20</span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="card h-100">
                                <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
                                <div class="card-body">
                                    <div class="row flex-between-center">
                                        <div class="col d-md-flex d-lg-block flex-between-center">
                                            <h6 class="mb-md-0 mb-lg-2">Fournisseur(s)</h6>
                                        </div>
                                        <div class="col-auto">
                                            <h4 class="fs-3 fw-normal driver"
                                                data-countup='{"endValue":28.5,"decimalPlaces":2,"suffix":"%"}'><span className='text-success'>05</span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div class="row g-3 mb-3">
                        <div class="col-md-6">
                            <div class="card h-100">
                                {/* <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div> */}
                                <span class="card-title text-center fs--1 mt-3">Courbe d'évolution: Caisse (Entrée/Sortie)</span>
                                <div class="card-body">
                                    <LineChartComponent />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card h-100">
                                {/* <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div> */}
                                <span class="card-title text-center fs--1 mt-3">Courbe d'évolution: Production</span>
                                <div class="card-body">
                                    <LineChartComponentForCaisse/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default Dashboard;
