import ApiHelper from "../../../libs/Api";

export const congeList = async (congeUrl = "/personnals/vacations/all") => {
  try {
    return await ApiHelper.request(congeUrl);
  } catch (error) {
    return error.response;
  }
};

const congeCreationUrl = "/personnals/vacations/create";

export const createConge = async (body) => {
  try {
    return await ApiHelper.request(congeCreationUrl, "post", body);
  } catch (error) {
    return error.response;
  }
};

export const editConge = async (congeEditUrl, body) => {
  try {
    return await ApiHelper.request(congeEditUrl, "put", body);
  } catch (error) {
    return error.response;
  }
};
