import ApiHelper from "../../../libs/Api";

/**
 * Retrieves a list of productions from the API.
 *
 * @param {string} [productionListUrl="/productions/all?page=1&limit=10"] - The URL to fetch the productions from. Defaults to "/productions/all?page=1&limit=10".
 * @return {Promise<Object>} A promise that resolves to the response from the API, or rejects with the error response.
 */
export const productionList = async (
  productionListUrl = "/productions/all?page=1&limit=10"
) => {
  try {
    return await ApiHelper.request(productionListUrl);
  } catch (error) {
    return error.response;
  }
};

const productionCreationUrl = "/productions/create";
export const createProduction = async (body) => {
  try {
    return await ApiHelper.request(productionCreationUrl, "post", body);
  } catch (error) {
    return error.response;
  }
};

export const editProduction = async (body) => {
  try {
    return await ApiHelper.request(productionCreationUrl, "put", body);
  } catch (error) {
    return error.response;
  }
}
const productionStepCreationUrl = "/productions/histories/create";

export const createProductionStep = async (body) => {
  try {
    return await ApiHelper.request(productionStepCreationUrl, "post", body);
  } catch (error) {
    return error.response;
  }
};
