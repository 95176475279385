import React from "react";
import { Route, Routes } from "react-router-dom";
import PublicLayout from "../components/PublicLayout";
import Login from "../pages/publicPage/Login";
import PasswordForget from "../pages/publicPage/PasswordForget";
import Verify from "../pages/publicPage/Verify";
import ChangePassword from "../pages/publicPage/ChangePassword";
import NotFound from "./NotFound";

function PublicRouter(props) {
  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route path="/" element={<Login />} />
        <Route path="/passwordforget" element={<PasswordForget />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/changepassword" element={<ChangePassword />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default PublicRouter;
