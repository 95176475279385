import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";

import { BGHOLDER as bg, checkStatusCodes } from "../../constants";
import { createPersonnel } from "./services/personnel.service";

function PersonnelAddModal({
  personnelTypes,
  showAdd,
  handleClose,
  handleToast,
  addPersonnelToList,
  showLoader,
}) {
  // const [value, setValue] = useState()
  // const [phone, setPhone] = useState("")

  const { register, handleSubmit } = useForm();

  const personnelCreation = async (data) => {
    showLoader(true);
    // data.phone = phone
    data.number_of_children = parseInt(data.number_of_children);
    data.salary = parseInt(data.salary);
    const response = await createPersonnel(data);
    let state = false;
    let message = "";
    let type = "";
    if (checkStatusCodes(response.data.statusCode)) {
      showLoader(false);
      state = true;
      message = response.data.message;
      type = "success";
      handleToast(state, type, message);
      addPersonnelToList(response.data.data);
    } else {
      showLoader(false);
      state = true;
      message = response.data.message;
      type = "error";
      handleToast(state, type, message);
    }
  };

  return (
    <Modal
      show={showAdd}
      onHide={handleClose}
      animation={true}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
      <Modal.Header>
        <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
          <button
            class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
            onClick={handleClose}
          ></button>
        </div>
        <div className="bg-light rounded-top-lg py-3 ps-4 pe-6">
          <h4 className="mb-1" id="staticBackdropLabel">
            Ajouter un personnel
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="p-4">
          <div className="row">
            <form onSubmit={handleSubmit((data) => personnelCreation(data))}>
              <div className="col-lg-12">
                <div className="d-flex">
                  <div className="flex-1">
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="fullname">
                          Prenom:
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <i className="fas fa-user text-white"></i>
                          </span>
                          <input
                            id="fullname"
                            className="form-control sb-input"
                            type="text"
                            aria-label="fullname"
                            placeholder="Prenom"
                            aria-describedby="basic-addon1"
                            {...register("first_name", { required: true })}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="fullname">
                          Nom:
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <i className="fas fa-user text-white"></i>
                          </span>
                          <input
                            id="fullname"
                            className="form-control sb-input"
                            type="text"
                            aria-label="fullname"
                            placeholder="Nom"
                            aria-describedby="basic-addon1"
                            {...register("last_name", { required: true })}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="email">
                          Email
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <i className="fas fa-envelope text-white"></i>
                          </span>
                          <input
                            id="email"
                            className="form-control sb-input"
                            type="email"
                            aria-label="email"
                            placeholder="Saisissez votre adresse email"
                            aria-describedby="basic-addon1"
                            {...register("email", { required: false })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6 mt-3">
                        <label htmlFor="phone" className="form-label">
                          Telephone
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <i className="fas fa-phone text-white"></i>
                          </span>
                          <input
                            id="phone"
                            className="form-control sb-input"
                            type="tel"
                            aria-label="Telephone"
                            placeholder="Ex: +22177.....87"
                            aria-describedby="basic-addon1"
                            {...register("phone", { required: false })}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mt-3">
                        <label htmlFor="personnel_role">Rôle</label>
                        <select
                          className="form-select js-choice mb-3"
                          id="personnel_role"
                          size="1"
                          name="personnel_role"
                          data-options='{"removeItemButton":true}'
                          {...register("code_category", { required: true })}
                        >
                          <option value="">Sélectionner un rôle...</option>
                          {personnelTypes && personnelTypes.length > 0
                            ? personnelTypes.map((type) => (
                                <option key={type.code} value={type.code}>
                                  {type.name}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="date_naissance">
                          Date de naissance:
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <i className="fas fa-calendar text-white"></i>
                          </span>
                          <input
                            id="date_naissance"
                            className="form-control sb-input"
                            type="date"
                            aria-label="date_naissance"
                            placeholder="Date de naissance"
                            aria-describedby="basic-addon1"
                            {...register("date_of_birth", { required: false })}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="lieu_naissance">
                          Lieu de naissance
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <i className="fas fa-map-pin text-white"></i>
                          </span>
                          <input
                            id="lieu_naissance"
                            className="form-control sb-input"
                            type="text"
                            aria-label="lieu_naissance"
                            placeholder="Lieu de naissance"
                            aria-describedby="basic-addon1"
                            {...register("place_of_birth", { required: false })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="situation_familiale">
                          Situation familiale:
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="material-symbols:family-restroom"
                              className="text-white"
                              width="25"
                              height="25"
                            />
                          </span>
                          <input
                            id="situation_familiale"
                            className="form-control sb-input"
                            type="text"
                            aria-label="situation_familiale"
                            placeholder="Situation familiale"
                            aria-describedby="basic-addon1"
                            {...register("family_situation", {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="nbr_enfants">Nombre d'enfants</label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="fa-solid:child"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="nbr_enfants"
                            className="form-control sb-input"
                            type="number"
                            aria-label="nbr_enfants"
                            aria-describedby="basic-addon1"
                            {...register("number_of_children", {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="salaire">Salaire de base:</label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <i className="fas fa-coins text-white"></i>
                          </span>
                          <input
                            id="salaire"
                            className="form-control sb-input"
                            type="number"
                            aria-label="salaire"
                            placeholder="Montant du salaire"
                            aria-describedby="basic-addon1"
                            {...register("salary", { required: false })}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="contrat">Contrat</label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="clarity:contract-solid"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="contrat"
                            className="form-control sb-input"
                            type="text"
                            aria-label="contrat"
                            aria-describedby="basic-addon1"
                            {...register("contract", { required: false })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="diplome">
                          Diplomes
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary "
                            id="basic-addon1"
                          >
                            <Icon
                              icon="mdi:certificate"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="diplome"
                            className="form-control sb-input"
                            type="text"
                            aria-label="diplome"
                            aria-describedby="basic-addon1"
                            {...register("diploma", { required: false })}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label
                          className="form-label"
                          htmlFor="recruitment_date"
                        >
                          Date de recrutement
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <i className="fas fa-calendar text-white"></i>
                          </span>
                          <input
                            id="recruitment_date"
                            className="form-control sb-input"
                            type="date"
                            aria-label="Date de recrutement"
                            aria-describedby="basic-addon1"
                            {...register("recruitment_date", {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="flex-1">
                    <button
                      className="btn btn-outline-danger rounded-pill me-1 mb-1"
                      type="button"
                      onClick={handleClose}
                    >
                      Annuler
                    </button>
                    <button
                      className="btn btn-outline-primary rounded-pill me-1 mb-1 btn-edit"
                      type="submit"
                      id="add_personnel"
                      onClick={handleClose}
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default PersonnelAddModal;
