import React from 'react';
import { BGHOLDER as bg, checkStatusCodes } from '../../../constants';
import { useForm } from 'react-hook-form';
import { Icon } from '@iconify/react';
import Modal from 'react-bootstrap/Modal';
import { createInvoice } from '../services/invoice.service';
import { LogOut } from '../../../helpers/storage.helper';

function InvoiceAddModal({ commandes, showAdd, handleClose, handleToast}) {
    const {
        register,
        handleSubmit,
    } = useForm()

    const invoiceCreation = async (data) => {
        handleClose()
        const response = await createInvoice(data)
        let state = false
        let message = ""
        let type = ""
        if(checkStatusCodes(response.data.statusCode)){
            state = true
            message = response.data.message
            type = "success"
            handleToast(state, type, message)
            setTimeout(() => {
                window.location.reload(); // Recharge la page
            }, 2000);
        }else{
            state = true
            message = response.data.message
            type = "error"
            handleToast(state, type, message)
            // if(response.data.message === "Acess Token incorrecte" || parseInt(response.data.statusCode) === 403){
            //     console.log("log out");
            //     // setTimeout(() => {
            //     //     LogOut()
            //     // }, 2000);
            // }
        }        
    };

    return (
        <Modal show={showAdd} onHide={handleClose} animation={true} size="lg" aria-labelledby="example-modal-sizes-title-lg">
            <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
            <Modal.Header>
                <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
                    <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                        onClick={handleClose}></button>
                </div>
                <div className="bg-light rounded-top-lg py-3 ps-4 pe-6">
                        <h4 className="mb-1" id="staticBackdropLabel">
                            Enrégistrer une facture
                        </h4>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="p-4">
                    <div className="row">
                        <form onSubmit={handleSubmit((data) => invoiceCreation(data))}>
                            <div className="col-lg-12">
                                <div className="d-flex">
                                    <div className="flex-1">
                                        <div className="row">
                                        <div className="col-md-12">
                                                <label className="form-label" htmlFor="produitType">
                                                    Liste commande
                                                </label>
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text bg-primary" id="basic-addon1">
                                                        <Icon icon="icon-park-solid:transaction-order" className='text-white' width="25" height="25" />
                                                    </span>
                                                    <select 
                                                        className="form-select js-choice" 
                                                        id="produitType"
                                                        size="1"
                                                        name="produitType" 
                                                        data-options='{"removeItemButton":true}' 
                                                        {...register("order", { required: true })}
                                                        >
                                                        <option value="">Sélectionner une commande...</option>
                                                        {commandes.map(commande => (
                                                            <option key={commande?.code} value={commande.code} >N° {commande?.number} ({commande?.owner?.name})</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="flex-1">
                                        <button className="btn btn-outline-danger rounded-pill me-1 mb-1" type="button"
                                            onClick={handleClose}>Annuler</button>
                                        <button className="btn btn-outline-primary rounded-pill me-1 mb-1 btn-edit"
                                            type="submit" id="edit_charge_send">Valider</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default InvoiceAddModal;