import ApiHelper from "../../../libs/Api";

export const userList = async (userListUrl = "/user/all?page=1&limit=10") => {
  try {
    return await ApiHelper.request(userListUrl);
  } catch (error) {
    return error.response;
  }
};

export const userTypes = async (userTypeUrl = "/user/type/all") => {
  try {
    return await ApiHelper.request(userTypeUrl);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const userCreationUrl = "/user/create";

export const createUser = async (body) => {
  try {
    return await ApiHelper.request(userCreationUrl, "post", body);
  } catch (error) {
    return error.response;
  }
};

export const editUser = async (userEditUrl, body) => {
  try {
    return await ApiHelper.request(userEditUrl, "put", body);
  } catch (error) {
    return error.response;
  }
};
