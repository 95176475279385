import axios from "axios";
import { getToken } from "../helpers/storage.helper";

// const BASE_URL = "https://api-dev.anita.sn/api/v1";

// export const Axios = axios.create({
//   baseURL: BASE_URL,
//   mode: "cors",
// });

// export const setHeadersWithToken = () => {
//   return `Bearer ${getToken()}`;
// };

/**
 * This code snippet is defining a class named `ApiHelper` as a default export. Within this class:
 * 1. It declares a static property `BASE_URL` with the value `"https://api-dev.anita.sn/api/v1"`. This
 * property holds the base URL for API requests.
 * 2. It defines a static method `setHeadersWithToken` that returns a string with the format `"Bearer
 * ${getToken()}"`. This method is used to set the authorization header with a token retrieved from the
 * `getToken()` function.
 * 3. It creates a static property `axiosInstance` using `axios.create()` method. This property is an
 * instance of Axios configured with the base URL from `BASE_URL` and the mode set to `"cors"`. This
 * instance will be used to make HTTP requests to the API.
 */
export default class ApiHelper {
  static BASE_URL = ApiHelper.getBaseUrl();

  static setHeadersWithToken = () => {
    return `Bearer ${getToken()}`;
  };

  static getBaseUrl() {
    return process.env.REACT_APP_BASE_URL;
  }

  static axiosInstance = axios.create({
    baseURL: this.BASE_URL,
    mode: "cors",
  });

  /**
   *
   * @param {string} url
   * @param {string} method // get, post, put, delete
   * @param {object} body
   * @returns {AxiosResponse}
   */
  static request = async (url, method = "get", body = {}) => {
    this.axiosInstance.interceptors.request.use((config) => {
      config.headers.Authorization = this.setHeadersWithToken();
      config.headers["Content-Type"] = "application/json";
      return config;
    });

    try {
      switch (method) {
        case "get":
          return await this.axiosInstance.get(url);
        case "post":
          return await this.axiosInstance.post(url, body);
        case "put":
          return await this.axiosInstance.put(url, body);
        case "delete":
          return await this.axiosInstance.delete(url, body);
        default:
          return await this.axiosInstance.get(url);
      }
    } catch (error) {
      throw error;
    }
  };
}
