import React, { useEffect, useState } from 'react';
import { BGHOLDER as bg, checkStatusCodes } from '../../../constants';
import { useForm } from 'react-hook-form';
import { Icon } from '@iconify/react';
import Modal from 'react-bootstrap/Modal';
import { dateFormatForSubmit } from '../../../helpers/dates.helper';
import { editInvoicePayment } from '../services/invoice.service';

function InvoicePayment({ selectedInvoice, showStatus, handleClose, handleToast}) {
    const [invoiceCode, setInvoiceCode] = useState("")

    const {
        register,
        handleSubmit,
    } = useForm()

    const invoiceEdit = async (data) => {
        handleClose()
        data.date = dateFormatForSubmit(data.date)
        data.sum_transaction = parseInt(data.sum_transaction)
        const invoiceEditUrl = "/invoices/payment_histories/create"
        const response = await editInvoicePayment(invoiceEditUrl, data)
        let state = false
        let message = ""
        let type = ""
        if(checkStatusCodes(response.data.statusCode)){
            state = true
            message = response.data.message
            type = "success"
            handleToast(state, type, message)
            setTimeout(() => {
                window.location.reload(); // Recharge la page
            }, 2000);
        }else{
            state = true
            message = response.data.message
            type = "error"
            handleToast(state, type, message)
        }        
    };

    useEffect(() => {
        if (selectedInvoice) {
            setInvoiceCode(selectedInvoice.code)
        }
    }, [selectedInvoice])
    return (
        <Modal show={showStatus} onHide={handleClose} animation={true} size="lg" aria-labelledby="example-modal-sizes-title-lg">
            <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
            <Modal.Header>
                <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
                    <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                        onClick={handleClose}></button>
                </div>
                <div className="bg-light rounded-top-lg py-3 ps-4 pe-6">
                        <h4 className="mb-1" id="staticBackdropLabel">
                            faire un paiement pour la facture N° <span className='text-success'>{selectedInvoice?.number}</span>
                        </h4>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="p-4">
                    <div className="row">
                        <form onSubmit={handleSubmit((data) => invoiceEdit(data))}>
                            <div className="col-lg-12">
                                <div className="d-flex">
                                    <div className="flex-1">
                                        <div className="row">
                                            <input
                                            type="hidden"
                                            defaultValue={selectedInvoice?.code} 
                                            {...register("code_invoice", { required: true })}
                                            />
                                            <div className="col-md-6">
                                                <label className="form-label" htmlFor="sum_transaction">
                                                    Somme de la transaction:
                                                </label>
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text bg-primary" id="basic-addon1">
                                                        <Icon icon="tdesign:undertake-transaction" width="25" height="25" className='text-white'/>
                                                    </span>
                                                    <input
                                                    id="sum_transaction"
                                                    className="form-control sb-input"
                                                    type="number"
                                                    aria-label="sum_transaction"
                                                    placeholder="Somme de la transaction"
                                                    aria-describedby="basic-addon1"
                                                    {...register("sum_transaction", { required: true })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="mode_transaction" className="form-label">Mode de transaction</label>
                                                <div className="input-group ">
                                                    <span className="input-group-text bg-primary" id="basic-addon1">
                                                        <Icon icon="wpf:bank-cards" width="25" height="25" className='text-white'/>
                                                    </span>
                                                    <select 
                                                        className="form-select js-choice" 
                                                        id="mode_transaction"
                                                        size="1"
                                                        name="mode_transaction" 
                                                        data-options='{"removeItemButton":true}' 
                                                        {...register("methode_transaction", { required: true })}
                                                        >
                                                        <option value="">Sélectionner un mode...</option>
                                                        <option value="Espèce" >Espèce</option>
                                                        <option value="Chèque" >Chèque</option>
                                                        <option value="Virement" >Virement</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <label className="form-label" htmlFor="date">
                                                    Date de la transaction:
                                                </label>
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text bg-primary" id="basic-addon1">
                                                        <Icon icon="clarity:date-solid" width="25" height="25" className='text-white'/>
                                                    </span>
                                                    <input
                                                    id="date"
                                                    className="form-control sb-input"
                                                    type="datetime-local"
                                                    aria-label="date"
                                                    placeholder="date"
                                                    aria-describedby="basic-addon1"
                                                    {...register("date", { required: true })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="number_transaction" className="form-label">N° de la transaction</label>
                                                <div className="input-group ">
                                                    <span className="input-group-text bg-primary" id="basic-addon1">
                                                        <Icon icon="tabler:number" width="25" height="25" className='text-white'/>
                                                    </span>
                                                    <input
                                                        id="number_transaction"
                                                        className="form-control sb-input"
                                                        type="text"
                                                        aria-label="number_transaction"
                                                        placeholder="N° de la transaction"
                                                        aria-describedby="basic-addon1"
                                                        {...register("number_transaction", { required: false })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* </div> */}
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="flex-1">
                                        <button className="btn btn-outline-danger rounded-pill me-1 mb-1" type="button"
                                            onClick={handleClose}>Annuler</button>
                                        <button className="btn btn-outline-primary rounded-pill me-1 mb-1 btn-edit"
                                            type="submit" id="edit_charge_send">Valider</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default InvoicePayment;