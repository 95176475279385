import ApiHelper from "../../../libs/Api";

export const baseurlEntreprise = "/customers/companies";

const urlListAll = `${baseurlEntreprise}/all`;

const urlCreat = `${baseurlEntreprise}/create`;

export const entrepriseList = async (entrepriseListUrl = urlListAll) => {
  try {
    return await ApiHelper.request(entrepriseListUrl);
  } catch (error) {
    return error.response;
  }
};

export const createEntreprise = async (body) => {
  try {
    return await ApiHelper.request(urlCreat, "post", body);
  } catch (error) {
    return error.response;
  }
};

export const editEntreprise = async (entrepriseEditUrl, body) => {
  try {
    return await ApiHelper.request(entrepriseEditUrl, "put", body);
  } catch (error) {
    return error.response;
  }
};
