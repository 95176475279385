export const renderStatusButton = (commande, handleStatusClick) => {
  const statusMap = {
    waiting: "En attente",
    valided: "Valider",
    canceled: "Annuler",
  };

  const isDisabled = commande?.status !== "waiting";
  const buttonText = statusMap[commande?.status] || "N/A";

  return (
    <button
      className={`btn btn-outline-${getStatusButtonColor(commande)} btn-sm text-truncate ${isDisabled ? "disabled" : ""}`}
      type="button"
      onClick={() => handleStatusClick(commande)}
    >
      {buttonText}
      <i className={`${getStatusButtonIcon(commande)}`}></i>
    </button>
  );
};

const getStatusButtonColor = (commande) => {
  const statusColorMap = {
    waiting: "secondary",
    valided: "success",
    canceled: "danger",
  };
  return statusColorMap[commande?.status] || "secondary";
};

const getStatusButtonIcon = (commande) => {
  const statusIconMap = {
    waiting: "fas fa-clock mx-2",
    valided: "fas fa-check mx-2",
    canceled: "fas fa-times mx-2",
  };
  return statusIconMap[commande?.status] || "";
};
