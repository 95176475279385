import React from 'react';
import { Outlet } from 'react-router-dom';
import SideBar from './partials/SideBar';
import Header from './partials/Header';

function ProfileLayout(props) {
    return (
        <main className="main" id="top">
            <div className="container-fluid"  data-layout="container">
                <script src="%PUBLIC_URL%/assets/js/inline_2.js"></script>
                <SideBar/>
                <div className="content">
                    <Header />
                    <script src="%PUBLIC_URL%/assets/js/inline_3.js"></script>

                    <div className="row container-fluid">
                    <Outlet />
                    </div>
                </div>
            </div>
      </main>

    );
}

export default ProfileLayout;