import ApiHelper from "../../../libs/Api";

export const caisseList = async (
  caisseListUrl = "/finances/transactions/all"
) => {
  try {
    return await ApiHelper.request(caisseListUrl);
  } catch (error) {
    return error.response;
  }
};

export const caisseReport = async (
  caisseReportUrl = "/finances/transactions/report"
) => {
  try {
    return await ApiHelper.request(caisseReportUrl);
  } catch (error) {
    return error.response;
  }
};

const caisseCreationUrl = "/finances/transactions/create";

export const createCaisse = async (body) => {
  try {
    return await ApiHelper.request(caisseCreationUrl, "post", body);
  } catch (error) {
    return error.response;
  }
};

export const editCaisse = async (caisseEditUrl, body) => {
  try {
    return await ApiHelper.request(caisseEditUrl, "put", body);
  } catch (error) {
    return error.response;
  }
};
