import ApiHelper from "../../../../libs/Api";

export const stockList = async (stockListUrl = "/stocks/all") => {
  try {
    return await ApiHelper.request(stockListUrl);
  } catch (error) {
    return error.response;
  }
};

const stockCreationUrl = "/stocks/create";

export const createStock = async (body) => {
  try {
    return await ApiHelper.request(stockCreationUrl, "post", body);
  } catch (error) {
    return error.response;
  }
};
