import React, { useEffect, useState } from "react";
import { BGHOLDER as bg, checkStatusCodes } from "../../constants";
import { Icon } from "@iconify/react";
import Modal from "react-bootstrap/Modal";
import { editCaisse } from "./services/caisse.service";
import {
  dateFormatForSubmit,
  formatDateForInput,
} from "../../helpers/dates.helper";

function CaisseEditModal({
  selectedTransaction,
  handleClose,
  showEdit,
  handleToast,
  showLoader,
  updateCaisseInList,
  fetchCaisseReport,
}) {
  const [code, setCode] = useState("");
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState("");
  const [Type, setType] = useState("");
  const [mode, setMode] = useState("");
  const [numbTransaction, setNumbTransaction] = useState("");
  const [description, setDescription] = useState("");

  const setOfMode = [
    {
      name: "Espèce",
      value: "Espèce",
    },
    {
      name: "Chèque",
      value: "Chèque",
    },
    {
      name: "Virement",
      value: "Virement",
    },
  ];

  const setOfType = [
    {
      name: "Entrée (cash_in)",
      value: "cash_in",
    },
    {
      name: "Sortie (cash_out)",
      value: "cash_out",
    },
  ];

  const caisseEdit = async (e) => {
    e.preventDefault();
    showLoader(true);
    const formData = {
      code,
      sum_transaction: parseInt(amount),
      date: dateFormatForSubmit(date),
      type_transaction: Type,
      mode_transaction: mode,
      number_transaction:
        numbTransaction || selectedTransaction?.number_transaction,
      description: description || "",
    };

    const editUrl = `/finances/transactions/${code}/update`;
    const response = await editCaisse(editUrl, formData);
    if (checkStatusCodes(response.data.statusCode)) {
      showLoader(false);
      handleToast(true, "success", response.data.message);
      fetchCaisseReport();
      updateCaisseInList(response.data.data);
    } else {
      showLoader(false);
      handleToast(true, "error", response.data.message);
    }
  };
  useEffect(() => {
    if (selectedTransaction) {
      setCode(selectedTransaction.code);
      setAmount(selectedTransaction.sum_transaction);
      setDate(selectedTransaction.date);
      setMode(selectedTransaction.mode_transaction);
      setNumbTransaction(selectedTransaction.numb_transaction);
      setDescription(selectedTransaction.description);
      setType(selectedTransaction.type_transaction);
    }
  }, [selectedTransaction]);
  return (
    <Modal
      show={showEdit}
      onHide={handleClose}
      animation={true}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
      <Modal.Header>
        <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
          <button
            class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
            onClick={handleClose}
          ></button>
        </div>
        <div className="bg-light rounded-top-lg py-3 ps-4 pe-6">
          <h4 className="mb-1" id="staticBackdropLabel">
            Modifier la transaction:{" "}
            <span className="text-primary">{selectedTransaction.code}</span>
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="p-4">
          <div className="row">
            <form onSubmit={(e) => caisseEdit(e)}>
              <div className="col-lg-12">
                <div className="d-flex">
                  <div className="flex-1">
                    <div className="row">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="sum_transaction">
                          Somme de la transaction:
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="tdesign:undertake-transaction"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="sum_transaction"
                            className="form-control sb-input"
                            type="number"
                            aria-label="sum_transaction"
                            placeholder="Somme de la transaction"
                            aria-describedby="basic-addon1"
                            defaultValue={selectedTransaction.sum_transaction}
                            onChange={(e) => setAmount(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="type_transaction"
                          className="form-label"
                        >
                          Type de transaction
                        </label>
                        <div className="input-group ">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="grommet-icons:transaction"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <select
                            className="form-select js-choice"
                            id="type_transaction"
                            size="1"
                            name="type_transaction"
                            data-options='{"removeItemButton":true}'
                            value={Type}
                            onChange={(e) => setType(e.target.value)}
                          >
                            <option value="">Sélectionner un type...</option>
                            {setOfType.map((type) => (
                              <option key={type.name} value={type.value}>
                                {type.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label
                          htmlFor="mode_transaction"
                          className="form-label"
                        >
                          Mode de transaction
                        </label>
                        <div className="input-group ">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="wpf:bank-cards"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <select
                            className="form-select js-choice"
                            id="mode_transaction"
                            size="1"
                            name="mode_transaction"
                            data-options='{"removeItemButton":true}'
                            value={mode}
                            onChange={(e) => setMode(e.target.value)}
                          >
                            <option value="">Sélectionner un mode...</option>
                            {setOfMode.map((mode) => (
                              <option key={mode.name} value={mode.value}>
                                {mode.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="date">
                          Date de la transaction:
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="clarity:date-solid"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="date"
                            className="form-control sb-input"
                            type="datetime-local"
                            aria-label="date"
                            placeholder="date"
                            aria-describedby="basic-addon1"
                            defaultValue={formatDateForInput(
                              selectedTransaction.date
                            )}
                            onChange={(e) => setDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label
                          htmlFor="number_transaction"
                          className="form-label"
                        >
                          N° de la transaction
                        </label>
                        <div className="input-group ">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="tabler:number"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="number_transaction"
                            className="form-control sb-input"
                            type="text"
                            aria-label="number_transaction"
                            placeholder="N° de la transaction"
                            aria-describedby="basic-addon1"
                            defaultValue={
                              selectedTransaction.number_transaction
                            }
                            onChange={(e) => setNumbTransaction(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="mb-3">
                          <label className="col-form-label" htmlFor="role">
                            Description:
                          </label>
                          <textarea
                            name=""
                            id="commentaire"
                            cols="15"
                            rows="3"
                            class="form-control sb-input"
                            placeholder="Commentez ..."
                            defaultValue={selectedTransaction.commentaire}
                            onChange={(e) => setDescription(e.target.value)}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>

                <div className="d-flex">
                  <div className="flex-1">
                    <button
                      className="btn btn-outline-danger rounded-pill me-1 mb-1"
                      type="button"
                      onClick={handleClose}
                    >
                      Annuler
                    </button>
                    <button
                      className="btn btn-outline-primary rounded-pill me-1 mb-1 btn-edit"
                      type="submit"
                      id="edit_charge_send"
                      onClick={handleClose}
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CaisseEditModal;
