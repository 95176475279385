import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import DynamicFieldsForOrder from '../../../components/formComponent/DynamicFieldsForOrder';

import { BGHOLDER as bg, checkStatusCodes } from '../../../constants';
import { useForm } from 'react-hook-form';
import { Icon } from '@iconify/react';
import { createCommande } from '../services/commande.service';


function CommandeAddModal({ commercials, entreprises, produits, showAdd, handleClose, handleToast }) {
    const [choice, setChoice] = useState(true);
    const {
        register,
        handleSubmit,
    } = useForm({
        shouldUnregister: true,
    })

    const chooseCommercial = () => setChoice(true);
    const chooseEntreprise = () => setChoice(false);

    const commandeCreation = async (data) => {
        const newData = Object.keys(data).reduce((result, key) => {
            // Vérifiez si la clé commence par "label" ou "value"
            if (key.startsWith("product")) {
                const index = key.replace("product", "");
                if (!result.products) {
                    result.products = [];
                }
                if (!result.products[index - 1]) {
                    result.products[index - 1] = {};
                }
                result.products[index - 1].code_product = data[key];
            } else if (key.startsWith("quantity")) {
                const index = key.replace("quantity", "");
                if (!result.products) {
                    result.products = [];
                }
                if (!result.products[index - 1]) {
                    result.products[index - 1] = {};
                }
                result.products[index - 1].quantity = parseInt(data[key]);
            } else if (key.startsWith("price")) {
                const index = key.replace("price", "");
                if (!result.products) {
                    result.products = [];
                }
                if (!result.products[index - 1]) {
                    result.products[index - 1] = {};
                }
                result.products[index - 1].price = parseInt(data[key]);
            } else {
                result[key] = data[key];
            }
            return result;
        }, {});

        const response = await createCommande(newData)
        let state = false
        let message = ""
        let type = ""
        if (checkStatusCodes(response.data.statusCode)) {
            state = true
            message = response.data.message
            type = "success"
            handleClose()
            handleToast(state, type, message)
            setTimeout(() => {
                window.location.reload(); // Recharge la page
            }, 2000);
        } else {
            state = true
            message = response.data.message
            type = "error"
            handleToast(state, type, message)
            handleClose()
        }
    };

    return (
        <Modal show={showAdd} onHide={handleClose} animation={true} size="lg" aria-labelledby="example-modal-sizes-title-lg">
            <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
            <Modal.Header>
                <div className="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
                    <button className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                        onClick={handleClose}></button>
                </div>
                <div className="bg-light rounded-top-lg py-3 ps-4 pe-6">
                    <h4 className="mb-1" id="staticBackdropLabel">
                        Enregistrer une commande
                    </h4>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="p-4">
                    <div className="row">
                        <form onSubmit={handleSubmit((data) => commandeCreation(data))}>
                            <div className="col-lg-12">
                                <div className="d-flex">
                                    <div className="flex-1">
                                        <div className="row mb-3">
                                            <div className="col-md-6 mx-auto">
                                                <div className="row">
                                                    {inputBtnSlectedCustomer("Commercial", "customer", choice, chooseCommercial)}
                                                    {inputBtnSlectedCustomer("Entreprise", "compagny", !choice, chooseEntreprise)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {choice ? (
                                                listCustomer("un commercial", commercials, register,)
                                            ) : (
                                                listCustomer("une entreprise", entreprises, register,)
                                            )}
                                        </div>
                                        <fieldset className="border p-2 mb-3">
                                            <legend className="float-none w-auto p-2">Produit(s) à commander</legend>
                                            <DynamicFieldsForOrder register={register} produits={produits} />
                                        </fieldset>
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="flex-1">
                                        <button className="btn btn-outline-danger rounded-pill me-1 mb-1" type="button"
                                            onClick={handleClose}>Annuler</button>
                                        <button className="btn btn-outline-primary rounded-pill me-1 mb-1 btn-edit"
                                            type="submit" id="edit_charge_send">Valider</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

function inputBtnSlectedCustomer(title, id, checked, handle) {
    return <div className="col-md-6 d-flex justify-content-center">
        <input className="checkbox-tools " type="radio"
            name={`${id}`} id={`${id}`} checked={checked} value="1" onClick={handle} />
        <label className="for-checkbox-tools" for={`${id}`}>
            <i className='fas fa-2x fa-user uil mx-auto'></i>
            <span>{title}</span>
        </label>
    </div>;
}

function listCustomer(title, list, register) {
    return <div className="col-md-6 mx-auto">
        <label className="form-label" htmlFor="first_step">Choisir {title}</label>
        <div className="input-group mb-3">
            <span className="input-group-text bg-primary" id="basic-addon1">
                <Icon icon="mdi:company" className='text-white' width="25" height="25" />
            </span>
            <select
                className="form-select js-choice"
                id="first_step"
                size="1"
                name="first_step"
                data-options='{"removeItemButton":true}'
                {...register("owner", { required: true })}
            >
                <option value="">Sélectionner {title}...</option>
                {list && list.length > 0 ? (
                    list.map(entreprise => (
                        <option key={entreprise.code} value={entreprise.code} >{entreprise?.name}</option>
                    ))
                ) : null}
            </select>
        </div>
    </div>;
}
export default CommandeAddModal;
