import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';

import Loader from '../../components/partials/Loader';
import ToastMessage from '../../components/messages/ToastMessage';
import { BGHOLDER as bg, checkStatusCodes} from '../../constants';
import Pagination from '../../components/partials/Pagination';
import { vehiculeList } from '../../components/finance/services/vehicule.service';
import VehiculeAddModal from '../../components/finance/VehiculeAddModal';

function Vehicule(props) {
    const [vehicules, setVehicules] = useState([])
    const [paginator, setPaginator] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [loaderState, setLoaderState] = useState(false);
    const [selectedEntreprise, setSelectedEntreprise] = useState(null);
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [toastState, setToastState] = useState({
        show: false,
        type:'',
        message:''
    });

    const handleToast=(show, type, message) => {
        const updatedToast = { ...toastState }
        updatedToast.show = show
        updatedToast.type = type;
        updatedToast.message = message

        setToastState(updatedToast)
    }
    const handleClose = () => {
        setShowAdd(false);
        setShowEdit(false);
    } 
    const handleShowAdd = () => setShowAdd(true);
    const handleShowEdit = () => setShowEdit(true);
    
    const fetchVehiculeList = async () =>{
        const response = await vehiculeList()
        if(checkStatusCodes(response.data.statusCode)){
            setVehicules(response.data.data.list)
            setPaginator(response.data.data.pagination)
            setLoaderState(false)
        }else{
            let state = true
            let message = "Vous n'avez pas les authorisations réquises"
            let type = "error"
            handleToast(state, type, message)
        }        
    }

    // const type = usersType
    const handleEditClick = (user) => {
        // Mettez à jour l'état avec les données de l'utilisateur sélectionné
        // setUsersTypes(type)
        setSelectedEntreprise(user);
        // Affichez le modal pour l'édition ici
        handleShowEdit()

    };

    const PanaginationList = async (page) => {
        setLoaderState(true)
        setCurrentPage(page)
        try {
            const response = await vehiculeList(`/car/all?page=${page}&limit=10`)
            setVehicules(response.data.data.list)
            setPaginator(response.data.data.pagination)
            setLoaderState(false)
        } catch (error) {
            
        }
    }

    const renderTableData = () => {
        return (
          <tbody className="list_admin">
            {vehicules && vehicules.length > 0 ? (
                    vehicules.map(vehicule => (
                        <tr key={vehicule.code}>
                            <td>{vehicule?.name_mark}</td>
                            <td>{vehicule?.name_model}</td>
                            <td>{vehicule?.registration}</td>
                            <td>{vehicule?.supplier}</td>
                            <td>
                                <div className="d-flex align-items-center justify-content-center">
                                    
                                    <button className="btn ms-2 btn-warning px-2 py-1"
                                        type="button" title="Modifier" onClick={() => handleEditClick(vehicule)}>
                                        <i className="text-500 text-white fas fa-edit"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))
                    ) : (
                        <tr>
                            <td colSpan="5" className='text-center'>Aucun véhicule disponible.</td>
                        </tr>
                    )}
          </tbody>
        );
    };

    useEffect(() => {
        setLoaderState(true)
        fetchVehiculeList();
        // fetchUserType()
        
    },  []);
    return (
        <div>
            {loaderState ? (
                <Loader loaderState={loaderState} />
            ):null}

            {toastState.show ? (
                <ToastMessage toastState = {toastState}/>
            ):null}
            <div className="row g-3 mb-3 p-0">
                <div className="row mb-3 p-0 ps-3">
                    <div className="card mb-3">
                        <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
                        <div className="card-body position-relative">
                            <div className="row">
                                <div className="col-lg-8">
                                    <h3><Icon icon="ant-design:car-filled" width="50" height="50" /> Véhicules</h3>
                                    <p className="mb-0">Gestion des Véhicules</p>
                                </div>
                                <div className="col-lg-4">
                                    <div className="d-flex align-items-center justify-content-end my-3" id="bulk-select-replace-element">
                                        <button className="btn btn-falcon-success btn-sm" type="button" onClick={handleShowAdd}>
                                            <span className="fas fa-plus" data-fa-transform="shrink-3 down-2">
                                            </span>
                                            <span className="ms-1">Nouvelle véhicule</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row m-0 p-0 mb-3">
                    <div className='col-md-12 p-0'>
                        <div className="card mb-3">
                            <div className="card-header bg-light">
                                <div className="row align-items-center">
                                    <div className="col">
                                    <h5 className="mb-0" id="followers">Les Véhicules</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body bg-light px-1 py-0">
                                <div className="row g-0 fs--1">
                                    
                                    <div id="tableExample2" data-list='{"valueNames":["Nom","email","telephone","role"],"page":5,"pagination":true}'>
                            
                                        <div className="table-responsive scrollbar">
                                            <table className="table table-bordered table-striped fs--1 mb-0" id="table">
                                                <thead className="bg-200 text-900">
                                                    <tr>
                                                        <th className="sort" data-sort="email">Marque</th>
                                                        <th className="sort" data-sort="mode">Model</th>
                                                        <th className="sort" data-sort="numero">Inscription</th>
                                                        <th className="sort" data-sort="numero">Founisseur</th>
                                                        {/* <th className="sort" data-sort="date">Date de la transaction</th> */}
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                {renderTableData()}
                                            </table>
                                        </div>
                                        <div className="d-flex justify-content-center mt-3">
                                            <Pagination paginator={paginator} currentPage={currentPage} limit={10} pagination={PanaginationList} />
                                        </div>
                                    </div>
                                    <VehiculeAddModal showAdd = {showAdd} handleClose={handleClose} handleToast = {handleToast}/>
                                     {/* <EntrepriseEditModal  selectedEntreprise = {selectedEntreprise} showEdit = {showEdit} handleClose={handleClose} handleToast = {handleToast}/> */}
                                   {/* <UserStatusUpdateModal /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
                
            </div>
        </div>
    );
}

export default Vehicule;