import React from 'react';

function Pagination({ paginator, limit, currentPage, pagination }) {
  if (!paginator || paginator.total < 1) {
    return null;
  }


  const elementsParPage = limit;
  const maxVisiblePages = 5;
  const totalPages = Math.ceil(paginator.total / elementsParPage);
  let startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
  const endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

  if (endPage - startPage + 1 < maxVisiblePages) {
    startPage = Math.max(endPage - maxVisiblePages + 1, 1);
  }

  const pages = [];
  const paginating = async (e,index) => {
    e.preventDefault();
    await pagination(index)
  }

  for (let index = startPage; index <= endPage; index++) {
    pages.push(
      <li key={index} className={`page-item ${currentPage === index ? "active" : ""}`}>
        <a
          className="page-link paginator btn btn-outline-primary rounded-pill me-1 mb-1"
          data-page={index}
          href=""
          onClick={(e)=>{paginating(e, index)}}
        >
          {index}
        </a>
      </li>
    );
  }
  
  return (
    <ul className="pagination">
      {currentPage > 1 && (
        <li className="page-item">
          <a className="page-link paginator btn btn-outline-primary rounded-pill me-1 mb-1" data-page={1} href="" aria-label="Précédent" onClick={(e)=>{paginating(e, 1)}}>
            <span aria-hidden="true">&laquo; &laquo;</span>
            <span className="sr-only">Précédent</span>
          </a>
        </li>
      )}
      {currentPage > 1 && (
        <li className="page-item">
          <a className="page-link paginator btn btn-outline-primary rounded-pill me-1 mb-1" data-page={currentPage - 1} href="" aria-label="Précédent" onClick={(e)=>{paginating(e, currentPage - 1)}}>
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Précédent</span>
          </a>
        </li>
      )}
      {pages}
      {currentPage < totalPages && (
        <li className="page-item">
          <a className="page-link paginator btn btn-outline-primary rounded-pill me-1 mb-1" data-page={currentPage + 1} href="" aria-label="Suivant" onClick={(e)=>{paginating(e, currentPage + 1)}}>
            <span aria-hidden="true">&raquo;</span>
            <span className="sr-only">Suivant</span>
          </a>
        </li>
      )}
      {currentPage < totalPages && (
        <li className="page-item">
          <a className="page-link paginator btn btn-outline-primary rounded-pill me-1 mb-1" data-page={totalPages} href="" aria-label="Suivant" onClick={(e)=>{paginating(e, totalPages)}}>
            <span aria-hidden="true">&raquo; &raquo;</span>
            <span className="sr-only">Suivant</span>
          </a>
        </li>
      )}
    </ul>
  );
}

export default Pagination;