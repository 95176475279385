import React, { useCallback, useEffect, useMemo, useState } from "react";
import Loader from "../../components/partials/Loader";
import { Icon } from "@iconify/react";

import ToastMessage from "../../components/messages/ToastMessage";
import { pointageList } from "../../components/personnels/services/pointage.service";
import { personnelList } from "../../components/personnels/services/personnel.service";
import { BGHOLDER as bg, checkStatusCodes } from "../../constants";
import { dateFormatToFr } from "../../helpers/dates.helper";
import PointageAddModal from "../../components/personnels/PointageAddModal";
import PointageEditModal from "../../components/personnels/PointageEditModal";
import Pagination from "../../components/partials/Pagination";
import { useTrail, animated as a } from "react-spring";
function Pointage(props) {
  const [pointages, setPointages] = useState([]);
  const [personnels, setPersonnels] = useState([]);
  const [paginator, setPaginator] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loaderState, setLoaderState] = useState(false);
  const [selectedPointage, setSelectedPointage] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [toastState, setToastState] = useState({
    show: false,
    type: "",
    message: "",
  });
  const [toggle, setToggle] = useState(true);
  const trail = useTrail(pointages?.length || 0, {
    opacity: toggle ? 1 : 0,
    x: toggle ? 0 : 20,
    from: { opacity: 0, x: 20 },
  });
  const handleToast = (show, type, message) => {
    const updatedToast = { ...toastState };
    updatedToast.show = show;
    updatedToast.type = type;
    updatedToast.message = message;

    setToastState(updatedToast);
  };
  const handleClose = () => {
    setShowAdd(false);
    setShowEdit(false);
  };
  const handleShowAdd = useCallback(() => setShowAdd(true), []);
  const handleShowEdit = useCallback(() => setShowEdit(true), []);
  const showLoader = useCallback((state) => setLoaderState(state), []);

  const PanaginationList = async (page) => {
    setLoaderState(true);
    setCurrentPage(page);
    try {
      const response = await pointageList(
        `/personnals/clockings/all?page=${page}&limit=10`
      );
      setPointages(response.data.data.list);
      setPaginator(response.data.data.pagination);

      setLoaderState(false);
    } catch (error) {}
  };

  const fetchPointageList = async () => {
    try {
      const response = await pointageList();
      const { statusCode, data } = response.data;
      if (checkStatusCodes(statusCode)) {
        setPointages(data.list);
        setPaginator(data.pagination);
      } else {
        handleToast(
          true,
          "error",
          "Vous n'avez pas les autorisations requises"
        );
      }
      setLoaderState(false);
    } catch (error) {
      handleToast(true, "error", "Une erreur est survenue");
    }
  };

  const fetchPersonnelList = async () => {
    try {
      const response = await personnelList();
      setPersonnels(response.data.data.list);
      setLoaderState(false);
    } catch (error) {}
  };
  // const type = usersType
  const handleEditClick = useCallback((Pointage) => {
    setSelectedPointage(Pointage);
    handleShowEdit();
  }, []);

  const addPointageToList = useCallback((newPointage) => {
    setPointages((prevPointages) => [newPointage, ...prevPointages]);
  }, []);

  /**
   * Updates a Pointage in the list.
   *
   * @param {Object} updatedPointage - The updated Pointage object.
   * @return {void}
   */
  const updatePointageInList = useCallback((updatedPointage) => {
    setPointages((prevPointages) =>
      prevPointages.map((Pointage) =>
        Pointage.code === updatedPointage.code ? updatedPointage : Pointage
      )
    );
  }, []);

  const renderTableData = useMemo(() => {
    return (
      <tbody className="list_admin">
        {pointages && pointages?.length > 0 ? (
          trail.map(({ x, ...rest }, index) => (
            <a.tr
              key={pointages[index]?.code}
              style={{
                ...rest,
                transform: x.to((x) => `translate3d(0,${x}px,0)`),
              }}
            >
              <td>
                {pointages[index]?.personnel?.first_name}{" "}
                {pointages[index]?.personnel?.last_name}
              </td>
              <td>{dateFormatToFr(pointages[index]?.date_cloking)}</td>
              <td>
                {pointages[index]?.date_descent
                  ? dateFormatToFr(pointages[index]?.date_descent)
                  : "N/A"}
              </td>
              <td>{pointages[index]?.number_hours_worked ?? "N/A"}</td>
              <td>
                {pointages[index]?.status === "here" ? (
                  <span className="badge rounded-pill badge-soft-primary">
                    Présent
                  </span>
                ) : pointages[index]?.status === "delay" ? (
                  <span className="badge rounded-pill badge-soft-warning">
                    En retard
                  </span>
                ) : (
                  <span className="badge rounded-pill badge-soft-danger">
                    Absent
                  </span>
                )}
              </td>
              <td>
                <div className="d-flex">
                  {pointages[index]?.status === "here" &&
                  !pointages[index]?.date_descent ? (
                    <button
                      className="btn btn-falcon-warning btn-sm"
                      type="button"
                      title="Modifier"
                      onClick={() => handleEditClick(pointages[index])}
                    >
                      Marquer descente
                    </button>
                  ) : pointages[index]?.status === "delay" &&
                    !pointages[index]?.date_descent ? (
                    <button
                      className="btn btn-falcon-warning btn-sm"
                      type="button"
                      title="Modifier"
                      onClick={() => handleEditClick(pointages[index])}
                    >
                      Marquer descente
                    </button>
                  ) : (
                    <button
                      className="btn btn-falcon-warning btn-sm"
                      type="button"
                      disabled
                      title="Modifier"
                      onClick={() => handleEditClick(pointages[index])}
                    >
                      Marquer descente
                    </button>
                  )}
                </div>
              </td>
            </a.tr>
          ))
        ) : (
          <tr>
            <td colSpan="6" className="text-center">
              Aucun Pointage disponible.
            </td>
          </tr>
        )}
      </tbody>
    );
  }, [trail]);

  useEffect(() => {
    setLoaderState(true);
    fetchPointageList();
    fetchPersonnelList();
  }, []);

  return (
    <div>
      {loaderState ? <Loader loaderState={loaderState} /> : null}

      {toastState.show ? <ToastMessage toastState={toastState} /> : null}
      <div className="row g-3 mb-3 p-0">
        <div className="row mb-3 p-0 ps-3">
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={bg}
            ></div>
            <div className="card-body position-relative">
              <div className="row">
                <div className="col-lg-8">
                  <h3>
                    <Icon icon="ion:finger-print" width="50" height="50" />{" "}
                    pointages
                  </h3>
                  <p className="mb-0">Gestion des pointages</p>
                </div>
                <div className="col-lg-4">
                  <div
                    className="d-flex align-items-center justify-content-end my-3"
                    id="bulk-select-replace-element"
                  >
                    <button
                      className="btn btn-falcon-success btn-sm"
                      type="button"
                      onClick={handleShowAdd}
                    >
                      <span
                        className="fas fa-plus"
                        data-fa-transform="shrink-3 down-2"
                      ></span>
                      <span className="ms-1">Enrégistrer un pointage</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 p-0 ps-3">
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={bg}
            ></div>
            <div className="card-body position-relative">
              <div className="row">
                <div className="col-md-4">
                  <div className="col-12 input-group">
                    <input
                      type="date"
                      className="form-control input-sm m-0"
                      placeholder="Rechercher"
                      id="searchInput"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="col-12 input-group">
                    <select
                      className="form-select js-choice mb-3"
                      id="personnel_role"
                      size="1"
                      name="personnel_role"
                      data-options='{"removeItemButton":true}'
                    >
                      <option value="">Sélectionner une personne...</option>
                      <option value="">Magatte Diallo</option>
                      <option value="">Youssouf Diallo</option>
                      <option value="">Sofi Diop</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="col-12 input-group">
                    <select
                      className="form-select js-choice mb-3"
                      id="personnel_role"
                      size="1"
                      name="personnel_role"
                      data-options='{"removeItemButton":true}'
                    >
                      <option value="">Sélectionner un statut...</option>
                      <option value="">Présent</option>
                      <option value="">Absent</option>
                      <option value="">Retard</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 p-0 mb-3">
          <div className="col-md-12 p-0">
            <div className="card mb-3">
              <div className="card-header bg-light">
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="mb-0" id="followers">
                      Les Pointages
                    </h5>
                  </div>
                </div>
              </div>
              <div className="card-body bg-light px-1 py-0">
                <div className="row g-0 fs--1">
                  <div
                    id="tableExample2"
                    data-list='{"valueNames":["Nom","email","telephone","role"],"page":5,"pagination":true}'
                  >
                    <div className="table-responsive scrollbar">
                      <table
                        className="table table-bordered table-striped fs--1 mb-0"
                        id="table"
                      >
                        <thead className="bg-200 text-900">
                          <tr>
                            <th className="sort" data-sort="email">
                              Employé
                            </th>
                            <th className="sort" data-sort="name">
                              Date de pointage
                            </th>
                            <th className="sort" data-sort="name">
                              Date de descente
                            </th>
                            <th className="sort" data-sort="name">
                              Heure(s) travaillée(s)
                            </th>
                            <th className="sort" data-sort="email">
                              Statut
                            </th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        {renderTableData}
                      </table>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <Pagination
                        paginator={paginator}
                        currentPage={currentPage}
                        limit={10}
                        pagination={PanaginationList}
                      />
                    </div>
                  </div>
                  <PointageAddModal
                    personnels={personnels}
                    showAdd={showAdd}
                    handleClose={handleClose}
                    handleToast={handleToast}
                    showLoader={showLoader}
                    addPointageToList={addPointageToList}
                  />
                  <PointageEditModal
                    selectedPointage={selectedPointage}
                    showEdit={showEdit}
                    handleClose={handleClose}
                    handleToast={handleToast}
                    showLoader={showLoader}
                    updatePointageInList={updatePointageInList}
                  />
                  {/* <UserStatusUpdateModal /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pointage;
