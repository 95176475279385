import React from "react";
import { BGHOLDER as bg, checkStatusCodes } from "../../constants";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { createUser } from "./services/user.service";

function UserAddModal({
  usersType,
  showAdd,
  handleClose,
  handleToast,
  addUserToList,
  showLoader,
}) {
  const { register, handleSubmit } = useForm();

  const userCreation = async (data) => {
    showLoader(true);
    const response = await createUser(data);
    let state = false;
    let message = "";
    let type = "";
    if (checkStatusCodes(response.data.statusCode)) {
      showLoader(false);
      state = true;
      message = response.data.message;
      type = "success";
      handleToast(state, type, message);
      addUserToList(response.data.data); // Ajouter le nouvel utilisateur à la liste
    } else {
      showLoader(false);
      state = true;
      message = response.data.message;
      type = "error";
      handleToast(state, type, message);
    }
  };
  return (
    <Modal
      show={showAdd}
      onHide={handleClose}
      animation={true}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
      <Modal.Header>
        <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
          <button
            class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
            onClick={handleClose}
          ></button>
        </div>
        <div className="bg-light rounded-top-lg py-3 ps-4 pe-6">
          <h4 className="mb-1" id="staticBackdropLabel">
            Ajouter un utilisateur
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="p-4">
          <div className="row">
            <form onSubmit={handleSubmit((data) => userCreation(data))}>
              <div className="col-lg-12">
                <div className="d-flex">
                  <div className="flex-1">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="col-form-label" htmlFor="name">
                            Prenom et Nom:
                          </label>
                          <input
                            className="form-control sb-input"
                            id="name"
                            type="text"
                            name="name"
                            placeholder="mettez le nom de l'admin"
                            {...register("name", { required: true })}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="col-form-label" htmlFor="email">
                            Email:
                          </label>
                          <input
                            type="text"
                            placeholder="metter l'email de l'admin"
                            name="email_admin"
                            className="form-control sb-input"
                            id="email"
                            {...register("email", { required: true })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6 mt-3 mb-3">
                        <div className="mb-3">
                          <label className="col-form-label" htmlFor="password">
                            Mot de passe:
                          </label>
                          <input
                            type="password"
                            placeholder="metter un mot de passe par défaut"
                            name="password"
                            className="form-control sb-input"
                            id="password"
                            {...register("password", { required: true })}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mt-3 mb-3">
                        <div className="mb-3">
                          <label className="col-form-label" htmlFor="role">
                            role:
                          </label>
                          <select
                            className="form-select js-choice"
                            data-options='{"removeItemButton":true,"placeholder":true}'
                            id="role"
                            {...register("code_user_type", { required: true })}
                          >
                            <option value="">Selectionner un role...</option>
                            {usersType.map((type) => (
                              <option key={type.code} value={type.code}>
                                {type.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="flex-1">
                    <button
                      className="btn btn-outline-danger rounded-pill me-1 mb-1"
                      type="button"
                      onClick={handleClose}
                    >
                      Annuler
                    </button>
                    <button
                      className="btn btn-outline-primary rounded-pill me-1 mb-1 btn-edit"
                      type="submit"
                      id="edit_charge_send"
                      onClick={handleClose}
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default UserAddModal;
