import React, { useEffect, useState } from "react";
import { BGHOLDER as bg, checkStatusCodes } from "../../constants";
import { useForm } from "react-hook-form";
import "react-phone-number-input/style.css";
import Modal from "react-bootstrap/Modal";
import { editUser } from "./services/user.service";
function UserUpdateModal({
  selectedUser,
  usersType,
  show,
  handleClose,
  handleToast,
  updateUserInList,
  showLoader,
}) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [selectedRoleType, setSelectedRoleType] = useState(null);
  const { register } = useForm();

  const userEdit = async (e) => {
    showLoader(true);
    e.preventDefault();
    const editData = {
      name: name,
      email: email,
      code_user_type: selectedRoleType.code,
    };
    const userEditUrl = `/user/${code ? code : ""}/update`;
    const response = await editUser(userEditUrl, editData);
    let state = false;
    let message = "";
    let type = "";
    if (checkStatusCodes(response.data.statusCode)) {
      showLoader(false);
      state = true;
      message = response.data.message;
      type = "success";
      handleToast(state, type, message);
      updateUserInList({
        ...selectedUser,
        name,
        email,
        role: selectedRoleType,
      }); // mettre à jour l'utilisateur dans la liste
    } else {
      showLoader(false);
      state = true;
      message = response.data.message;
      type = "error";
      handleToast(state, type, message);
    }
  };

  const changeInputState = (selectedUser) => {
    setName(selectedUser.name);
    setEmail(selectedUser.email);
    setCode(selectedUser.code);
  };

  const clearInput = () => {
    setName("");
    setEmail("");
    setCode("");
  };

  useEffect(() => {
    // Mettez à jour le type d'utilisateur en fonction du rôle sélectionné
    if (selectedUser && selectedUser.role) {
      console.log(selectedUser);
      changeInputState(selectedUser);
      const userRoleCode = selectedUser.role.code;
      const correspondingType = usersType.find(
        (type) => type.code === userRoleCode
      );
      setSelectedRoleType(correspondingType);
    }
  }, [selectedUser, usersType]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={true}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>

      <Modal.Header>
        <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
          <button
            class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
            onClick={() => {
              handleClose();
              clearInput();
            }}
          ></button>
        </div>
        <div className="bg-light rounded-top-lg py-3 ps-4 pe-6">
          <h4 className="mb-1" id="staticBackdropLabel">
            Modifier l'utilisateur :{" "}
            <span className="sb-text code_charge"></span>
          </h4>
          <p className="fs--2 mb-0">
            make by{" "}
            <a className="link-600 fw-semi-bold" href="#!">
              Antony
            </a>
          </p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="p-4">
          <div className="row">
            <form onSubmit={(e) => userEdit(e)}>
              <div className="col-lg-12">
                <div className="d-flex">
                  <div className="flex-1">
                    <div className="row">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="edit_fullname">
                          Prénom et nom:
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <i className="fas fa-user text-white"></i>
                          </span>
                          <input
                            id="edit_fullname"
                            className="form-control sb-input"
                            type="text"
                            aria-label="edit_fullname"
                            placeholder="Prénom et nom"
                            defaultValue={name}
                            onChange={(e) => setName(e.target.value)}
                            // {...register("edit_name", { required: false })}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <label className="form-label" htmlFor="edit_email">
                          Email
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <i className="fas fa-envelope text-white"></i>
                          </span>
                          <input
                            id="edit_email"
                            className="form-control sb-input"
                            type="email"
                            defaultValue={email}
                            aria-label="edit_email"
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                            // {...register("edit_email", { required: false })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6 mt-3 mb-3">
                        <label for="edit_role">Role</label>
                        <select
                          className="form-select js-choice"
                          id="edit_role"
                          size="1"
                          name="edit_role"
                          data-options='{"removeItemButton":true,"placeholder":true}'
                          {...register("code_user_type", { required: true })}
                          value={selectedRoleType ? selectedRoleType.code : ""}
                          onChange={(e) => {
                            const selectedRoleCode = e.target.value;
                            const selectedType = usersType.find(
                              (type) => type.code === selectedRoleCode
                            );
                            setSelectedRoleType(selectedType);
                          }}
                        >
                          <option value="">Sélectionner un rôle...</option>
                          {usersType.map((type) => (
                            <option key={type.code} value={type.code}>
                              {type.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="flex-1">
                    <button
                      className="btn btn-outline-danger rounded-pill me-1 mb-1"
                      type="button"
                      onClick={() => {
                        handleClose();
                        clearInput();
                      }}
                    >
                      Annuler
                    </button>
                    <button
                      className="btn btn-outline-primary rounded-pill me-1 mb-1 btn-edit"
                      type="submit"
                      id="edit_charge_send"
                      onClick={handleClose}
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default UserUpdateModal;
