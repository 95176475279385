import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import { BGHOLDER as bg, checkStatusCodes } from "../../constants";
import { useTrail, animated as a } from "react-spring";
import UserUpdateModal from "../../components/users/UserUpdateModal";
import UserStatusUpdateModal from "../../components/users/UserStatusUpdateModal";
import {
  userList,
  userTypes,
} from "../../components/users/services/user.service";
import Loader from "../../components/partials/Loader";
import UserAddModal from "../../components/users/UserAddModal";
import ToastMessage from "../../components/messages/ToastMessage";
import Pagination from "../../components/partials/Pagination";

function Users() {
  const [users, setUsers] = useState([]);
  const [usersType, setUsersTypes] = useState([]);
  const [loaderState, setLoaderState] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [paginator, setPaginator] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showAdd, setShowAdd] = useState(false);
  const [show, setShow] = useState(false);
  const [toastState, setToastState] = useState({
    show: false,
    type: "",
    message: "",
  });
  const [toggle, setToggle] = useState(true);

  const trail = useTrail(users?.length || 0, {
    opacity: toggle ? 1 : 0,
    x: toggle ? 0 : 20,
    from: { opacity: 0, x: 20 },
  });

  const handleToast = useCallback((show, type, message) => {
    setToastState((prevState) => ({
      ...prevState,
      show,
      type,
      message,
    }));
  }, []);
  const handleClose = () => {
    setShowAdd(false);
    setShow(false);
  };
  const handleShowAdd = useCallback(() => setShowAdd(true), []);
  const handleShow = useCallback(() => setShow(true), []);
  const showLoader = useCallback((state) => setLoaderState(state), []);

  const fetchUserList = useCallback(async () => {
    const response = await userList();
    const { statusCode, data } = response.data;
    if (checkStatusCodes(statusCode)) {
      setUsers(data.list);
      setPaginator(data.pagination);
    } else {
      handleToast(true, "error", "Vous n'avez pas les authorisations réquises");
    }
    setLoaderState(false);
  }, []);

  const fetchUserType = async () => {
    try {
      const response = await userTypes();
      if (checkStatusCodes(response.data.statusCode)) {
        setUsersTypes(response.data.data.list);
        setLoaderState(false);
      } else {
        handleToast(
          true,
          "error",
          "Vous n'avez pas les authorisations requises"
        );
      }
    } catch (error) {
      handleToast(true, "error", "Une erreur s'est produite");
    }
  };

  // Fonction pour ajouter un nouvel utilisateur à la liste existante
  const addUserToList = (newUser) => {
    setUsers((prevUsers) => [newUser, ...prevUsers]);
  };

  /**
   * Updates a user in the list.
   *
   * @param {Object} updatedUser - The updated user object.
   * @return {void}
   */
  const updateUserInList = (updatedUser) => {
    const updatedUsers = users.map((user) =>
      user.code === updatedUser.code ? updatedUser : user
    );
    setUsers(updatedUsers);
  };

  const handleEditClick = (user) => {
    // Mettez à jour l'état avec les données de l'utilisateur sélectionné
    setSelectedUser(user);
    // Affichez le modal pour l'édition ici
    handleShow();
  };
  const PanaginationList = async (page) => {
    setLoaderState(true);
    setCurrentPage(page);
    try {
      const response = await userList(`/user/all?page=${page}&limit=10`);
      setUsers(response.data.data.list);
      setPaginator(response.data.data.pagination);

      setLoaderState(false);
    } catch (error) {}
  };
  const renderTableData = useMemo(() => {
    if (!users || users.length === 0) {
      return (
        <tr>
          <td colSpan="5" className="text-center">
            Aucun utilisateur disponible.
          </td>
        </tr>
      );
    }

    return trail.map(({ x, ...rest }, index) => (
      <a.tr
        key={users[index]?.code}
        style={{ ...rest, transform: x.to((x) => `translate3d(0,${x}px,0)`) }}
      >
        <td>{users[index]?.name}</td>
        <td>{users[index]?.email}</td>
        <td>{users[index]?.role?.name}</td>
        <td className="text-center">
          <button
            className="btn btn-outline-success btn-sm status-user-admin text-truncate"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#etat"
          >
            Active <i className="fas fa-check"></i>
          </button>{" "}
        </td>
        <td>
          <div className="d-flex align-items-center justify-content-center">
            <button
              className="btn ms-2 btn-warning px-2 py-1"
              type="button"
              title="Modifier"
              onClick={() => handleEditClick(users[index])}
            >
              <i className="text-500 text-white fas fa-edit"></i>
            </button>
          </div>
        </td>
      </a.tr>
    ));
  }, [users, trail]);

  useEffect(() => {
    setLoaderState(true);
    fetchUserList();
    fetchUserType();
  }, []);
  // console.log(setShowModal)
  return (
    <div>
      {loaderState ? <Loader loaderState={loaderState} /> : null}

      {toastState.show ? <ToastMessage toastState={toastState} /> : null}
      <div className="row g-3 mb-3 p-0">
        <div className="row mb-3 p-0 ps-3">
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={bg}
            ></div>
            <div className="card-body position-relative">
              <div className="row">
                <div className="col-lg-8">
                  <h3>
                    <Icon icon="mdi:users" width="50" /> Utilisateurs
                  </h3>
                  <p className="mb-0">Gestion des utilisateurs</p>
                </div>
                <div className="col-lg-4">
                  <div
                    className="d-flex align-items-center justify-content-end my-3"
                    id="bulk-select-replace-element"
                  >
                    <button
                      className="btn btn-falcon-success btn-sm"
                      type="button"
                      onClick={handleShowAdd}
                    >
                      <span
                        className="fas fa-plus"
                        data-fa-transform="shrink-3 down-2"
                      ></span>
                      <span className="ms-1">Nouveau utilisateur</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 p-0 mb-3">
          <div className="col-md-12 p-0">
            <div className="card mb-3">
              <div className="card-header bg-light">
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="mb-0" id="followers">
                      Les utilisateurs
                    </h5>
                  </div>
                </div>
              </div>
              <div className="card-body bg-light px-1 py-0">
                <div className="row g-0 fs--1">
                  <div
                    id="tableExample2"
                    data-list='{"valueNames":["Nom","email","telephone","role"],"page":5,"pagination":true}'
                  >
                    <div className="table-responsive scrollbar">
                      <table
                        className="table table-bordered table-striped fs--1 mb-0"
                        id="table"
                      >
                        <thead className="bg-200 text-900">
                          <tr>
                            <th className="sort" data-sort="name">
                              Nom
                            </th>
                            <th className="sort" data-sort="email">
                              email
                            </th>
                            <th className="sort" data-sort="telephone">
                              Role
                            </th>
                            <th className="sort" data-sort="statut">
                              statut
                            </th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody className="list_admin">{renderTableData}</tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <Pagination
                        paginator={paginator}
                        currentPage={currentPage}
                        limit={10}
                        pagination={PanaginationList}
                      />
                    </div>
                  </div>
                  <UserAddModal
                    usersType={usersType}
                    showAdd={showAdd}
                    handleClose={handleClose}
                    handleToast={handleToast}
                    addUserToList={addUserToList}
                    showLoader={showLoader}
                  />
                  {selectedUser && (
                    <UserUpdateModal
                      selectedUser={selectedUser}
                      usersType={usersType}
                      show={show}
                      handleClose={handleClose}
                      handleToast={handleToast}
                      updateUserInList={updateUserInList}
                      showLoader={showLoader}
                    />
                  )}
                  <UserStatusUpdateModal />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Users;
