import React from "react";
import { Link, useNavigate } from "react-router-dom";
// import { LogOut } from '../../helpers/storage.helper';
function Header(props) {
  const navigate = useNavigate();
  const LogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    navigate("/");
  };
  return (
    <nav className="navbar navbar-light navbar-glass navbar-top navbar-expand">
      <button
        className="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3"
        data-bs-toggle="collapse"
        data-bs-target="#navbarVerticalCollapse"
        aria-controls="navbarVerticalCollapse"
        aria-expanded="false"
        aria-label="Toggle Navigation"
      >
        <span className="navbar-toggle-icon">
          <span className="toggle-line"></span>
        </span>
      </button>
      <Link className="navbar-brand me-1 me-sm-3" to={"#"}>
        <div className="d-flex align-items-center">
          <img
            className="me-2"
            src="../../../assets/logo_anita_2.png"
            width="80"
            height="50"
            alt=""
          />
          <span className="font-sans-serif"></span>
        </div>
      </Link>
      <ul className="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center">
        <li className="nav-item">
          <div className="theme-control-toggle fa-icon-wait px-2">
            <input
              className="form-check-input ms-0 theme-control-toggle-input"
              id="themeControlToggle"
              type="checkbox"
              data-theme-control="theme"
              value="dark"
            />
            <label
              className="mb-0 theme-control-toggle-label theme-control-toggle-light"
              for="themeControlToggle"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Switch to light theme"
            >
              <span className="fas fa-sun fs-0"></span>
            </label>
            <label
              className="mb-0 theme-control-toggle-label theme-control-toggle-dark"
              for="themeControlToggle"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Switch to dark theme"
            >
              <span className="fas fa-moon fs-0"></span>
            </label>
          </div>
        </li>
        <li className="nav-item dropdown">
          <Link
            className="nav-link pe-0 ps-2"
            id="navbarDropdownUser"
            to={"#"}
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div className="avatar avatar-xl">
              <img
                className="rounded-circle"
                src="../../assets/avatar-1.png"
                alt=""
                id="profile_img"
              />
            </div>
          </Link>
          <div
            className="dropdown-menu dropdown-menu-end py-0"
            aria-labelledby="navbarDropdownUser"
          >
            <div className="bg-white dark__bg-1000 rounded-2 py-2">
              <Link className="dropdown-item navigation" to={"#"} id="username">
                Profil
              </Link>
              <div className="dropdown-divider"></div>
              <button className="dropdown-item log_out" onClick={LogOut}>
                Déconnexion
              </button>
            </div>
          </div>
        </li>
      </ul>
    </nav>
  );
}

export default Header;
