import ApiHelper from "../../../libs/Api";

export const depenseList = async (depenseListUrl = "/spents/all") => {
  try {
    return await ApiHelper.request(depenseListUrl);
  } catch (error) {
    return error.response;
  }
};

const depenseCreationUrl = "/spents/create";

export const createDepense = async (body) => {
  try {
    return await ApiHelper.request(depenseCreationUrl, "post", body);
  } catch (error) {
    return error.response;
  }
};

export const editDepense = async (depenseEditUrl, body) => {
  try {
    return await ApiHelper.request(depenseEditUrl, "put", body);
  } catch (error) {
    return error.response;
  }
};
