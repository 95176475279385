import React, { useEffect, useState } from "react";
import { BGHOLDER as bg, checkStatusCodes } from "../../constants";
import { Icon } from "@iconify/react";
import Modal from "react-bootstrap/Modal";
import { editCommercial } from "./services/commercial.service";

function CommercialEditModal({
  selectedCommercial,
  showEdit,
  handleClose,
  handleToast,
  updateCommercialInList,
  showLoader,
}) {
  const [codeCommercial, setCodeCommercial] = useState("");
  const [nomCommercial, setNomCommercial] = useState("");
  const [telephoneCommercial, setTelephoneCommercial] = useState("");
  const [emailCommercial, setEmailCommercial] = useState("");

  const changeInputState = (selectedCommercial) => {
    setCodeCommercial(() => selectedCommercial.code);
    setNomCommercial(() => selectedCommercial.name);
    setTelephoneCommercial(() => selectedCommercial.phone);
    setEmailCommercial(() => selectedCommercial.email);
  };

  const clearInput = () => {
    setCodeCommercial("");
    setNomCommercial("");
    setTelephoneCommercial("");
    setEmailCommercial("");
  };

  const CommerciallEdit = async (e) => {
    showLoader(true);
    e.preventDefault();
    const editData = {
      name: nomCommercial,
      email: emailCommercial,
      phone: telephoneCommercial,
    };
    const CommercialEditUrl = `customers/commercials/${
      codeCommercial ? codeCommercial : ""
    }/update`;
    const response = await editCommercial(CommercialEditUrl, editData);
    let state = false;
    let message = "";
    let type = "";
    if (checkStatusCodes(response.data.statusCode)) {
      showLoader(false);
      state = true;
      message = response.data.message;
      type = "success";
      handleToast(state, type, message);
      updateCommercialInList({
        ...selectedCommercial,
        name: nomCommercial,
        email: emailCommercial,
        phone: telephoneCommercial,
      }); // mettre à jour le commercial dans la liste
    } else {
      showLoader(false);
      state = true;
      message = response.data.message;
      type = "error";
      handleToast(state, type, message);
    }
  };
  useEffect(() => {
    // Mettez à jour le type d'utilisateur en fonction du rôle sélectionné
    if (selectedCommercial) {
      console.log(selectedCommercial);
      changeInputState(selectedCommercial);
    }
  }, [selectedCommercial]);
  return (
    <Modal
      show={showEdit}
      onHide={handleClose}
      animation={true}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
      <Modal.Header>
        <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
          <button
            class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
            onClick={() => {
              handleClose();
              clearInput();
            }}
          ></button>
        </div>
        <div className="bg-light rounded-top-lg py-3 ps-4 pe-6">
          <h4 className="mb-1" id="staticBackdropLabel">
            Modifier un commercial
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="p-4">
          <div className="row">
            <form onSubmit={(e) => CommerciallEdit(e)}>
              <div className="col-lg-12">
                <div className="d-flex">
                  <div className="flex-1">
                    <div className="row">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="fullname">
                          Nom:
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="mdi:company"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="fullname"
                            className="form-control sb-input"
                            type="text"
                            aria-label="fullname"
                            placeholder="Nom"
                            aria-describedby="basic-addon1"
                            defaultValue={nomCommercial}
                            onChange={(e) => setNomCommercial(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="phone" className="form-label">
                          Telephone
                        </label>
                        <div className="input-group ">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="ic:baseline-phone"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="phone"
                            className="form-control sb-input"
                            type="tel"
                            aria-label="Telephone"
                            placeholder="Ex: +22177.....87"
                            aria-describedby="basic-addon1"
                            defaultValue={telephoneCommercial}
                            onChange={(e) =>
                              setTelephoneCommercial(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="email">
                          Email
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="material-symbols:mail"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="email"
                            className="form-control sb-input"
                            type="email"
                            aria-label="email"
                            placeholder="Saisissez votre adresse email"
                            aria-describedby="basic-addon1"
                            defaultValue={emailCommercial}
                            onChange={(e) => setEmailCommercial(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>

                <div className="d-flex">
                  <div className="flex-1">
                    <button
                      className="btn btn-outline-danger rounded-pill me-1 mb-1"
                      type="button"
                      onClick={() => {
                        handleClose();
                        clearInput();
                      }}
                    >
                      Annuler
                    </button>
                    <button
                      className="btn btn-outline-primary rounded-pill me-1 mb-1 btn-edit"
                      type="submit"
                      id="edit_charge_send"
                      onClick={handleClose}
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CommercialEditModal;
