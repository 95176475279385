import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const configToast = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light",
};

function ToastMessage({ toastState }) {
  const [lastToastId, setLastToastId] = useState(null);

  const showToastMessage = (toastState) => {
    const { message, type } = toastState;
    if (lastToastId !== null) {
      toast.dismiss(lastToastId);
    }
    switch (type) {
      case "success":
        setLastToastId(toast.success(message, configToast));
        break;
      case "error":
        setLastToastId(toast.error(message, configToast));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (toastState.show) {
      showToastMessage(toastState);
    }
  }, [toastState]);

  return <ToastContainer />;
}

export default ToastMessage;
