import React from 'react';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from "recharts";

const LineChartComponentForCaisse = () => {
    const data = [
        { name: "01", Sortie: 4000, Production: 2400, amt: 2400 },
        { name: "02", Sortie: 3000, Production: 1398, amt: 2210 },
        { name: "03", Sortie: 2000, Production: 9800, amt: 2290 },
        { name: "04", Sortie: 2780, Production: 3908, amt: 2000 },
        { name: "05", Sortie: 1890, Production: 4800, amt: 2181 },
        { name: "06", Sortie: 2390, Production: 3800, amt: 2500 },
        { name: "07", Sortie: 3490, Production: 4300, amt: 2100 },
        { name: "08", Sortie: 4000, Production: 2400, amt: 2400 },
        { name: "09", Sortie: 3000, Production: 1398, amt: 2210 },
        { name: "10", Sortie: 2000, Production: 9800, amt: 2290 },
        { name: "11", Sortie: 4000, Production: 2400, amt: 2400 },
        { name: "12", Sortie: 3000, Production: 1398, amt: 2210 },
        { name: "13", Sortie: 2000, Production: 9800, amt: 2290 },
        { name: "14", Sortie: 2780, Production: 3908, amt: 2000 },
        { name: "15", Sortie: 1890, Production: 4800, amt: 2181 },
        { name: "16", Sortie: 2390, Production: 3800, amt: 2500 },
        { name: "17", Sortie: 3490, Production: 4300, amt: 2100 },
        { name: "18", Sortie: 4000, Production: 2400, amt: 2400 },
        { name: "19", Sortie: 3000, Production: 1398, amt: 2210 },
        { name: "20", Sortie: 3000, Production: 1398, amt: 2210 },
        { name: "21", Sortie: 4000, Production: 2400, amt: 2400 },
        { name: "22", Sortie: 3000, Production: 1398, amt: 2210 },
        { name: "23", Sortie: 2000, Production: 9800, amt: 2290 },
        { name: "24", Sortie: 2780, Production: 3908, amt: 2000 },
        { name: "25", Sortie: 1890, Production: 4800, amt: 2181 },
        { name: "26", Sortie: 2390, Production: 3800, amt: 2500 },
        { name: "27", Sortie: 3490, Production: 4300, amt: 2100 },
        { name: "28", Sortie: 4000, Production: 2400, amt: 2400 },
        { name: "29", Sortie: 3000, Production: 1398, amt: 2210 },
        { name: "30", Sortie: 3000, Production: 1398, amt: 2210 }
        ];
        
        return (
            <LineChart width={450} height={300} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="Production" stroke="#8884d8" />
            {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
            </LineChart>
        );
};
    
    export default LineChartComponentForCaisse;