import React, { useEffect, useState } from "react";
import { BGHOLDER as bg, checkStatusCodes } from "../../constants";
import { Icon } from "@iconify/react";
import Modal from "react-bootstrap/Modal";
import { editEntreprise } from "./services/entreprise.service";

function EntrepriseEditModal({
  selectedEntreprise,
  showEdit,
  handleClose,
  handleToast,
  updateEntrepriseInList,
  showLoader,
}) {
  const [codeEntreprise, setCodeEntreprise] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [tva, setTva] = useState(0);

  const clearInput = () => {
    setCodeEntreprise("");
    setName("");
    setPhone("");
    setEmail("");
    setAddress("");
    setTva(0);
  };

  const entrepriselEdit = async (e) => {
    showLoader(true);
    e.preventDefault();
    const editData = {
      name: name,
      email: email,
      phone: phone,
      address: address,
      tva: parseInt(tva),
    };
    const entrepriseEditUrl = `/customers/companies/${
      codeEntreprise ? codeEntreprise : ""
    }/update`;
    const response = await editEntreprise(entrepriseEditUrl, editData);
    let state = false;
    let message = "";
    let type = "";
    if (checkStatusCodes(response.data.statusCode)) {
      showLoader(false);
      state = true;
      message = response.data.message;
      type = "success";
      handleToast(state, type, message);
      updateEntrepriseInList({
        ...selectedEntreprise,
        name,
        email,
        phone,
        address,
        tva,
      }); // mettre à jour l'entreprise dans la liste
    } else {
      showLoader(false);
      state = true;
      message = response.data.message;
      type = "error";
      handleToast(state, type, message);
    }
  };
  useEffect(() => {
    // Mettez à jour le type d'utilisateur en fonction du rôle sélectionné
    if (selectedEntreprise) {
      const { code, name, phone, email, address, tva } = selectedEntreprise;
      setCodeEntreprise(code);
      setName(name);
      setPhone(phone);
      setEmail(email);
      setAddress(address);
      setTva(parseInt(tva));
    }
  }, [selectedEntreprise]);

  return (
    <Modal
      show={showEdit}
      onHide={handleClose}
      animation={true}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
      <Modal.Header>
        <div className="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
          <button
            className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
            onClick={() => {
              handleClose();
              clearInput();
            }}
          ></button>
        </div>
        <div className="bg-light rounded-top-lg py-3 ps-4 pe-6">
          <h4 className="mb-1" id="staticBackdropLabel">
            Modifier une entrprise
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="p-4">
          <div className="row">
            <form onSubmit={(e) => entrepriselEdit(e)}>
              <div className="col-lg-12">
                <div className="d-flex">
                  <div className="flex-1">
                    <div className="row">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="fullname">
                          Nom:
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="mdi:company"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="fullname"
                            className="form-control sb-input"
                            type="text"
                            aria-label="fullname"
                            placeholder="Nom"
                            aria-describedby="basic-addon1"
                            defaultValue={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="phone" className="form-label">
                          Telephone
                        </label>
                        <div className="input-group ">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="ic:baseline-phone"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="phone"
                            className="form-control sb-input"
                            type="tel"
                            aria-label="Telephone"
                            placeholder="Ex: +22177.....87"
                            aria-describedby="basic-addon1"
                            defaultValue={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="email">
                          Email
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="material-symbols:mail"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="email"
                            className="form-control sb-input"
                            type="email"
                            aria-label="email"
                            placeholder="Saisissez votre adresse email"
                            aria-describedby="basic-addon1"
                            defaultValue={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="adresse">
                          Adresse:
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="mdi:address-marker"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="adresse"
                            className="form-control sb-input"
                            type="text"
                            aria-label="adresse"
                            placeholder="adresse"
                            aria-describedby="basic-addon1"
                            defaultValue={address}
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="tva">
                          TVA
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="icon-park-solid:balance-two"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="tva"
                            className="form-control sb-input"
                            type="number"
                            aria-label="tva"
                            placeholder="Le TVA de l'entreprise"
                            aria-describedby="basic-addon1"
                            defaultValue={selectedEntreprise.tva}
                            onChange={(e) => setTva(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>

                <div className="d-flex">
                  <div className="flex-1">
                    <button
                      className="btn btn-outline-danger rounded-pill me-1 mb-1"
                      type="button"
                      onClick={() => {
                        handleClose();
                        clearInput();
                      }}
                    >
                      Annuler
                    </button>
                    <button
                      className="btn btn-outline-primary rounded-pill me-1 mb-1 btn-edit"
                      type="submit"
                      id="edit_charge_send"
                      onClick={handleClose}
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default EntrepriseEditModal;
