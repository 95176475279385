import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";

import { BGHOLDER as bg, checkStatusCodes } from "../../constants";
import { editPersonnel } from "./services/personnel.service";
import {
  dateFormatForSubmit,
  formatDateForInput,
} from "../../helpers/dates.helper";

function PersonnelUpdateModal({
  selectedPersonnel,
  personnelTypes,
  showEdit,
  handleClose,
  handleToast,
  updatePersonnelInList,
  showLoader,
}) {
  const [code, setCode] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState(null);
  const [salary, setSalary] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [placeOfBirth, setPlaceOfBirth] = useState("");
  const [familySituation, setFamilySituation] = useState("");
  const [numberOfChildren, setNumberOfChildren] = useState("");
  const [contract, setContract] = useState("");
  const [diploma, setDiploma] = useState("");
  const [recruitmentDate, setRecruitmentDate] = useState("");

  const { register } = useForm();

  const personnelEdit = async (e) => {
    showLoader(true);
    e.preventDefault();
    const editData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      salary: salary,
      date_of_birth: dateFormatForSubmit(dateOfBirth),
      place_of_birth: placeOfBirth,
      family_situation: familySituation,
      number_of_children: numberOfChildren,
      contract: contract,
      diploma: diploma,
      recruitment_date: dateFormatForSubmit(recruitmentDate),
      code_category: role.code,
    };
    const personnelEditUrl = `/personnals/${code ? code : ""}/update`;
    const response = await editPersonnel(personnelEditUrl, editData);
    let state = false;
    let message = "";
    let type = "";
    if (checkStatusCodes(response.data.statusCode)) {
      showLoader(false);
      state = true;
      message = response.data.message;
      type = "success";
      handleToast(state, type, message);
      updatePersonnelInList({
        ...selectedPersonnel,
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phone,
        salary: salary,
        date_of_birth: dateOfBirth,
        place_of_birth: placeOfBirth,
        family_situation: familySituation,
        number_of_children: numberOfChildren,
        contract: contract,
        diploma: diploma,
        recruitment_date: recruitmentDate,
        category: role,
      });
    } else {
      state = true;
      message = response.data.message;
      type = "error";
      handleToast(state, type, message);
    }
  };

  const changeInputState = () => {
    setCode(selectedPersonnel.code);
    setfirstName(() => selectedPersonnel.first_name);
    setLastName(() => selectedPersonnel.last_name);
    setEmail(() => selectedPersonnel.email);
    setPhone(() => selectedPersonnel.phone);
    setSalary(() => selectedPersonnel.salary);
    setDateOfBirth(() => selectedPersonnel.date_of_birth);
    setPlaceOfBirth(() => selectedPersonnel.place_of_birth);
    setFamilySituation(() => selectedPersonnel.family_situation);
    setNumberOfChildren(() => selectedPersonnel.number_of_children);
    setContract(() => selectedPersonnel.contract);
    setDiploma(() => selectedPersonnel.diploma);
    setRecruitmentDate(() => selectedPersonnel.recruitment_date);
  };

  const clearInput = () => {
    setCode("");
    setfirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setSalary("");
    setDateOfBirth("");
    setPlaceOfBirth("");
    setFamilySituation("");
    setNumberOfChildren("");
    setContract("");
    setDiploma("");
    setRecruitmentDate("");
  };
  useEffect(() => {
    // Mettez à jour le type d'utilisateur en fonction du rôle sélectionné
    if (selectedPersonnel) {
      changeInputState();

      const personnelRoleCode = selectedPersonnel.category.code;
      const correspondingType = personnelTypes.find(
        (type) => type.code === personnelRoleCode
      );
      setRole(correspondingType);
    }
  }, [selectedPersonnel, personnelTypes]);

  return (
    <Modal
      show={showEdit}
      onHide={handleClose}
      animation={true}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
      <Modal.Header>
        <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
          <button
            class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
            onClick={() => {
              handleClose();
              clearInput();
            }}
          ></button>
        </div>
        <div className="bg-light rounded-top-lg py-3 ps-4 pe-6">
          <h4 className="mb-1" id="staticBackdropLabel">
            Modifier l'employé:
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="p-4">
          <div className="row">
            <form onSubmit={(e) => personnelEdit(e)}>
              <div className="col-lg-12">
                <div className="d-flex">
                  <div className="flex-1">
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="edit_fullname">
                          Prenom:
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <i className="fas fa-user text-white"></i>
                          </span>
                          <input
                            id="edit_fullname"
                            className="form-control sb-input"
                            type="text"
                            aria-label="edit_fullname"
                            placeholder="Prenom"
                            aria-describedby="basic-addon1"
                            defaultValue={firstName}
                            onChange={(e) => setfirstName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="edit_fullname">
                          Nom:
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <i className="fas fa-user text-white"></i>
                          </span>
                          <input
                            id="edit_fullname"
                            className="form-control sb-input"
                            type="text"
                            aria-label="edit_fullname"
                            placeholder="Nom"
                            aria-describedby="basic-addon1"
                            defaultValue={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="edit_email">
                          Email
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <i className="fas fa-envelope text-white"></i>
                          </span>
                          <input
                            id="edit_email"
                            className="form-control sb-input"
                            type="email"
                            aria-label="email"
                            placeholder="Saisissez l' adresse email"
                            aria-describedby="basic-addon1"
                            defaultValue={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6 mt-3">
                        <label htmlFor="edit_phone" className="form-label">
                          Telephone
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <i className="fas fa-phone text-white"></i>
                          </span>
                          <input
                            id="edit_phone"
                            className="form-control sb-input"
                            type="tel"
                            aria-label="Telephone"
                            placeholder="Ex: +22177.....87"
                            aria-describedby="basic-addon1"
                            defaultValue={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mt-3">
                        <label htmlFor="personnel_role">Rôle</label>
                        <select
                          className="form-select js-choice mb-3"
                          id="personnel_role"
                          size="1"
                          name="personnel_role"
                          data-options='{"removeItemButton":true}'
                          {...register("code_category", { required: true })}
                          value={role ? role.code : ""}
                          onChange={(e) => {
                            const selectedRoleCode = e.target.value;
                            const selectedType = personnelTypes.find(
                              (type) => type.code === selectedRoleCode
                            );
                            setRole(selectedType);
                          }}
                        >
                          <option value="">Sélectionner un rôle...</option>
                          {personnelTypes && personnelTypes.length > 0
                            ? personnelTypes.map((type) => (
                                <option key={type.code} value={type.code}>
                                  {type.name}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="edit_date_naissance">
                          Date de naissance:
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <i className="fas fa-calendar text-white"></i>
                          </span>
                          <input
                            id="edit_date_naissance"
                            className="form-control sb-input"
                            type="date"
                            aria-label="edit_date_naissance"
                            placeholder="Date de naissance"
                            aria-describedby="basic-addon1"
                            defaultValue={
                              formatDateForInput(dateOfBirth).split("T")[0]
                            }
                            onChange={(e) => setDateOfBirth(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="edit_lieu_naissance">
                          Lieu de naissance
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <i className="fas fa-map-pin text-white"></i>
                          </span>
                          <input
                            id="edit_lieu_naissance"
                            className="form-control sb-input"
                            type="text"
                            aria-label="edit_lieu_naissance"
                            placeholder="Lieu de naissance"
                            aria-describedby="basic-addon1"
                            defaultValue={placeOfBirth}
                            onChange={(e) => setPlaceOfBirth(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="edit_situation_familiale">
                          Situation familiale:
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="material-symbols:family-restroom"
                              className="text-white"
                              width="25"
                              height="25"
                            />
                          </span>
                          <input
                            id="edit_situation_familiale"
                            className="form-control sb-input"
                            type="text"
                            aria-label="edit_situation_familiale"
                            placeholder="Situation familiale"
                            aria-describedby="basic-addon1"
                            defaultValue={familySituation}
                            onChange={(e) => setFamilySituation(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="edit_nbr_enfants">
                          Nombre d'enfants
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="fa-solid:child"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="edit_nbr_enfants"
                            className="form-control sb-input"
                            type="number"
                            aria-label="edit_nbr_enfants"
                            aria-describedby="basic-addon1"
                            defaultValue={numberOfChildren}
                            onChange={(e) =>
                              setNumberOfChildren(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="edit_salaire">Salaire de base:</label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <i className="fas fa-coins text-white"></i>
                          </span>
                          <input
                            id="edit_salaire"
                            className="form-control sb-input"
                            type="number"
                            aria-label="edit_salaire"
                            placeholder="Montant du salaire"
                            aria-describedby="basic-addon1"
                            defaultValue={salary}
                            onChange={(e) => setSalary(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="edit_contrat">Contrat</label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="clarity:contract-solid"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="edit_contrat"
                            className="form-control sb-input"
                            type="text"
                            aria-label="edit_contrat"
                            aria-describedby="basic-addon1"
                            defaultValue={contract}
                            onChange={(e) => setContract(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="edit_diplome">
                          Diplomes
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary "
                            id="basic-addon1"
                          >
                            <Icon
                              icon="mdi:certificate"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="edit_diplome"
                            className="form-control sb-input"
                            type="text"
                            aria-label="diplome"
                            aria-describedby="basic-addon1"
                            defaultValue={diploma}
                            onChange={(e) => setDiploma(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label
                          className="form-label"
                          htmlFor="edit_recruitment_date"
                        >
                          Date de recrutement
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <i className="fas fa-calendar text-white"></i>
                          </span>
                          <input
                            id="edit_recruitment_date"
                            className="form-control sb-input"
                            type="date"
                            aria-label="Date de recrutement"
                            aria-describedby="basic-addon1"
                            defaultValue={
                              formatDateForInput(recruitmentDate).split("T")[0]
                            }
                            onChange={(e) => setRecruitmentDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="flex-1">
                    <button
                      className="btn btn-outline-danger rounded-pill me-1 mb-1"
                      type="button"
                      onClick={() => {
                        handleClose();
                        clearInput();
                      }}
                    >
                      Annuler
                    </button>
                    <button
                      className="btn btn-outline-primary rounded-pill me-1 mb-1 btn-edit"
                      type="submit"
                      id="edit_personnel"
                      onClick={handleClose}
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default PersonnelUpdateModal;
