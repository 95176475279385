import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { BGHOLDER as bg } from '../../../constants'
// import { Icon } from '@iconify/react';
import { dateFormatToFr } from '../../../helpers/dates.helper';
// import { dateFormatToFr } from '../../helpers/dates.helper';


function HistorisqueDetailModal({ selectedHistorique, showDetail, handleClose}) {

    useEffect(() => {
        if (!selectedHistorique) {
            return () => {
              // Logique de nettoyage ou de destruction si nécessaire
              // Cette fonction sera appelée lors du démontage du composant
            };
          }
    }, [selectedHistorique])

    return (
        <Modal show={showDetail} onHide={handleClose} animation={true} size="lg" aria-labelledby="example-modal-sizes-title-lg">
            <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
            <Modal.Header>
                <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
                    <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                        onClick={handleClose}></button>
                </div>
                <div className="bg-light rounded-top-lg py-3 ps-4 pe-6">
                    <h4 className="mb-1" id="staticBackdropLabel">
                    {selectedHistorique ? (
                        <span>
                            <span>{`${selectedHistorique?.product?.name}`}</span>: <span class="text-success">{selectedHistorique?.action} </span> 
                        </span>    
                    ):null}
                    </h4>
                    <p class="fs--4 mb-0">
                        créer par <a class="link-600 fw-semi-bold" href="#">{selectedHistorique?.created_by.name}</a>
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="p-4">
                    <div className="row">
                        
                        <div class="d-flex">
                            <span class="fa-stack ms-n1 me-3">
                                <i class="fas fa-circle fa-stack-2x text-200"></i>
                                <i class="fa-inverse fa-stack-1x text-primary fas fa-inventory"
                                    data-fa-transform="shrink-2"></i>
                            </span>
                            {selectedHistorique ? (
                                <div class="flex-1">
                                    <h5 class="mb-2 fs-0">Infos sur l'action</h5>
                                    <div class="d-flex">
                                        <ul class="list-group fs--4 w-100">
                                            <li class="list-group-item me-2 me-lg-0 fs-1">
                                                Date de l'action: <span class="text-success">{dateFormatToFr(selectedHistorique?.date)}</span>
                                            </li>
                                            <li class="list-group-item me-2 me-lg-0 fs-1">
                                                Quantité: <span class="text-success">{selectedHistorique?.quantity} {selectedHistorique?.product?.unit_of_measure}</span>
                                            </li>
                                            <li class="list-group-item me-2 me-lg-0 fs-1">
                                                Nom du produit: <span class="text-success">{selectedHistorique?.product?.name}</span>
                                            </li>
                                            <li class="list-group-item me-2 me-lg-0 fs-1">
                                                Prix unitaire: <span class="text-success">{selectedHistorique?.product?.unit_price} XOF</span>
                                            </li>
                                            <li class="list-group-item me-2 me-lg-0 fs-1">
                                                Type de produit: <span class="text-success">{selectedHistorique?.product?.product_type?.name}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            ):null}
                        </div>
                        <hr class="my-4" />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default HistorisqueDetailModal;