import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import PublicRouter from "./router/PublicRouter";
import ProfileRouter from "./router/ProfileRouter";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/me/*" element={<ProfileRouter />} />
        <Route path="/*" element={<PublicRouter />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
