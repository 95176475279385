import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";

import { login } from "./services/auth.service";
import ErrorMessage from "../../components/messages/ErrorMessage";
import Loader from "../../components/partials/Loader";
import { checkStatusCodes } from "../../constants";
import { saveToken } from "../../helpers/storage.helper";

function Login(props) {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [loaderState, setLoaderState] = useState(false);
  const {
    register,
    handleSubmit,
    // formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoaderState(true);
    const response = await login(data);
    if (checkStatusCodes(response.statusCode)) {
      setLoaderState(false);
      setErrorMessage(null);
      saveToken(response.data.access_token);
      navigate("/me/dashboard");
    } else {
      setErrorMessage(
        `Message: ${response.message} Code: ${response.statusCode}`
      );
      setLoaderState(false);
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
    }
  };

  return (
    <main
      className="main m-0 p-0"
      id="top"
      style={{
        backgroundImage: "url(../../../assets/publicbg.jpeg)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="container" data-layout="container">
        {loaderState ? <Loader loaderState={loaderState} /> : null}

        {/* <script>
            var isFluid = JSON.parse(localStorage.getItem('isFluid'));
            if (isFluid) {
              var container = document.querySelector('[data-layout]');
              container.classList.remove('container');
              container.classList.add('container-fluid');
            }
          </script> */}
        <div className="row flex-center min-vh-100 py-6">
          <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
            <Link className="d-flex flex-center mb-4" to={""}>
              <img
                className="me-2 rounded-circle"
                src="../../../assets/logo_anita_2.png"
                alt=""
                width="200"
                height="100"
              />
            </Link>
            <div className="card">
              <div className="card-body p-4 p-sm-5">
                <div className="row flex-between-center mb-2">
                  <div className="col-auto">
                    <h5>Log in</h5>
                    {errorMessage === "" ? null : (
                      <div className="d-flex justify-content-center my-3">
                        <ErrorMessage errorMessage={errorMessage} />
                      </div>
                    )}
                  </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-3">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Email address"
                      {...register("email", { required: true })}
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Password"
                      {...register("password", { required: true })}
                    />
                  </div>
                  <div className="row flex-between-center">
                    <div className="col-auto">
                      {/* <div className="form-check mb-0"><input className="form-check-input" type="checkbox" id="basic-checkbox" checked="checked" /><label className="form-check-label mb-0" for="basic-checkbox">Remember me</label></div> */}
                    </div>
                    <div className="col-auto">
                      <Link className="fs--1" to={"/passwordforget"}>
                        Mot de passe oublié?
                      </Link>
                    </div>
                  </div>
                  <div className="mb-3">
                    <button
                      className="btn btn-primary d-block w-100 mt-3"
                      type="submit"
                      name="submit"
                      disabled={loaderState}
                    >
                      Log in
                    </button>
                  </div>
                </form>
                <div className="position-relative mt-4"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Login;
