import React from 'react';
const ErrorMessage = ({errorMessage}) => {
    return (
        <div className="alert alert-danger border-2 d-flex align-items-center" role="alert">
            <div className="bg-danger me-3 icon-item"><span className="fas fa-times-circle text-white fs-3" width="25"></span></div>
            <p className="mb-0 flex-1">{errorMessage}</p>
            {/* <button className="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button> */}
        </div>
    );
};

export default ErrorMessage;