import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
// import { useForm } from 'react-hook-form';

const DynamicFields = ({ register, option }) => {
  const [fields, setFields] = useState([{ id: 1, label: "", value: "" }]);
  //   const {register,} = useForm()
  const addField = () => {
    const newField = {
      id: fields.length + 1,
      label: "",
      value: "",
    };
    setFields([...fields, newField]);
  };

  const handleInputChange = (id, fieldKey, event) => {
    const updatedFields = fields.map((field) =>
      field.id === id ? { ...field, [fieldKey]: event.target.value } : field
    );
    setFields(updatedFields);
  };

  const removeField = (id) => {
    const filteredFields = fields.filter((field) => field.id !== id);
    setFields(filteredFields);
  };

  useEffect(() => {
    console.log("option", option);
  }, [option]);
  return (
    <>
      {fields.map((field) => (
        <div className="row mb-3" key={field.id}>
          <div className="col-md-5" key={"label_" + field.id}>
            <div className="input-group mb-3">
              <span className="input-group-text bg-primary" id="basic-addon1">
                <Icon
                  icon="fluent-emoji-high-contrast:label"
                  width="25"
                  height="25"
                  className="text-white"
                />
              </span>
              <input
                type="text"
                className="form-control sb-input"
                placeholder="Label"
                value={field.label}
                {...register(`label${field.id}`, { required: false })}
                defaultValue={option.libelle || ""}
                onChange={(e) => handleInputChange(field.id, "label", e)}
              />
            </div>
          </div>
          <div className="col-md-5" key={"value_" + field.id}>
            <div className="input-group mb-3">
              <span className="input-group-text bg-primary" id="basic-addon1">
                <Icon
                  icon="carbon:value-variable"
                  width="25"
                  height="25"
                  className="text-white"
                />
              </span>
              <input
                type="text"
                className="form-control sb-input"
                placeholder="Valeur"
                {...register(`value${field.id}`, { required: false })}
                defaultValue={option.value || ""}
                onChange={(e) => handleInputChange(field.id, "value", e)}
              />
            </div>
          </div>
          <div className="col-md-2">
            <button
              onClick={() => removeField(field.id)}
              class="btn btn-outline-danger me-1 mb-1"
              type="button"
            >
              <i className="fas fa-trash"></i>
            </button>
          </div>
        </div>
      ))}
      <button
        onClick={addField}
        class="btn btn-outline-primary me-1 mb-3"
        type="button"
      >
        <i className="fas fa-plus"></i>
      </button>
      {/* <button onClick={addField}>Ajouter une paire label-value</button> */}
    </>
  );
};

export default DynamicFields;
