import React from "react";
import { Link } from "react-router-dom";
import { BGHOLDER as bg, checkStatusCodes } from "../constants";

function NotFound(props) {
  return (
    <main className="main" id="top">
      <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
      <div className="container" data-layout="container">
        <div className="row flex-center min-vh-100 py-6 text-center">
          <div className="col-sm-10 col-md-8 col-lg-6 col-xxl-5">
            <Link className="d-flex flex-center mb-4" to={"/"}>
              <img
                className="me-2 rounded-circle"
                src="../../../assets/logo_anita_2.png"
                alt=""
                width="200"
                height="100"
              />
            </Link>
            <div className="card">
              <div className="card-body p-4 p-sm-5">
                <div className="fw-black lh-1 text-300 fs-error">404</div>
                <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">
                  La page que vous cherchez est introuvable.
                </p>
                <hr />
                <p>
                  Assurez-vous que l'adresse est correcte et que la page n'a pas
                  été déplacée. Si vous pensez qu'il s'agit d'une erreur,{" "}
                  {/* <a href="mailto:info@exmaple.com">contact us</a>. */}
                  Contactez l'Admin
                </p>
                <Link className="btn btn-success btn-sm mt-3" to={"/"}>
                  <span className="fas fa-home me-2"></span>Ramenez-moi à la
                  page d'accueil
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default NotFound;
