import React, { useEffect, useState } from "react";
import { BGHOLDER as bg, checkStatusCodes } from "../../constants";
import Modal from "react-bootstrap/Modal";
import { editPointage } from "./services/pointage.service";

function PointageEditModal({
  selectedPointage,
  showEdit,
  handleClose,
  handleToast,
  updatePointageInList,
  showLoader,
}) {
  const [codePointage, setCodePointage] = useState("");

  const pointageEdit = async () => {
    showLoader(true);
    const PointageEditUrl = `/personnals/clockings/${
      codePointage ? codePointage : ""
    }/descent`;
    const response = await editPointage(PointageEditUrl, {});
    let state = false;
    let message = "";
    let type = "";
    if (checkStatusCodes(response.data.statusCode)) {
      const pointage = response.data.data;
      pointage.personnel = selectedPointage.personnel;
      showLoader(false);

      state = true;
      message = response.data.message;

      type = "success";
      handleToast(state, type, message);
      updatePointageInList(pointage);
    } else {
      showLoader(false);
      state = true;
      message = response.data.message;
      type = "error";
      handleToast(state, type, message);
    }
  };
  useEffect(() => {
    // Mettez à jour le type d'utilisateur en fonction du rôle sélectionné
    if (selectedPointage && selectedPointage.personnel) {
      setCodePointage(selectedPointage.code);
    }
  }, [selectedPointage]);
  return (
    <Modal show={showEdit} onHide={handleClose} animation={true}>
      <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
      <div className="modal-content position-relative">
        <Modal.Body className="modal-body p-0">
          <div className="rounded-top-lg py-3 ps-4 pe-6 bg-light">
            <h5 className="mb-1" id="modalExampleDemoLabel">
              {selectedPointage && selectedPointage.personnel ? (
                <span className="sb-text">
                  {selectedPointage.personnel.first_name}{" "}
                  {selectedPointage.personnel.last_name}
                </span>
              ) : null}{" "}
              est-il(elle) descendu(e)?
            </h5>
          </div>
          <div className="action_status">
            <div className="d-flex justify-content-center m-2 ">
              <button
                className="btn btn-outline-success mx-2 btn-sm change-status-user"
                type="button"
                onClick={() => {
                  pointageEdit();
                  handleClose();
                }}
              >
                marquer descendu
              </button>
              <button
                className="btn btn-outline-danger btn-sm"
                type="button"
                onClick={() => {
                  handleClose();
                }}
              >
                Annuler
              </button>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
}

export default PointageEditModal;
