import { Icon } from '@iconify/react';
import { BGHOLDER as bg } from '../../constants';

function HeaderCommande({ handleShowAdd }) {
  return (
    <div className="row mb-3 p-0 ps-3">
      <div className="card mb-3">
        <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3><Icon icon="icon-park-solid:transaction-order" width="50" height="50" /> Commandes</h3>
              <p className="mb-0">Gestion des Commandes</p>
            </div>
            <div className="col-lg-4">
              <div className="d-flex align-items-center justify-content-end my-3" id="bulk-select-replace-element">
                <button className="btn btn-falcon-success btn-sm" type="button" onClick={handleShowAdd}>
                  <span className="fas fa-plus" data-fa-transform="shrink-3 down-2">
                  </span>
                  <span className="ms-1">Nouvelle Commande</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderCommande;



