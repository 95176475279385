/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTrail, animated as a } from "react-spring";

import Loader from "../../components/partials/Loader";
import { Icon } from "@iconify/react";
import ToastMessage from "../../components/messages/ToastMessage";
import { BGHOLDER as bg, checkStatusCodes } from "../../constants";
import { commercialList } from "../../components/customer/services/commercial.service";
import CommercialAddModal from "../../components/customer/CommercialAddModal";
import CommercialEditModal from "../../components/customer/CommercialEditModal";
import Pagination from "../../components/partials/Pagination";

function Commercial(props) {
  const [commercials, setCommercials] = useState([]);
  const [paginator, setPaginator] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loaderState, setLoaderState] = useState(false);
  const [selectedCommercial, setSelectedCommercial] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [toastState, setToastState] = useState({
    show: false,
    type: "",
    message: "",
  });
  const [match, setMach] = useState("");
  const [toggle, setToggle] = useState(true);

  const trail = useTrail(commercials?.length || 0, {
    opacity: toggle ? 1 : 0,
    x: toggle ? 0 : 20,
    from: { opacity: 0, x: 20 },
  });

  const handleToast = useCallback((show, type, message) => {
    setToastState((prevState) => ({
      ...prevState,
      show,
      type,
      message,
    }));
  }, []);

  const handleClose = () => {
    setShowAdd(false);
    setShowEdit(false);
  };
  const handleShowAdd = useCallback(() => setShowAdd(true), []);
  const handleShowEdit = useCallback(() => setShowEdit(true), []);

  const showLoader = useCallback((state) => setLoaderState(state), []);

  const fetchCommercialList = useCallback(async () => {
    try {
      const response = await commercialList();
      const { statusCode, data } = response.data;

      if (checkStatusCodes(statusCode)) {
        setCommercials(data.list);
        setPaginator(data.pagination);
      } else {
        handleToast(
          true,
          "error",
          "Vous n'avez pas les authorisations réquises"
        );
      }
    } catch (error) {
      handleToast(
        true,
        "error",
        "Une erreur est survenue lors de la récupération de la liste des commerciaux."
      );
    } finally {
      setLoaderState(false);
    }
  }, [toastState]);

  const PanaginationList = async (page) => {
    setLoaderState(true);
    setCurrentPage(page);
    try {
      const response = await commercialList(
        `/customers/commercials/searchd?page=${page}&limit=10`
      );
      const { data } = response.data;
      setCommercials(data.list);
      setPaginator(data.pagination);
    } catch (error) {
      handleToast(
        true,
        "error",
        "Une erreur est survenue lors de la récupération des données de la page."
      );
    } finally {
      setLoaderState(false);
    }
  };

  const addCommercialToList = (newCommercial) => {
    setCommercials((prevCommercials) => [newCommercial, ...prevCommercials]);
  };

  const updateCommercialInList = (updatedCommercial) => {
    setCommercials((prevCommercials) =>
      prevCommercials.map((commercial) =>
        commercial.code === updatedCommercial.code
          ? updatedCommercial
          : commercial
      )
    );
  };

  const doSearch = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        setLoaderState(true);
        const response = await commercialList(
          `/customers/commercials/search?match=${match}`
        );
        setCommercials(response.data.data.list);
        setPaginator(response.data.data.pagination);
      } finally {
        setLoaderState(false);
      }
    },
    [match]
  );

  const handleEditClick = useCallback((user) => {
    setSelectedCommercial(user);
    handleShowEdit();
  }, []);

  const renderTableData = useMemo(() => {
    if (!commercials || commercials.length === 0) {
      return (
        <tr>
          <td colSpan="4" className="text-center">
            Aucun commercial disponible.
          </td>
        </tr>
      );
    }

    return (
      <tbody>
        {trail.map(({ x, ...rest }, index) => {
          const { code, name, email, phone } = commercials[index];
          return (
            <a.tr
              key={code}
              style={{
                ...rest,
                transform: x.to((x) => `translate3d(0,${x}px,0)`),
              }}
            >
              <td>{name}</td>
              <td>{email}</td>
              <td>
                <div className="d-flex align-items-center justify-content-center">
                  <button
                    className="btn ms-2 btn-warning px-2 py-1"
                    type="button"
                    title="Modifier"
                    onClick={() =>
                      handleEditClick({ code, name, email, phone })
                    }
                  >
                    <i className="text-500 text-white fas fa-edit"></i>
                  </button>
                </div>
              </td>
            </a.tr>
          );
        })}
      </tbody>
    );
  }, [commercials, trail]);

  useEffect(() => {
    setLoaderState(true);
    fetchCommercialList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {loaderState ? <Loader loaderState={loaderState} /> : null}

      {toastState.show ? <ToastMessage toastState={toastState} /> : null}
      <div className="row g-3 mb-3 p-0">
        <div className="row mb-3 p-0 ps-3">
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={bg}
            ></div>
            <div className="card-body position-relative">
              <div className="row">
                <div className="col-lg-8">
                  <h3>
                    <Icon icon="raphael:customer" width="50" height="50" />{" "}
                    Commerciaux
                  </h3>
                  <p className="mb-0">Gestion des commerciaux</p>
                </div>
                <div className="col-lg-4">
                  <div
                    className="d-flex align-items-center justify-content-end my-3"
                    id="bulk-select-replace-element"
                  >
                    <button
                      className="btn btn-falcon-success btn-sm"
                      type="button"
                      onClick={handleShowAdd}
                    >
                      <span
                        className="fas fa-plus"
                        data-fa-transform="shrink-3 down-2"
                      ></span>
                      <span className="ms-1">Nouvelle Commercial</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 p-0 ps-3">
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={bg}
            ></div>
            <div className="card-body position-relative">
              <div className="row p-0">
                <div className="col-md-8 p-0 m-0">
                  <div className="col-12 input-group" id="search_box">
                    <input
                      type="text"
                      className="form-control input-sm m-0"
                      placeholder="Rechercher"
                      id="searchInput"
                      onChange={(data) => {
                        setMach(data.target.value);
                      }}
                    />
                    <span className="input-group-text" id="basic-addon3">
                      <a
                        href="/#"
                        className="search"
                        onClick={(e) => doSearch(e)}
                      >
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 p-0 mb-3">
          <div className="col-md-12 p-0">
            <div className="card mb-3">
              <div className="card-header bg-light">
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="mb-0" id="followers">
                      Les Commerciaux
                    </h5>
                  </div>
                </div>
              </div>
              <div className="card-body bg-light px-1 py-0">
                <div className="row g-0 fs--1">
                  <div
                    id="tableExample2"
                    data-list='{"valueNames":["Nom","email","telephone","role"],"page":5,"pagination":true}'
                  >
                    <div className="table-responsive scrollbar">
                      <table
                        className="table table-bordered table-striped fs--1 mb-0"
                        id="table"
                      >
                        <thead className="bg-200 text-900">
                          <tr>
                            <th className="sort" data-sort="name">
                              Nom
                            </th>
                            <th className="sort" data-sort="email">
                              email
                            </th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        {renderTableData}
                      </table>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <Pagination
                        paginator={paginator}
                        currentPage={currentPage}
                        limit={10}
                        pagination={PanaginationList}
                      />
                    </div>
                  </div>
                  <CommercialAddModal
                    showAdd={showAdd}
                    handleClose={handleClose}
                    handleToast={handleToast}
                    addCommercialToList={addCommercialToList}
                    showLoader={showLoader}
                  />
                  {selectedCommercial && (
                    <CommercialEditModal
                      selectedCommercial={selectedCommercial}
                      showEdit={showEdit}
                      handleClose={handleClose}
                      handleToast={handleToast}
                      updateCommercialInList={updateCommercialInList}
                      showLoader={showLoader}
                    />
                  )}

                  {/* <UserStatusUpdateModal /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Commercial;
