import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { BGHOLDER as bg, checkStatusCodes } from "../../constants";
// import { Icon } from '@iconify/react';
import { dateFormatToFr } from "../../helpers/dates.helper";
import { produitList } from "../stock/produit/services/produit.service";

function ProductionDetailModal({
  selectedProduction,
  showDetail,
  handleClose,
  handleToast,
  showLoader,
}) {
  // const [productionCode, setProductionCode] = useState("")
  const [production, setProduction] = useState(null);

  const fetchProduction = async () => {
    showLoader(true);
    try {
      const response = await produitList(
        `/productions/histories/by_production/${selectedProduction.code}`
      );
      if (checkStatusCodes(response.data.statusCode)) {
        setProduction(response.data.data);
      } else {
        const { message } = response.data;
        let type = "error";
        handleToast(true, type, message);
      }
    } finally {
      showLoader(false);
    }
  };

  useEffect(() => {
    if (selectedProduction) {
      fetchProduction();
    }
  }, [selectedProduction]);

  return (
    <Modal
      show={showDetail}
      onHide={handleClose}
      animation={true}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
      <Modal.Header>
        <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
          <button
            class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
            onClick={handleClose}
          ></button>
        </div>
        <div className="bg-light rounded-top-lg py-3 ps-4 pe-6">
          <h4 className="mb-1" id="staticBackdropLabel">
            {selectedProduction ? (
              <span>
                <span>Production</span>:{" "}
                <span class="text-success">{selectedProduction?.code} </span>
              </span>
            ) : null}
          </h4>
          <p class="fs--4 mb-0">
            {/* créer par <a class="link-600 fw-semi-bold" href="#">{selectedProduction?.created_by.name}</a> */}
          </p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="p-4">
          <div className="row">
            {production && production.length > 0
              ? production.map((production_step) => (
                  <div class="d-flex" key={production_step?.code}>
                    <div class="flex-1 m-2">
                      <h5 class="mb-2 fs-0">
                        Etape:{" "}
                        <span className="text-success">
                          {production_step?.production_step.value}
                        </span>
                      </h5>
                      <div class="d-flex">
                        <ul class="list-group fs--4 w-100">
                          <li class="list-group-item me-2 me-lg-0 fs--4">
                            Date de debut:{" "}
                            <span class="text-success">
                              {dateFormatToFr(production_step?.date_start)}
                            </span>
                          </li>
                          <li class="list-group-item me-2 me-lg-0 fs--4">
                            Date de fin:{" "}
                            <span class="text-success">
                              {dateFormatToFr(production_step?.date_start)}
                            </span>
                          </li>
                          <li class="list-group-item me-2 me-lg-0 fs--4">
                            Assigné à :
                            {production_step.assignments.map((personnel) => (
                              <span class="badge rounded-pill badge-soft-success ms-2">
                                {personnel?.first_name} {personnel?.last_name}
                              </span>
                            ))}
                          </li>
                          {/*<li class="list-group-item me-2 me-lg-0 fs-1">
                                                    Prix unitaire: <span class="text-success">{production_step?.product?.unit_price} XOF</span>
                                                </li>
                                                <li class="list-group-item me-2 me-lg-0 fs-1">
                                                    Type de produit: <span class="text-success">{production_step?.product?.product_type?.name}</span>
                                                </li> */}
                        </ul>
                      </div>
                    </div>
                    <hr class="my-4" />
                  </div>
                ))
              : null}
            <hr class="my-4" />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ProductionDetailModal;
