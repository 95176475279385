import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTrail, animated as a } from "react-spring";
import Loader from "../../components/partials/Loader";
import { Icon } from "@iconify/react";

import ToastMessage from "../../components/messages/ToastMessage";
import { BGHOLDER as bg, checkStatusCodes } from "../../constants";
import {
  produitList,
  produitTypes,
} from "../../components/stock/produit/services/produit.service";
import ProduitAddModal from "../../components/stock/produit/ProduitAddModal";
import ProduitEditModal from "../../components/stock/produit/ProduitEditModal";
import Pagination from "../../components/partials/Pagination";
import { formaterNombreAvecEspace } from "../../helpers/numbers.helper";

function Produit(props) {
  const [produits, setProduits] = useState([]);
  const [produitTypesValues, setproduitTypesValues] = useState([]);
  const [paginator, setPaginator] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loaderState, setLoaderState] = useState(false);
  const [selectedProduit, setSelectedProduit] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [toastState, setToastState] = useState({
    show: false,
    type: "",
    message: "",
  });
  //   const items = produits || [];

  const trail = useTrail(produits?.length || 0, {
    from: { opacity: 0, transform: "translate3d(0,40px,0)" },
    to: { opacity: 1, transform: "translate3d(0,0px,0)" },
    config: { mass: 1, tension: 200, friction: 15 },
  });
  const handleToast = (show, type, message) => {
    const updatedToast = { ...toastState };
    updatedToast.show = show;
    updatedToast.type = type;
    updatedToast.message = message;

    setToastState(updatedToast);
  };
  const handleClose = () => {
    setShowAdd(false);
    setShowEdit(false);
  };

  const handleShowAdd = useCallback(() => setShowAdd(true), []);
  const handleShowEdit = useCallback(() => setShowEdit(true), []);
  const showLoader = useCallback((state) => setLoaderState(state), []);

  const fetchProduitList = async () => {
    const response = await produitList();
    if (checkStatusCodes(response.data.statusCode)) {
      setProduits(response.data.data.list);
      setPaginator(response.data.data.pagination);
      setLoaderState(false);
    } else {
      let state = true;
      let message = "Vous n'avez pas les authorisations réquises";
      let type = "error";
      handleToast(state, type, message);
    }
  };

  const fetchProduitTypeList = async () => {
    try {
      const response = await produitTypes();
      setproduitTypesValues(response.data.data.list);
      setLoaderState(false);
    } catch (error) {}
  };

  const PanaginationList = async (page) => {
    setLoaderState(true);
    setCurrentPage(page);
    try {
      const response = await produitList(`/products/all?page=${page}&limit=10`);
      setProduits(response.data.data.list);
      setPaginator(response.data.data.pagination);
      setLoaderState(false);
    } catch (error) {}
  };
  // const type = usersType
  const handleEditClick = (Produit) => {
    // Mettez à jour l'état avec les données de l'utilisateur sélectionné
    // setProduitsTypes(type)
    setSelectedProduit(Produit);
    // Affichez le modal pour l'édition ici
    handleShowEdit();
  };

  const addProduitToList = useCallback((newProduit) => {
    setProduits((prevProduits) => [newProduit, ...prevProduits]);
  }, []);

  const updateProduitInList = useCallback((updatedProduit) => {
    setProduits((prevProduits) =>
      prevProduits.map((produit) =>
        produit.code === updatedProduit.code ? updatedProduit : produit
      )
    );
  });
  const renderTableData = useMemo(() => {
    return (
      <tbody className="list_admin">
        {produits && produits.length > 0 ? (
          trail.map(({ opacity, transform }, index) => (
            <a.tr
              key={produits[index]?.code}
              style={{
                opacity,
                transform,
              }}
            >
              <a.td>{produits[index]?.name}</a.td>
              <a.td>
                {formaterNombreAvecEspace(produits[index]?.unit_price)} XOF
              </a.td>
              <a.td>{produits[index]?.unit_of_measure}</a.td>
              <a.td>{produits[index]?.product_type?.name}</a.td>
              <a.td>
                <div className="d-flex align-items-center justify-content-center">
                  <button
                    className="btn ms-2 btn-warning px-2 py-1"
                    type="button"
                    title="Modifier"
                    onClick={() => handleEditClick(produits[index])}
                  >
                    <i className="text-500 text-white fas fa-edit"></i>
                  </button>
                </div>
              </a.td>
            </a.tr>
          ))
        ) : (
          <tr>
            <td colSpan="5" className="text-center">
              Aucun produit disponible.
            </td>
          </tr>
        )}
      </tbody>
    );
  }, [produits]);

  useEffect(() => {
    setLoaderState(true);
    fetchProduitList();
    fetchProduitTypeList();
  }, []);

  return (
    <div>
      {loaderState ? <Loader loaderState={loaderState} /> : null}

      {toastState.show ? <ToastMessage toastState={toastState} /> : null}
      <div className="row g-3 mb-3 p-0">
        <div className="row mb-3 p-0 ps-3">
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={bg}
            ></div>
            <div className="card-body position-relative">
              <div className="row">
                <div className="col-lg-8">
                  <h3>
                    <Icon
                      icon="gridicons:product-virtual"
                      width="50"
                      height="50"
                    />{" "}
                    Produits
                  </h3>
                  <p className="mb-0">Gestion des produits</p>
                </div>
                <div className="col-lg-4">
                  <div
                    className="d-flex align-items-center justify-content-end my-3"
                    id="bulk-select-replace-element"
                  >
                    <button
                      className="btn btn-falcon-success btn-sm"
                      type="button"
                      onClick={handleShowAdd}
                    >
                      <span
                        className="fas fa-plus"
                        data-fa-transform="shrink-3 down-2"
                      ></span>
                      <span className="ms-1">Enrégistrer un produit</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 p-0 ps-3">
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={bg}
            ></div>
            <div className="card-body position-relative">
              <div className="row p-0">
                <div className="col-md-8 p-0 m-0">
                  <div className="col-12 input-group" id="search_box">
                    <input
                      type="text"
                      className="form-control input-sm m-0"
                      placeholder="Rechercher"
                      id="searchInput"
                    />
                    <span className="input-group-text" id="basic-addon3">
                      <a href="" className="search">
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 p-0 mb-3">
          <div className="col-md-12 p-0">
            <div className="card mb-3">
              <div className="card-header bg-light">
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="mb-0" id="followers">
                      Les Produits
                    </h5>
                  </div>
                </div>
              </div>
              <div className="card-body bg-light px-1 py-0">
                <div className="row g-0 fs--1">
                  <div
                    id="tableExample2"
                    data-list='{"valueNames":["Nom","email","telephone","role"],"page":5,"pagination":true}'
                  >
                    <div className="table-responsive scrollbar">
                      <table
                        className="table table-bordered table-striped fs--1 mb-0"
                        id="table"
                      >
                        <thead className="bg-200 text-900">
                          <tr>
                            <th className="sort">Nom Produit</th>
                            <th className="sort">Prix unitaire</th>
                            <th className="sort">Unité de mesure</th>
                            <th className="sort">Type de produit</th>
                            {/* <th className="sort" data-sort="telephone">Adresse</th>
                                                        <th className="sort" data-sort="statut">TVA</th> */}
                            <th>Actions</th>
                          </tr>
                        </thead>
                        {renderTableData}
                      </table>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <Pagination
                        paginator={paginator}
                        currentPage={currentPage}
                        limit={10}
                        pagination={PanaginationList}
                      />
                    </div>
                  </div>
                  <ProduitAddModal
                    produitTypesValues={produitTypesValues}
                    showAdd={showAdd}
                    handleClose={handleClose}
                    handleToast={handleToast}
                    addProduitToList={addProduitToList}
                    showLoader={showLoader}
                  />
                  <ProduitEditModal
                    produitTypesValues={produitTypesValues}
                    selectedProduit={selectedProduit}
                    showEdit={showEdit}
                    handleClose={handleClose}
                    handleToast={handleToast}
                    updateProduitInList={updateProduitInList}
                    showLoader={showLoader}
                  />
                  {/* <UserStatusUpdateModal /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Produit;
