import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTrail, animated } from "react-spring";
import { Icon } from "@iconify/react";
import Loader from "../../components/partials/Loader";

import ToastMessage from "../../components/messages/ToastMessage";
import { BGHOLDER as bg, checkStatusCodes } from "../../constants";
import Pagination from "../../components/partials/Pagination";
import { produitList } from "../../components/stock/produit/services/produit.service";
import { stockList } from "../../components/stock/stockComponent/services/stock.service";
import StockAddModal from "../../components/stock/stockComponent/StockAddModal";

function Stock(props) {
  const [stocks, setStocks] = useState([]);
  const [produits, setProduits] = useState([]);
  const [paginator, setPaginator] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loaderState, setLoaderState] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [toastState, setToastState] = useState({
    show: false,
    type: "",
    message: "",
  });

  const trail = useTrail(stocks?.length || 0, {
    from: { opacity: 0, transform: "translate3d(0,40px,0)" },
    to: { opacity: 1, transform: "translate3d(0,0px,0)" },
    config: { mass: 1, tension: 200, friction: 15 },
  });
  const handleToast = (show, type, message) => {
    const updatedToast = { ...toastState };
    updatedToast.show = show;
    updatedToast.type = type;
    updatedToast.message = message;

    setToastState(updatedToast);
  };
  const handleClose = () => {
    setShowAdd(false);
  };
  const handleShowAdd = () => setShowAdd(true);
  const showLoader = useCallback((state) => setLoaderState(state), []);

  const fetchStockList = async () => {
    const response = await stockList();
    if (checkStatusCodes(response.data.statusCode)) {
      setStocks(response.data.data.list);
      setPaginator(response.data.data.pagination);
      setLoaderState(false);
    } else {
      let state = true;
      let message = "Vous n'avez pas les authorisations réquises";
      let type = "error";
      handleToast(state, type, message);
    }
  };

  const fetchProduitList = async () => {
    try {
      const response = await produitList("/products/all?page=1&limit=100");
      setProduits(response.data.data.list);
      setLoaderState(false);
    } catch (error) {}
  };

  const PanaginationList = async (page) => {
    setLoaderState(true);
    setCurrentPage(page);
    try {
      const response = await stockList(`/stocks/all?page=${page}&limit=10`);
      setStocks(response.data.data.list);
      setPaginator(response.data.data.pagination);
      setLoaderState(false);
    } catch (error) {}
  };

  const addStockToList = useCallback((newStock) => {
    setStocks((prevStocks) => [newStock, ...prevStocks]);
  }, []);

  const updateStockInList = useCallback((updatedStock) => {
    setStocks((prevStocks) =>
      prevStocks.map((Stock) =>
        Stock.code === updatedStock.code ? updatedStock : Stock
      )
    );
  });

  const renderTableData = useMemo(() => {
    return (
      <tbody className="list_admin">
        {stocks && stocks.length > 0 ? (
          trail.map((style, index) => (
            <animated.tr key={stocks[index]?.code} style={style}>
              <td>{stocks[index]?.product.name}</td>
              <td>
                {stocks[index]?.stock_level}{" "}
                {stocks[index]?.product.unit_of_measure}
              </td>
              <td>{stocks[index]?.product.product_type.name}</td>
              {/* <td>{dateFormatToFr(stocks[index]?.date_recovery)}</td> */}
            </animated.tr>
          ))
        ) : (
          <tr>
            <td colSpan="4" className="text-center">
              Aucun stock disponible.
            </td>
          </tr>
        )}
      </tbody>
    );
  }, [stocks]);

  useEffect(() => {
    setLoaderState(true);
    fetchStockList();
    fetchProduitList();
  }, []);

  return (
    <div>
      {loaderState ? <Loader loaderState={loaderState} /> : null}

      {toastState.show ? <ToastMessage toastState={toastState} /> : null}
      <div className="row g-3 mb-3 p-0">
        <div className="row mb-3 p-0 ps-3">
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={bg}
            ></div>
            <div className="card-body position-relative">
              <div className="row">
                <div className="col-lg-8">
                  <h3>
                    <Icon icon="carbon:heat-map-stocks" width="50" /> stocks
                  </h3>
                  <p className="mb-0">Gestion des stocks</p>
                </div>
                <div className="col-lg-4">
                  <div
                    className="d-flex align-items-center justify-content-end my-3"
                    id="bulk-select-replace-element"
                  >
                    <button
                      className="btn btn-falcon-success btn-sm"
                      type="button"
                      onClick={handleShowAdd}
                    >
                      <span
                        className="fas fa-plus"
                        data-fa-transform="shrink-3 down-2"
                      ></span>
                      <span className="ms-1">Faire un enrégistrement</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 p-0 ps-3">
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={bg}
            ></div>
            <div className="card-body position-relative">
              <div className="row">
                <div className="col-md-6">
                  <div className="col-12 input-group">
                    <select
                      className="form-select js-choice mb-3"
                      id="personnel_role"
                      size="1"
                      name="personnel_role"
                      data-options='{"removeItemButton":true}'
                    >
                      <option value="">Sélectionner un produit...</option>
                      <option value="">Pument</option>
                      <option value="">Poivre</option>
                      <option value="">curcument</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="col-12 input-group">
                    <select
                      className="form-select js-choice mb-3"
                      id="personnel_role"
                      size="1"
                      name="personnel_role"
                      data-options='{"removeItemButton":true}'
                    >
                      <option value="">Sélectionner un type...</option>
                      <option value="">Fini</option>
                      <option value="">Semi-fini</option>
                      <option value="">Matiére première</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 p-0 mb-3">
          <div className="col-md-12 p-0">
            <div className="card mb-3">
              <div className="card-header bg-light">
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="mb-0" id="followers">
                      la liste de stock
                    </h5>
                  </div>
                </div>
              </div>
              <div className="card-body bg-light px-1 py-0">
                <div className="row g-0 fs--1">
                  <div
                    id="tableExample2"
                    data-list='{"valueNames":["Nom","email","telephone","role"],"page":5,"pagination":true}'
                  >
                    <div className="table-responsive scrollbar">
                      <table
                        className="table table-bordered table-striped fs--1 mb-0"
                        id="table"
                      >
                        <thead className="bg-200 text-900">
                          <tr>
                            <th className="sort" data-sort="email">
                              Produit
                            </th>
                            <th className="sort" data-sort="name">
                              Quantité
                            </th>
                            <th className="sort" data-sort="name">
                              Type de produit
                            </th>
                            {/* <th className="sort" data-sort="email">Date de fin</th> */}
                            {/* <th>Actions</th> */}
                          </tr>
                        </thead>
                        {renderTableData}
                      </table>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <Pagination
                        paginator={paginator}
                        currentPage={currentPage}
                        limit={10}
                        pagination={PanaginationList}
                      />
                    </div>
                  </div>
                  <StockAddModal
                    produits={produits}
                    showAdd={showAdd}
                    handleClose={handleClose}
                    handleToast={handleToast}
                    showLoader={showLoader}
                    addStockToList={addStockToList}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stock;
