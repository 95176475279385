import ApiHelper from "../../../../libs/Api";

export const fournisseurList = async (
  fournisseurListUrl = "/suppliers/all"
) => {
  try {
    return await ApiHelper.request(fournisseurListUrl);
  } catch (error) {
    return error.response;
  }
};

const fournisseurCreationUrl = "/suppliers/create";

export const createFournisseur = async (body) => {
  try {
    return await ApiHelper.request(fournisseurCreationUrl, "post", body);
  } catch (error) {
    return error.response;
  }
};

export const editFournisseur = async (fournisseurEditUrl, body) => {
  try {
    return await ApiHelper.request(fournisseurEditUrl, "put", body);
  } catch (error) {
    return error.response;
  }
};
