import React from "react";
import { BGHOLDER as bg, checkStatusCodes } from "../../constants";
import { useForm } from "react-hook-form";
import { Icon } from "@iconify/react";
import Modal from "react-bootstrap/Modal";
import { createDepense } from "./services/depense.service";

function DepenseAddModal({
  vehicules,
  showAdd,
  handleClose,
  handleToast,
  showLoader,
  addDepenseToList,
}) {
  const { register, handleSubmit } = useForm();

  const depenseCreation = async (data) => {
    showLoader(true);
    // data.date = dateFormatForSubmit(data.date)
    handleToast();
    data.amount = parseInt(data.amount);
    const response = await createDepense(data);
    let state = false;
    let message = "";
    let type = "";
    if (checkStatusCodes(response.data.statusCode)) {
      showLoader(false);
      state = true;
      message = response.data.message;
      type = "success";
      handleToast(state, type, message);
      addDepenseToList(response.data.data);
    } else {
      showLoader(false);
      state = true;
      message = response.data.message;
      type = "error";
      handleToast(state, type, message);
    }
  };

  return (
    <Modal
      show={showAdd}
      onHide={handleClose}
      animation={true}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
      <Modal.Header>
        <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
          <button
            class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
            onClick={handleClose}
          ></button>
        </div>
        <div className="bg-light rounded-top-lg py-3 ps-4 pe-6">
          <h4 className="mb-1" id="staticBackdropLabel">
            Enrégistrer une dépense
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="p-4">
          <div className="row">
            <form onSubmit={handleSubmit((data) => depenseCreation(data))}>
              <div className="col-lg-12">
                <div className="d-flex">
                  <div className="flex-1">
                    <div className="row">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="date">
                          Date:
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="clarity:date-solid"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="date"
                            className="form-control sb-input"
                            type="datetime-local"
                            aria-label="date"
                            placeholder="date"
                            aria-describedby="basic-addon1"
                            {...register("date", { required: true })}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="libellé">
                          Libelle de la dépense:
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="fluent-emoji-high-contrast:label"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="libellé"
                            className="form-control sb-input"
                            type="text"
                            aria-label="libellé"
                            placeholder="Libelle de la dépense"
                            aria-describedby="basic-addon1"
                            {...register("title", { required: true })}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="amount">
                          Somme de la dépense:
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="tdesign:undertake-transaction"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="amount"
                            className="form-control sb-input"
                            type="number"
                            aria-label="amount"
                            placeholder="Somme de la dépense"
                            aria-describedby="basic-addon1"
                            {...register("amount", { required: true })}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="mode_transaction"
                          className="form-label"
                        >
                          Les véhicules
                        </label>
                        <div className="input-group ">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="ant-design:car-filled"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <select
                            className="form-select js-choice"
                            id="mode_transaction"
                            size="1"
                            name="mode_transaction"
                            data-options='{"removeItemButton":true}'
                            {...register("code_car", { required: false })}
                          >
                            <option value="">
                              Sélectionner un véhicule...
                            </option>
                            {vehicules && vehicules.length > 0
                              ? vehicules.map((vehicule) => (
                                  <option
                                    key={vehicule.code}
                                    value={vehicule.code}
                                  >
                                    {vehicule?.name_mark}-{vehicule?.name_model}
                                  </option>
                                ))
                              : null}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="flex-1">
                    <button
                      className="btn btn-outline-danger rounded-pill me-1 mb-1"
                      type="button"
                      onClick={handleClose}
                    >
                      Annuler
                    </button>
                    <button
                      className="btn btn-outline-primary rounded-pill me-1 mb-1 btn-edit"
                      type="submit"
                      id="edit_charge_send"
                      onClick={handleClose}
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DepenseAddModal;
