import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useTrail, animated as a } from "react-spring";
import Loader from "../../components/partials/Loader";
import { Icon } from "@iconify/react";

import ToastMessage from "../../components/messages/ToastMessage";
import { BGHOLDER as bg, checkStatusCodes } from "../../constants";
import { salaireList } from "../../components/personnels/services/salaire.service";
import SalaireAddModel from "../../components/personnels/SalaireAddModel";
import SalaireEditModal from "../../components/personnels/SalaireEditModal";
import { personnelList } from "../../components/personnels/services/personnel.service";
import { dateFormatToFr } from "../../helpers/dates.helper";
import Pagination from "../../components/partials/Pagination";

function Salaire(props) {
  const [salaires, setSalaires] = useState([]);
  const [personnels, setPersonnels] = useState([]);
  const [paginator, setPaginator] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loaderState, setLoaderState] = useState(false);
  const [selectedSalaire, setSelectedSalaire] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [toastState, setToastState] = useState({
    show: false,
    type: "",
    message: "",
  });
  const [toggle, setToggle] = useState(true);

  const trail = useTrail(salaires?.length || 0, {
    opacity: toggle ? 1 : 0,
    x: toggle ? 0 : 20,
    from: { opacity: 0, x: 20 },
  });

  const handleToast = (show, type, message) => {
    const updatedToast = { ...toastState };
    updatedToast.show = show;
    updatedToast.type = type;
    updatedToast.message = message;

    setToastState(updatedToast);
  };
  const handleClose = () => {
    setShowAdd(false);
    setShowEdit(false);
  };
  const handleShowAdd = useCallback(() => setShowAdd(true), []);
  const handleShowEdit = useCallback(() => setShowEdit(true), []);
  const showLoader = useCallback((state) => setLoaderState(state), []);

  const fetchSalaireList = useCallback(async () => {
    try {
      const response = await salaireList();
      if (checkStatusCodes(response.data.statusCode)) {
        setSalaires(response.data.data.list);
        setPaginator(response.data.data.pagination);
      } else {
        throw new Error("Unauthorized");
      }
    } catch (error) {
      setLoaderState(false);
      handleToast(true, "error", "Vous n'avez pas les authorisations réquises");
    }
  }, []);

  const fetchPersonnelList = async () => {
    try {
      const response = await personnelList();
      setPersonnels(response.data.data.list);
      setLoaderState(false);
    } catch (error) {}
  };
  // const type = usersType
  const handleEditClick = (salaire) => {
    setSelectedSalaire(salaire);
    // Affichez le modal pour l'édition ici
    handleShowEdit();
  };

  const PanaginationList = async (page) => {
    setLoaderState(true);
    setCurrentPage(page);
    try {
      const response = await salaireList(
        `/personnals/salaries/payments/all?page=${page}&limit=10`
      );
      setSalaires(response.data.data.list);
      setPaginator(response.data.data.pagination);
      setLoaderState(false);
    } catch (error) {}
  };

  const addSalaireToList = useCallback((newSalaire) => {
    setSalaires((prevSalaires) => [newSalaire, ...prevSalaires]);
  }, []);

  /**
   * Updates a Salaire in the list.
   *
   * @param {Object} updatedSalaire - The updated Salaire object.
   * @return {void}
   */
  const updateSalaireInList = useCallback((updatedSalaire) => {
    setSalaires((prevSalaires) =>
      prevSalaires.map((Salaire) =>
        Salaire.code === updatedSalaire.code ? updatedSalaire : Salaire
      )
    );
  }, []);
  const renderTableData = useMemo(() => {
    if (!salaires || salaires.length === 0) {
      return (
        <tr>
          <td colSpan="5" className="text-center">
            Aucun salaire disponible.
          </td>
        </tr>
      );
    }

    return (
      <tbody className="list_admin">
        {trail.map(({ x, ...rest }, index) => {
          const salaire = salaires[index];
          return (
            <a.tr
              key={salaire?.code}
              style={{
                ...rest,
                transform: x.to((x) => `translate3d(0,${x}px,0)`),
              }}
            >
              <td>
                {salaire?.personnel.first_name} {salaire?.personnel.last_name}
              </td>
              <td>{salaire?.amount} XOF</td>
              <td>{salaire?.remaining ?? "N/A"} XOF</td>
              <td>{dateFormatToFr(salaire?.date)}</td>
              <td>
                <div className="d-flex align-items-center justify-content-center">
                  <button
                    className="btn ms-2 btn-warning px-2 py-1"
                    type="button"
                    title="Modifier"
                    onClick={() => handleEditClick(salaire)}
                  >
                    <i className="text-500 text-white fas fa-edit"></i>
                  </button>
                </div>
              </td>
            </a.tr>
          );
        })}
      </tbody>
    );
  }, [salaires, trail]);

  useEffect(() => {
    setLoaderState(true);
    fetchSalaireList();
    fetchPersonnelList();
  }, []);

  return (
    <div>
      {loaderState ? <Loader loaderState={loaderState} /> : null}

      {toastState.show ? <ToastMessage toastState={toastState} /> : null}
      <div className="row g-3 mb-3 p-0">
        <div className="row mb-3 p-0 ps-3">
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={bg}
            ></div>
            <div className="card-body position-relative">
              <div className="row">
                <div className="col-lg-8">
                  <h3>
                    <Icon icon="grommet-icons:money" width="50" height="50" />{" "}
                    Salaires
                  </h3>
                  <p className="mb-0">Gestion des salaires</p>
                </div>
                <div className="col-lg-4">
                  <div
                    className="d-flex align-items-center justify-content-end my-3"
                    id="bulk-select-replace-element"
                  >
                    <button
                      className="btn btn-falcon-success btn-sm"
                      type="button"
                      onClick={handleShowAdd}
                    >
                      <span
                        className="fas fa-plus"
                        data-fa-transform="shrink-3 down-2"
                      ></span>
                      <span className="ms-1">Enrégistrer un paiement</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 p-0 ps-3">
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={bg}
            ></div>
            <div className="card-body position-relative">
              <div className="row">
                <div className="col-md-6">
                  <div className="col-12 input-group">
                    <input
                      type="date"
                      className="form-control input-sm m-0"
                      placeholder="Rechercher"
                      id="searchInput"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="col-12 input-group">
                    <select
                      className="form-select js-choice mb-3"
                      id="personnel_role"
                      size="1"
                      name="personnel_role"
                      data-options='{"removeItemButton":true}'
                    >
                      <option value="">Sélectionner une personne...</option>
                      <option value="">Magatte Diallo</option>
                      <option value="">Youssouf Diallo</option>
                      <option value="">Sofi Diop</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 p-0 mb-3">
          <div className="col-md-12 p-0">
            <div className="card mb-3">
              <div className="card-header bg-light">
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="mb-0" id="followers">
                      Les Payments
                    </h5>
                  </div>
                </div>
              </div>
              <div className="card-body bg-light px-1 py-0">
                <div className="row g-0 fs--1">
                  <div
                    id="tableExample2"
                    data-list='{"valueNames":["Nom","email","telephone","role"],"page":5,"pagination":true}'
                  >
                    <div className="table-responsive scrollbar">
                      <table
                        className="table table-bordered table-striped fs--1 mb-0"
                        id="table"
                      >
                        <thead className="bg-200 text-900">
                          <tr>
                            <th className="sort" data-sort="email">
                              Employé
                            </th>
                            <th className="sort" data-sort="email">
                              Montant
                            </th>
                            <th className="sort" data-sort="email">
                              Restant
                            </th>
                            <th className="sort" data-sort="name">
                              Date
                            </th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        {renderTableData}
                      </table>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <Pagination
                        paginator={paginator}
                        currentPage={currentPage}
                        limit={10}
                        pagination={PanaginationList}
                      />
                    </div>
                  </div>
                  <SalaireAddModel
                    personnels={personnels}
                    showAdd={showAdd}
                    handleClose={handleClose}
                    handleToast={handleToast}
                    showLoader={showLoader}
                    addSalaireToList={addSalaireToList}
                  />
                  {selectedSalaire && (
                    <SalaireEditModal
                      personnels={personnels}
                      selectedSalaire={selectedSalaire}
                      showEdit={showEdit}
                      handleClose={handleClose}
                      handleToast={handleToast}
                      updateSalaireInList={updateSalaireInList}
                      showLoader={showLoader}
                    />
                  )}

                  {/* <UserStatusUpdateModal /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Salaire;
