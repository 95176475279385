import React from 'react';

// import '../../assets/css/loader.css';
import RingLoader from "react-spinners/RingLoader";

const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "green",
    zIndex: 1000,
};
const Loader = ({loaderState}) => {
    
    return (
        <div className="loader-container" style={{ position:"fixed", top:"49%", left: "45%", zIndex:1000 }}>
            <RingLoader
                color="green"
                loading={loaderState}
                cssOverride={override}
                size={75}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
      </div>
  );
};

export default Loader;