import React from "react";
import { BGHOLDER as bg, checkStatusCodes } from "../../../constants";
import { useForm } from "react-hook-form";
import { Icon } from "@iconify/react";
import Modal from "react-bootstrap/Modal";
import { createFournisseur } from "./services/fournisseur.service";
import DynamicFields from "../../formComponent/DynamicFields";

function FournisseurAddModal({
  showAdd,
  handleClose,
  handleToast,
  showLoader,
  addFournisseurToList,
}) {
  const { register, handleSubmit } = useForm();

  const fournisseurCreation = async (data) => {
    showLoader(true);
    const newData = Object.keys(data).reduce((result, key) => {
      // Vérifiez si la clé commence par "label" ou "value"
      if (key.startsWith("label")) {
        const index = key.replace("label", "");
        if (!result.statut_juridique) {
          result.statut_juridique = [];
        }
        if (!result.statut_juridique[index - 1]) {
          result.statut_juridique[index - 1] = {};
        }
        result.statut_juridique[index - 1].libelle = data[key];
      } else if (key.startsWith("value")) {
        const index = key.replace("value", "");
        if (!result.statut_juridique) {
          result.statut_juridique = [];
        }
        if (!result.statut_juridique[index - 1]) {
          result.statut_juridique[index - 1] = {};
        }
        result.statut_juridique[index - 1].value = data[key];
      } else {
        // Copiez les autres clés telles quelles
        result[key] = data[key];
      }
      return result;
    }, {});

    const response = await createFournisseur(newData);
    let state = false;
    let message = "";
    let type = "";
    if (checkStatusCodes(response.data.statusCode)) {
      showLoader(false);
      state = true;
      message = response.data.message;
      type = "success";
      handleToast(state, type, message);
      addFournisseurToList(response.data.data);
    } else {
      showLoader(false);
      state = true;
      message = response.data.message;
      type = "error";
      handleToast(state, type, message);
    }
  };

  return (
    <Modal
      show={showAdd}
      onHide={handleClose}
      animation={true}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
      <Modal.Header>
        <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
          <button
            class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
            onClick={handleClose}
          ></button>
        </div>
        <div className="bg-light rounded-top-lg py-3 ps-4 pe-6">
          <h4 className="mb-1" id="staticBackdropLabel">
            Ajouter un Fournisseur
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="p-4">
          <div className="row">
            <form onSubmit={handleSubmit((data) => fournisseurCreation(data))}>
              <div className="col-lg-12">
                <div className="d-flex">
                  <div className="flex-1">
                    <div className="row">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="fullname">
                          Nom du fournisseur:
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="mdi:company"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="fullname"
                            className="form-control sb-input"
                            type="text"
                            aria-label="fullname"
                            placeholder="Nom"
                            aria-describedby="basic-addon1"
                            {...register("name", { required: true })}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="phone" className="form-label">
                          Telephone
                        </label>
                        <div className="input-group ">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="ic:baseline-phone"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="phone"
                            className="form-control sb-input"
                            type="tel"
                            aria-label="Telephone"
                            placeholder="Ex: +22177.....87"
                            aria-describedby="basic-addon1"
                            {...register("telephone", { required: false })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="email">
                          Email
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="material-symbols:mail"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="email"
                            className="form-control sb-input"
                            type="email"
                            aria-label="email"
                            placeholder="Saisissez votre adresse email"
                            aria-describedby="basic-addon1"
                            {...register("email", { required: false })}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="adresse">
                          adresse
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="material-symbols:mail"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="adresse"
                            className="form-control sb-input"
                            type="text"
                            aria-label="adresse"
                            placeholder="Saisissez l' adresse"
                            aria-describedby="basic-addon1"
                            {...register("address", { required: false })}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="pays">
                          Pays
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="material-symbols:mail"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="pays"
                            className="form-control sb-input"
                            type="text"
                            aria-label="pays"
                            placeholder="Saisissez le pays"
                            aria-describedby="basic-addon1"
                            {...register("country", { required: false })}
                          />
                        </div>
                      </div>
                    </div>
                    <fieldset class="border p-2">
                      <legend class="float-none w-auto p-2">
                        Statut Juridique
                      </legend>
                      <DynamicFields register={register} />
                    </fieldset>
                    {/* </div> */}
                  </div>
                </div>

                <div className="d-flex">
                  <div className="flex-1">
                    <button
                      className="btn btn-outline-danger rounded-pill me-1 mb-1"
                      type="button"
                      onClick={handleClose}
                    >
                      Annuler
                    </button>
                    <button
                      className="btn btn-outline-primary rounded-pill me-1 mb-1 btn-edit"
                      type="submit"
                      id="edit_charge_send"
                      onClick={handleClose}
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default FournisseurAddModal;
