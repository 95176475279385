import { formaterNombreAvecEspace } from "../../../helpers/numbers.helper";
import { renderActionButtons } from "./ActionButtons";
import { renderStatusButton } from "./StatusButton";

export const renderCommandeRow = ({ commande, handleStatusClick, handleDetailClick, handleEditClick }) => (
  <tr key={commande?.code}>
    <td>{commande?.number}</td>
    <td>{formatAmount(commande?.amount_ht)} XOF</td>
    <td>{formatAmount(commande?.amount_tva)} XOF</td>
    <td>{formatAmount(commande?.amount_ttc)} XOF</td>
    <td>{commande?.owner?.name}</td>
    <td>{renderStatusButton(commande, handleStatusClick)}</td>
    <td>{renderActionButtons(commande, handleDetailClick, handleEditClick)}</td>
  </tr>
);

const formatAmount = (amount) => formaterNombreAvecEspace(amount);
