import React from "react";
import { BGHOLDER as bg, checkStatusCodes } from "../../../constants";
import { useForm } from "react-hook-form";
import { Icon } from "@iconify/react";
import Modal from "react-bootstrap/Modal";
import { createStock } from "./services/stock.service";

function StockAddModal({
  produits,
  showAdd,
  handleClose,
  handleToast,
  showLoader,
  addStockToList,
}) {
  const { register, handleSubmit } = useForm();

  const stockCreation = async (data) => {
    handleClose();
    showLoader(true);
    data.number = parseInt(data.number);
    const response = await createStock(data);
    let state = false;
    let message = "";
    let type = "";
    if (checkStatusCodes(response.data.statusCode)) {
      showLoader(false);
      const data = response.data.data;
      state = true;
      message = response.data.message;
      type = "success";
      handleToast(state, type, message);
      addStockToList({
        ...data,
        product: produits.find((produit) => produit.code === data.code_product),
      });
    } else {
      showLoader(false);
      state = true;
      message = response.data.message;
      type = "error";
      handleToast(state, type, message);
    }
  };

  return (
    <Modal
      show={showAdd}
      onHide={handleClose}
      animation={true}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
      <Modal.Header>
        <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
          <button
            class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
            onClick={handleClose}
          ></button>
        </div>
        <div className="bg-light rounded-top-lg py-3 ps-4 pe-6">
          <h4 className="mb-1" id="staticBackdropLabel">
            Faire un enrégistrement
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="p-4">
          <div className="row">
            <form onSubmit={handleSubmit((data) => stockCreation(data))}>
              <div className="col-lg-12">
                <div className="d-flex">
                  <div className="flex-1">
                    <div className="row">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="produit">
                          Produit(s)
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="gridicons:product-virtual"
                              className="text-white"
                              width="25"
                              height="25"
                            />
                          </span>
                          <select
                            className="form-select js-choice"
                            id="produit"
                            size="1"
                            name="produit"
                            data-options='{"removeItemButton":true}'
                            {...register("code_product", { required: true })}
                          >
                            <option value="">Sélectionner un produt...</option>
                            {produits &&
                              produits.map((produit) => (
                                <option key={produit.code} value={produit.code}>
                                  {produit?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="quantite">Quantite:</label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="material-symbols:production-quantity-limits"
                              width="25"
                              height="25"
                              className="text-white"
                            />
                          </span>
                          <input
                            id="quantite"
                            className="form-control sb-input"
                            type="number"
                            aria-label="Quantite"
                            placeholder="Quantite"
                            aria-describedby="basic-addon1"
                            {...register("number", { required: false })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="action">Action:</label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="mdi:call-to-action"
                              width="25"
                              className="text-white"
                            />
                          </span>
                          <select
                            className="form-select js-choice"
                            id="action"
                            size="1"
                            name="action"
                            data-options='{"removeItemButton":true}'
                            {...register("action", { required: true })}
                          >
                            <option value="">Sélectionner un action...</option>

                            <option value="Entrée">Entrée</option>
                            <option value="Sortie">Sortie</option>
                            <option value="Retour">Retour</option>
                            <option value="Ajustement">Ajustement</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>

                <div className="d-flex">
                  <div className="flex-1">
                    <button
                      className="btn btn-outline-danger rounded-pill me-1 mb-1"
                      type="button"
                      onClick={handleClose}
                    >
                      Annuler
                    </button>
                    <button
                      className="btn btn-outline-primary rounded-pill me-1 mb-1 btn-edit"
                      type="submit"
                      id="edit_charge_send"
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default StockAddModal;
