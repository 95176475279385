import ApiHelper from "../../../libs/Api";

export const salaireList = async (
  salaireUrl = "/personnals/salaries/payments/all?page=1&limit=10"
) => {
  try {
    return await ApiHelper.request(salaireUrl);
  } catch (error) {
    return error.response;
  }
};

const salaireCreationUrl = "/personnals/salaries/payments/create";

export const createSalaire = async (body) => {
  try {
    return await ApiHelper.request(salaireCreationUrl, "post", body);
  } catch (error) {
    return error.response;
  }
};

export const editSalaire = async (salaireEditUrl, body) => {
  try {
    return await ApiHelper.request(salaireEditUrl, "put", body);
  } catch (error) {
    return error.response;
  }
};
