import ApiHelper from "../../../../libs/Api";

export const historiqueStockList = async (
  historiqueStockListUrl = "/stocks/histories/all?page=1&limit=10"
) => {
  try {
    return await ApiHelper.request(historiqueStockListUrl);
  } catch (error) {
    return error.response;
  }
};
