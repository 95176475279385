import ApiHelper from "../../../libs/Api";

export const vehiculeList = async (vehiculeListUrl = "/car/all") => {
  try {
    return await ApiHelper.request(vehiculeListUrl);
  } catch (error) {
    return error.response;
  }
};

const vehiculeCreationUrl = "/car/create";

export const createVehicule = async (body) => {
  try {
    return await ApiHelper.request(vehiculeCreationUrl, "post", body);
  } catch (error) {
    return error.response;
  }
};

export const editVehicule = async (vehiculeEditUrl, body) => {
  try {
    return await ApiHelper.request(vehiculeEditUrl, "put", body);
  } catch (error) {
    return error.response;
  }
};
