import { LogOut } from "../helpers/storage.helper";

const STATUS_CODES_SUCCESS = [200, 201];
const STATUS_CODES_ERROR = [400, 401, 402, 404, 403, 500, 501];

export const checkStatusCodes = (status) => {
    if (STATUS_CODES_SUCCESS.includes(parseInt(status))) {
        return true
    }
    if (STATUS_CODES_ERROR.includes(parseInt(status))) {
        return false
    }
    return false
}


export const BGHOLDER = {
    backgroundImage: "url(../assets/img/icons/spot-illustrations/corner-2.png)"
}