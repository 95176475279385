import React, { useCallback, useEffect, useState } from "react";
import { useTrail, animated as a } from "react-spring";
import { Icon } from "@iconify/react";

import Loader from "../../components/partials/Loader";
import ToastMessage from "../../components/messages/ToastMessage";
import { BGHOLDER as bg, checkStatusCodes } from "../../constants";
import Pagination from "../../components/partials/Pagination";
import { depenseList } from "../../components/finance/services/depense.service";
import { formaterNombreAvecEspace } from "../../helpers/numbers.helper";
import DepenseAddModal from "../../components/finance/DepenseAddModal";
import { vehiculeList } from "../../components/finance/services/vehicule.service";
import { dateFormatToFr } from "../../helpers/dates.helper";
import DepenseEditModal from "../../components/finance/DepenseEditModal";

function Depenses(props) {
  const [depenses, setDepenses] = useState([]);
  const [paginator, setPaginator] = useState(null);
  const [vehicules, setVehicules] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loaderState, setLoaderState] = useState(false);
  const [selectedDepense, setSelectedDepense] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [toastState, setToastState] = useState({
    show: false,
    type: "",
    message: "",
  });

  const trail = useTrail(depenses?.length || 0, {
    from: { opacity: 0, transform: "translate3d(0,40px,0)" },
    to: { opacity: 1, transform: "translate3d(0,0px,0)" },
    config: { mass: 1, tension: 200, friction: 15 },
  });

  const handleToast = (show, type, message) => {
    const updatedToast = { ...toastState };
    updatedToast.show = show;
    updatedToast.type = type;
    updatedToast.message = message;

    setToastState(updatedToast);
  };
  const handleClose = () => {
    setShowAdd(false);
    setShowEdit(false);
  };
  const handleShowAdd = () => setShowAdd(true);
  const handleShowEdit = () => setShowEdit(true);
  const showLoader = useCallback((state) => setLoaderState(state), []);

  const fetchDepenseList = async () => {
    const response = await depenseList();
    if (checkStatusCodes(response.data.statusCode)) {
      setDepenses(response.data.data.list);
      setPaginator(response.data.data.pagination);
      setLoaderState(false);
    } else {
      let state = true;
      let message = "Vous n'avez pas les authorisations réquises";
      let type = "error";
      handleToast(state, type, message);
    }
  };

  const fetchVehiculeList = async () => {
    const response = await vehiculeList(`/car/all?page=1&limit=100`);
    if (checkStatusCodes(response.data.statusCode)) {
      setVehicules(response.data.data.list);
      setPaginator(response.data.data.pagination);
      setLoaderState(false);
    } else {
      let state = true;
      let message = "Vous n'avez pas les authorisations réquises";
      let type = "error";
      handleToast(state, type, message);
    }
  };

  // const type = usersType
  const handleEditClick = (depense) => {
    setSelectedDepense(depense);
    handleShowEdit();
  };

  const PanaginationList = async (page) => {
    setLoaderState(true);
    setCurrentPage(page);
    try {
      const response = await depenseList(`/spents/all?page=${page}&limit=10`);
      setDepenses(response.data.data.list);
      setPaginator(response.data.data.pagination);
      setLoaderState(false);
    } catch (error) {}
  };

  const addDepenseToList = useCallback((newDepense) => {
    setDepenses((prevDepenses) => [newDepense, ...prevDepenses]);
  }, []);

  const updateDepenseInList = useCallback((updatedDepense) => {
    setDepenses((prevDepenses) =>
      prevDepenses.map((Depense) =>
        Depense.code === updatedDepense.code ? updatedDepense : Depense
      )
    );
  });

  const renderTableData = () => {
    return (
      <tbody className="list_admin">
        {depenses && depenses.length > 0 ? (
          trail.map(({ opacity, transform }, index) => (
            <a.tr
              key={depenses[index]?.code}
              style={{
                opacity,
                transform,
              }}
            >
              <td>{depenses[index]?.title}</td>
              <td>{formaterNombreAvecEspace(depenses[index]?.amount)} XOF</td>
              <td>{dateFormatToFr(depenses[index]?.date).split(" ")[0]}</td>
              <td>
                <div className="d-flex align-items-center justify-content-center">
                  <button
                    className="btn ms-2 btn-warning px-2 py-1"
                    type="button"
                    title="Modifier"
                    onClick={() => handleEditClick(depenses[index])}
                  >
                    <i className="text-500 text-white fas fa-edit"></i>
                  </button>
                </div>
              </td>
            </a.tr>
          ))
        ) : (
          <tr>
            <td colSpan="5" className="text-center">
              Aucun dépense disponible.
            </td>
          </tr>
        )}
      </tbody>
    );
  };

  useEffect(() => {
    setLoaderState(true);
    fetchDepenseList();
    fetchVehiculeList();
  }, []);
  return (
    <div>
      {loaderState ? <Loader loaderState={loaderState} /> : null}

      {toastState.show ? <ToastMessage toastState={toastState} /> : null}
      <div className="row g-3 mb-3 p-0">
        <div className="row mb-3 p-0 ps-3">
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={bg}
            ></div>
            <div className="card-body position-relative">
              <div className="row">
                <div className="col-lg-8">
                  <h3>
                    <Icon icon="game-icons:expense" width="50" height="50" />{" "}
                    Dépenses
                  </h3>
                  <p className="mb-0">Gestion des Dépenses</p>
                </div>
                <div className="col-lg-4">
                  <div
                    className="d-flex align-items-center justify-content-end my-3"
                    id="bulk-select-replace-element"
                  >
                    <button
                      className="btn btn-falcon-success btn-sm"
                      type="button"
                      onClick={handleShowAdd}
                    >
                      <span
                        className="fas fa-plus"
                        data-fa-transform="shrink-3 down-2"
                      ></span>
                      <span className="ms-1">Nouvelle dépense</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 p-0 ps-3">
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={bg}
            ></div>
            <div className="card-body position-relative">
              <div className="row p-0">
                <div className="col-md-6">
                  <div className="col-12 input-group" id="search_box">
                    <input
                      type="text"
                      className="form-control input-sm m-0"
                      placeholder="Rechercher"
                      id="searchInput"
                    />
                    <span className="input-group-text" id="basic-addon3">
                      <a href="" className="search">
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </a>
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="col-12 input-group" id="search_box">
                    <input
                      type="date"
                      className="form-control input-sm m-0"
                      placeholder="Rechercher"
                      id="searchInput"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 p-0 mb-3">
          <div className="col-md-12 p-0">
            <div className="card mb-3">
              <div className="card-header bg-light">
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="mb-0" id="followers">
                      Les Dépenses
                    </h5>
                  </div>
                </div>
              </div>
              <div className="card-body bg-light px-1 py-0">
                <div className="row g-0 fs--1">
                  <div
                    id="tableExample2"
                    data-list='{"valueNames":["Nom","email","telephone","role"],"page":5,"pagination":true}'
                  >
                    <div className="table-responsive scrollbar">
                      <table
                        className="table table-bordered table-striped fs--1 mb-0"
                        id="table"
                      >
                        <thead className="bg-200 text-900">
                          <tr>
                            <th className="sort" data-sort="email">
                              Libelle de la dépense
                            </th>
                            <th className="sort" data-sort="mode">
                              Somme
                            </th>
                            <th className="sort" data-sort="mode">
                              Date
                            </th>
                            {/* <th className="sort" data-sort="numero">N° de la transaction</th>
                                                        <th className="sort" data-sort="numero">Type de transaction</th>
                                                        <th className="sort" data-sort="date">Date de la transaction</th> */}
                            <th>Actions</th>
                          </tr>
                        </thead>
                        {renderTableData()}
                      </table>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <Pagination
                        paginator={paginator}
                        currentPage={currentPage}
                        limit={10}
                        pagination={PanaginationList}
                      />
                    </div>
                  </div>
                  <DepenseAddModal
                    vehicules={vehicules}
                    showAdd={showAdd}
                    handleClose={handleClose}
                    handleToast={handleToast}
                    addDepenseToList={addDepenseToList}
                    showLoader={showLoader}
                  />
                  {selectedDepense && (
                    <DepenseEditModal
                      selectedDepense={selectedDepense}
                      showEdit={showEdit}
                      handleClose={handleClose}
                      handleToast={handleToast}
                      updateDepenseInList={updateDepenseInList}
                      showLoader={showLoader}
                    />
                  )}
                  {/* <EntrepriseEditModal  selectedEntreprise = {selectedEntreprise} showEdit = {showEdit} handleClose={handleClose} handleToast = {handleToast}/> */}
                  {/* <UserStatusUpdateModal /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Depenses;
