/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTrail, animated as a } from "react-spring";
import { Icon } from "@iconify/react";
import { BGHOLDER as bg, checkStatusCodes } from "../../constants";
import {
  entrepriseList,
  baseurlEntreprise,
} from "../../components/customer/services/entreprise.service";

import Loader from "../../components/partials/Loader";
import ToastMessage from "../../components/messages/ToastMessage";
import EntrepriseAddModal from "../../components/customer/EntrepriseAddModal";
import EntrepriseEditModal from "../../components/customer/EntrepriseEditModal";
import Pagination from "../../components/partials/Pagination";

function Entreprise(props) {
  const [entreprises, setEntreprises] = useState([]);
  const [paginator, setPaginator] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loaderState, setLoaderState] = useState(false);
  const [selectedEntreprise, setSelectedEntreprise] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [match, setMach] = useState("");

  const [toastState, setToastState] = useState({
    show: false,
    type: "",
    message: "",
  });

  const [toggle, setToggle] = useState(true);

  const trail = useTrail(entreprises?.length || 0, {
    opacity: toggle ? 1 : 0,
    x: toggle ? 0 : 20,
    from: { opacity: 0, x: 20 },
  });
  const handleToast = (show, type, message) => {
    const updatedToast = { ...toastState };
    updatedToast.show = show;
    updatedToast.type = type;
    updatedToast.message = message;

    setToastState(updatedToast);
  };
  const handleClose = () => {
    setShowAdd(false);
    setShowEdit(false);
  };
  const handleShowAdd = useCallback(() => setShowAdd(true), []);
  const handleShowEdit = useCallback(() => setShowEdit(true), []);
  const showLoader = useCallback((state) => setLoaderState(state), []);

  const fetchEntrpriseList = useCallback(async () => {
    try {
      const { data } = await entrepriseList();
      if (checkStatusCodes(data.statusCode)) {
        const { list, pagination } = data.data;
        setEntreprises(list);
        setPaginator(pagination);
      } else {
        handleToast(
          true,
          "error",
          "Vous n'avez pas les authorisations réquises"
        );
      }
    } catch (error) {
      handleToast(true, "error", "Une erreur est survenue");
    } finally {
      setLoaderState(false);
    }
  });

  // const type = usersType
  const handleEditClick = useCallback((user) => {
    setSelectedEntreprise(user);
    handleShowEdit();
  }, []);

  const PanaginationList = async (page) => {
    setLoaderState(true);
    setCurrentPage(page);
    try {
      const response = await entrepriseList(
        `${baseurlEntreprise}/search?page=${page}&limit=10&match=${match}`
      );
      setEntreprises(response.data.data.list);
      setPaginator(response.data.data.pagination);
      setLoaderState(false);
    } catch (error) {}
  };

  const addEntrepriseToList = (newEntreprise) => {
    setEntreprises((prevEntreprises) => [newEntreprise, ...prevEntreprises]);
  };

  const updateEntrepriseInList = (updatedEntreprise) => {
    const updatedEntreprises = entreprises.map((entreprise) =>
      entreprise.code === updatedEntreprise.code
        ? updatedEntreprise
        : entreprise
    );
    setEntreprises(updatedEntreprises);
  };
  const doSearch = useCallback(
    async (e) => {
      e.preventDefault();
      setLoaderState(true);
      setCurrentPage(1);
      try {
        const response = await entrepriseList(
          `${baseurlEntreprise}/search?match=${match}`
        );
        setEntreprises(response?.data?.data?.list || []);
        setPaginator(response?.data?.data?.pagination || {});
      } finally {
        setLoaderState(false);
      }
    },
    [match, setEntreprises, setPaginator, setLoaderState]
  );

  const renderTableData = useMemo(() => {
    if (!entreprises || entreprises.length === 0) {
      return (
        <tr>
          <td colSpan="6" className="text-center">
            Aucun entreprise disponible.
          </td>
        </tr>
      );
    }

    return (
      <tbody className="list_admin">
        {trail.map(({ x, ...rest }, index) => (
          <a.tr
            key={entreprises[index]?.code}
            style={{
              ...rest,
              transform: x.to((x) => `translate3d(0,${x}px,0)`),
            }}
          >
            <td>{entreprises[index]?.name}</td>
            <td>{entreprises[index]?.email}</td>
            <td>{entreprises[index]?.phone}</td>
            <td>{entreprises[index]?.address}</td>
            <td>{entreprises[index]?.tva}</td>
            <td>
              <button
                className="btn ms-2 btn-warning px-2 py-1"
                type="button"
                title="Modifier"
                onClick={() => handleEditClick(entreprises[index])}
              >
                <i className="text-500 text-white fas fa-edit"></i>
              </button>
            </td>
          </a.tr>
        ))}
      </tbody>
    );
  }, [entreprises, trail]);

  useEffect(() => {
    setLoaderState(true);
    fetchEntrpriseList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {loaderState ? <Loader loaderState={loaderState} /> : null}

      {toastState.show ? <ToastMessage toastState={toastState} /> : null}
      <div className="row g-3 mb-3 p-0">
        <div className="row mb-3 p-0 ps-3">
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={bg}
            ></div>
            <div className="card-body position-relative">
              <div className="row">
                <div className="col-lg-8">
                  <h3>
                    <Icon icon="mdi:company" width="50" height="50" />{" "}
                    Entreprises
                  </h3>
                  <p className="mb-0">Gestion des Entreprises</p>
                </div>
                <div className="col-lg-4">
                  <div
                    className="d-flex align-items-center justify-content-end my-3"
                    id="bulk-select-replace-element"
                  >
                    <button
                      className="btn btn-falcon-success btn-sm"
                      type="button"
                      onClick={handleShowAdd}
                    >
                      <span
                        className="fas fa-plus"
                        data-fa-transform="shrink-3 down-2"
                      ></span>
                      <span className="ms-1">Nouvelle Entreprise</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 p-0 ps-3">
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={bg}
            ></div>
            <div className="card-body position-relative">
              <div className="row p-0">
                <div className="col-md-8 p-0 m-0">
                  <div className="col-12 input-group" id="search_box">
                    <input
                      type="text"
                      className="form-control input-sm m-0"
                      placeholder="Rechercher"
                      id="searchInput"
                      onChange={(data) => {
                        setMach(data.target.value);
                      }}
                    />
                    <span className="input-group-text" id="basic-addon3">
                      <a
                        href="/#"
                        className="search"
                        onClick={(e) => doSearch(e)}
                      >
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 p-0 mb-3">
          <div className="col-md-12 p-0">
            <div className="card mb-3">
              <div className="card-header bg-light">
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="mb-0" id="followers">
                      Les entreprise
                    </h5>
                  </div>
                </div>
              </div>
              <div className="card-body bg-light px-1 py-0">
                <div className="row g-0 fs--1">
                  <div
                    id="tableExample2"
                    data-list='{"valueNames":["Nom","email","telephone","role"],"page":5,"pagination":true}'
                  >
                    <div className="table-responsive scrollbar">
                      <table
                        className="table table-bordered table-striped fs--1 mb-0"
                        id="table"
                      >
                        <thead className="bg-200 text-900">
                          <tr>
                            <th className="sort" data-sort="name">
                              Nom
                            </th>
                            <th className="sort" data-sort="email">
                              Email
                            </th>
                            <th className="sort">Telephone</th>
                            <th className="sort" data-sort="telephone">
                              Adresse
                            </th>
                            <th className="sort" data-sort="statut">
                              TVA
                            </th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        {renderTableData}
                      </table>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <Pagination
                        paginator={paginator}
                        currentPage={currentPage}
                        limit={10}
                        pagination={PanaginationList}
                      />
                    </div>
                  </div>
                  <EntrepriseAddModal
                    showAdd={showAdd}
                    handleClose={handleClose}
                    handleToast={handleToast}
                    addEntrepriseToList={addEntrepriseToList}
                    showLoader={showLoader}
                  />
                  {selectedEntreprise && (
                    <EntrepriseEditModal
                      selectedEntreprise={selectedEntreprise}
                      showEdit={showEdit}
                      handleClose={handleClose}
                      handleToast={handleToast}
                      updateEntrepriseInList={updateEntrepriseInList}
                      showLoader={showLoader}
                    />
                  )}

                  {/* <UserStatusUpdateModal /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Entreprise;
