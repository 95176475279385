import { useNavigate } from "react-router-dom";

export const saveToken = (token) => {
  localStorage.setItem("token", token);
};

export const saveUserData = (data) => {
  localStorage.setItem("userData", JSON.stringify(data));
};

export const getToken = () => {
  // if (isLogged()) {
  return localStorage.getItem("token");
  // }
  // else {
  //     LogOut()
  // }
};

export const getUserData = () => {
  return JSON.parse(localStorage.getItem("userData"));
};

export const isLogged = () => {
  let token = localStorage.getItem("token");
  return !!token;
};

export const LogOut = () => {
  // const navigate = useNavigate()
  localStorage.removeItem("token");
  localStorage.removeItem("userData");
  window.location.href = "/";
};
