import ApiHelper from "../../../libs/Api";

export const pointageList = async (
  pointageUrl = "/personnals/clockings/all"
) => {
  try {
    return await ApiHelper.request(pointageUrl);
  } catch (error) {
    return error.response;
  }
};

const pointageCreationUrl = "/personnals/clockings/create";

export const createPointage = async (body) => {
  try {
    return await ApiHelper.request(pointageCreationUrl, "post", body);
  } catch (error) {
    return error.response;
  }
};

export const editPointage = async (pointageEditUrl, body) => {
  try {
    return await ApiHelper.request(pointageEditUrl, "put", body);
  } catch (error) {
    return error.response;
  }
};
