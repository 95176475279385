import React from "react";
import { Route, Routes } from "react-router-dom";
import ProfileLayout from "../components/ProfileLayout";
import Dashboard from "../pages/profilePage/Dashboard";
import Users from "../pages/profilePage/Users";
import Production from "../pages/profilePage/Production";
import Personnels from "../pages/profilePage/Personnels";
import Stock from "../pages/profilePage/Stock";
import Depenses from "../pages/profilePage/Depenses";
import Caisse from "../pages/profilePage/Caisse";
import Commande from "../pages/commande/CommandePage";
import Facture from "../pages/profilePage/Facture";
import Entreprise from "../pages/profilePage/Entreprise";
import Commercial from "../pages/profilePage/Commercial";
import Salaire from "../pages/profilePage/Salaire";
import Conge from "../pages/profilePage/Conge";
import Produit from "../pages/profilePage/Produit";
import Fournisseur from "../pages/profilePage/Fournisseur";
import Pointage from "../pages/profilePage/Pointage";
import HistoriqueStock from "../pages/profilePage/HistoriqueStock";
import Vehicule from "../pages/profilePage/Vehicule";
import FactureDetail from "../pages/profilePage/FactureDetail";
import ProductionStep from "../pages/profilePage/ProductionStep";
import NotFound from "./NotFound";

const profileRoutes = [
  { path: "/dashboard", element: <Dashboard /> },
  { path: "/users", element: <Users /> },
  { path: "/commercial", element: <Commercial /> },
  { path: "/company", element: <Entreprise /> },
  { path: "/personnels", element: <Personnels /> },
  { path: "/pointage", element: <Pointage /> },
  { path: "/conge", element: <Conge /> },
  { path: "/salaire", element: <Salaire /> },
  { path: "/production", element: <Production /> },
  { path: "/production/:id", element: <ProductionStep /> },
  { path: "/produit", element: <Produit /> },
  { path: "/stock", element: <Stock /> },
  { path: "/historiqueStock", element: <HistoriqueStock /> },
  { path: "/fournisseur", element: <Fournisseur /> },
  { path: "/depenses", element: <Depenses /> },
  { path: "/caisse", element: <Caisse /> },
  { path: "/commande", element: <Commande /> },
  { path: "/facture", element: <Facture /> },
  { path: "/facture/:id", element: <FactureDetail />, exact: true },
  { path: "/vehicule", element: <Vehicule /> },
];

export default function ProfileRouter(props) {
  return (
    <Routes>
      <Route path="/" element={<ProfileLayout />}>
        {profileRoutes.map((route) => (
          <Route {...route} />
        ))}
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

// export default ProfileRouter;
