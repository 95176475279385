export const renderActionButtons = (commande, handleDetailClick, handleEditClick) => (
  <div className="d-flex align-items-center justify-content-center">
    <button className="btn ms-2 btn-primary px-2 py-1" type="button" title="Détails" onClick={() => handleDetailClick(commande)}>
      <i className="text-500 text-white fas fa-eye"></i>
    </button>
    <button className="btn ms-2 btn-info px-2 py-1" type="button" title="Détails" onClick={() => handleDetailClick(commande)}>
      <i className="text-500 text-white fas fa-file"></i>
    </button>
    <button className="btn ms-2 btn-warning px-2 py-1" type="button" title="Modifier" onClick={() => handleEditClick(commande)}>
      <i className="text-500 text-white fas fa-edit"></i>
    </button>
  </div>
);
