import React, { useEffect, useState } from "react";
import { BGHOLDER as bg, checkStatusCodes } from "../../constants";
import { Icon } from "@iconify/react";
import Modal from "react-bootstrap/Modal";
import { editSalaire } from "./services/salaire.service";
import {
  dateFormatForSubmit,
  formatDateForInput,
} from "../../helpers/dates.helper";

function SalaireEditModal({
  personnels,
  selectedSalaire,
  showEdit,
  handleClose,
  handleToast,
  updateSalaireInList,
  showLoader,
}) {
  const [codeSalaire, setCodeSalaire] = useState("");
  const [montantSalaire, setMontantSalaire] = useState("");
  const [dateSalaire, setDateSalaire] = useState("");
  const [selectedPersonnel, setSelectedPersonnel] = useState(null);

  const clearInput = () => {
    setCodeSalaire("");
    setMontantSalaire("");
    setDateSalaire("");
  };

  const salairelEdit = async (e) => {
    showLoader(true);
    e.preventDefault();
    const editData = {
      date: dateFormatForSubmit(dateSalaire),
      amount: parseInt(montantSalaire),
      code_personnel: selectedPersonnel.code,
    };
    const SalaireEditUrl = `/personnals/salaries/payments/${
      codeSalaire ? codeSalaire : ""
    }/update`;
    const response = await editSalaire(SalaireEditUrl, editData);
    let state = false;
    let message = "";
    let type = "";
    if (checkStatusCodes(response.data.statusCode)) {
      showLoader(false);
      state = true;
      message = response.data.message;
      type = "success";
      handleToast(state, type, message);
      updateSalaireInList({
        ...selectedSalaire,
        amount: montantSalaire,
        date: dateSalaire,
      });
      clearInput();
    } else {
      showLoader(false);
      state = true;
      message = response.data.message;
      type = "error";
      handleToast(state, type, message);
    }
  };
  useEffect(() => {
    // Mettez à jour le type d'utilisateur en fonction du rôle sélectionné
    if (selectedSalaire && selectedSalaire.personnel) {
      const { code, amount, date } = selectedSalaire;
      setCodeSalaire(code);
      setMontantSalaire(amount);
      setDateSalaire(date);
      const personnelCode = selectedSalaire.personnel.code;
      const correspondingPersonnel = personnels.find(
        (personnel) => personnel.code === personnelCode
      );
      setSelectedPersonnel(correspondingPersonnel);
    }
  }, [selectedSalaire, personnels]);
  return (
    <Modal
      show={showEdit}
      onHide={handleClose}
      animation={true}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <div className="bg-holder d-none d-lg-block bg-card" style={bg}></div>
      <Modal.Header>
        <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
          <button
            class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
            onClick={() => {
              handleClose();
              // clearInput();
            }}
          ></button>
        </div>
        <div className="bg-light rounded-top-lg py-3 ps-4 pe-6">
          <h4 className="mb-1" id="staticBackdropLabel">
            Modifier un paiement
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="p-4">
          <div className="row">
            <form onSubmit={(e) => salairelEdit(e)}>
              <div className="col-lg-12">
                <div className="d-flex">
                  <div className="flex-1">
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="date_paiement">Date de paiement:</label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <i className="fas fa-calendar text-white"></i>
                          </span>
                          <input
                            id="date_paiement"
                            className="form-control sb-input"
                            type="date"
                            aria-label="Date de paiement"
                            placeholder="Date de paiement"
                            aria-describedby="basic-addon1"
                            defaultValue={formatDateForInput(
                              selectedSalaire.date
                            )}
                            onChange={(e) => setDateSalaire(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="montant">Montant:</label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <i className="fas fa-coins text-white"></i>
                          </span>
                          <input
                            id="montant"
                            className="form-control sb-input"
                            type="number"
                            aria-label="montant"
                            placeholder="Montant"
                            aria-describedby="basic-addon1"
                            defaultValue={montantSalaire}
                            onChange={(e) => setMontantSalaire(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="personnel">
                          Employé(s)
                        </label>
                        <div className="input-group mb-3">
                          <span
                            className="input-group-text bg-primary"
                            id="basic-addon1"
                          >
                            <Icon
                              icon="clarity:employee-group-solid"
                              className="text-white"
                              width="25"
                              height="25"
                            />
                          </span>
                          <select
                            className="form-select js-choice"
                            id="personnel"
                            size="1"
                            name="personnel"
                            data-options='{"removeItemButton":true}'
                            value={
                              selectedPersonnel ? selectedPersonnel.code : ""
                            }
                            onChange={(e) => {
                              const selectedPersonnelCode = e.target.value;
                              const PersonnelSelected = personnels.find(
                                (personnel) =>
                                  personnel.code === selectedPersonnelCode
                              );
                              setSelectedPersonnel(PersonnelSelected);
                            }}
                          >
                            <option value="">Sélectionner un employé...</option>
                            {personnels && personnels.length > 0
                              ? personnels.map((personnel) => (
                                  <option
                                    key={personnel.code}
                                    value={personnel.code}
                                  >
                                    {personnel?.first_name}{" "}
                                    {personnel?.last_name}
                                  </option>
                                ))
                              : null}
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>

                <div className="d-flex">
                  <div className="flex-1">
                    <button
                      className="btn btn-outline-danger rounded-pill me-1 mb-1"
                      type="button"
                      onClick={() => {
                        handleClose();
                        // clearInput();
                      }}
                    >
                      Annuler
                    </button>
                    <button
                      className="btn btn-outline-primary rounded-pill me-1 mb-1 btn-edit"
                      type="submit"
                      id="edit_charge_send"
                      onClick={handleClose}
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SalaireEditModal;
