import React from 'react';

function UserStatusUpdateModal(props) {
    return (
        <div className="modal fade" id="etat" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth: "500px"}}>
                <div className="modal-content position-relative">
                    <div className="modal-body p-0">
                        <div className="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                            <h5 className="mb-1" id="modalExampleDemoLabel">
                                Changer le status de l'utilisateur
                            </h5>
                        </div>
                        <div className="action_status">
                        <div className="d-flex justify-content-center m-2 ">
                            <button className="btn btn-outline-success mx-2 btn-sm change-status-user" 
                                data-code="code"
                                data-status="ACTIVE" 
                                type="button" 
                                data-bs-dismiss="modal">Activer</button>
                            <button className="btn btn-outline-secondary btn-sm" type="button" data-bs-dismiss="modal" >Annuler</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserStatusUpdateModal;